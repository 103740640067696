import React, {Fragment} from 'react'
import changeUser from '../WQCImage/changeUser.svg';
import * as SKY from "../../component/ComComponent";

export default class Header extends React.Component{
  constructor(props){
    super(props);
    let agent = window.navigator.userAgent.toLowerCase();
    let ie11 = (agent.indexOf('trident/7') !== -1);
    this.wrap_style = {
      position: "fixed",
      display:"flex",
      zIndex: "900",
      top: "0",
      width: "100%",
      minWidth : 1000,
      background : 
        ie11 ? "-ms-linear-gradient(bottom,  #01906D,  #4CAF93)":
        "linear-gradient( #4CAF93, 50%, #01906D)",
      justifyContent:"space-between",
      alignItems:"center",
      height : 50,
      padding:"0px 24px",
    }
    this.logo_style = {
      fontSize: "35px",
      color:"#fff",
    }
    this.header_style = {
      display:"flex",
      alignItems:"center",
    }
    this.survey_style = {
      display:"flex",
      fontSize: "30px",
      alignItems:"left",
      color:"#000",
      backgroundColor:"#fff",
    }
    this.regUser_style = {
      color : "#fff",
      padding : "0px 16px"
    }

  }
  // _______________________________________________________________________________________________________

  render(){  
    const { 
      regUser,
      regUserName,
      loginWithSurvey,
      superGrant,
      onClickLogout,
      logo,
      onClickIcon,
    } = this.props;
    console.log(this.props);
    return(
      <header style={this.wrap_style}>
        <h1 style={this.logo_style}>{logo}</h1>
        <div style={this.survey_style}>{loginWithSurvey && 'サーベイ利用中'}</div>
        <div style={this.survey_style}></div>
        <div style={this.survey_style}></div>
        <div style={this.survey_style}></div>
        <div style={this.survey_style}></div>
        <div style={this.header_style}>
          { superGrant  &&<ChangeUserIcon onClick={onClickIcon} /> }
          <div style={this.regUser_style}>参照中の施設：{regUser}　{regUserName}</div>
          <LogoutBtn onClick={onClickLogout}/>
        </div>  
      </header>
    );
  }
}


class LogoutBtn extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false,
    }
    this.logoutBtn_style = {
      height: 35,
      padding:"0px 25px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 20,
      boxShadow:" 2px 2px 2px #aaa",
      cursor: "pointer",
      border:"2px solid #000",
      transition:"0.3s",
    }
  }
  render(){
    const { onClick } = this.props;
    const { hover } = this.state;
    Object.assign(this.logoutBtn_style,{
      backgroundColor: 
        hover ? "#000" :
        "#fff",
      color :
        hover ? "#fff" :
        null,
      border:
        hover ? "2px solid #FFF" :
        "2px solid #000",
    })
    return(
      <button 
        style        = {Object.assign({}, this.logoutBtn_style)}
        onClick      = {onClick}
        onBlur       = {()=>this.setState({ hover : false })}
        onFocus      = {()=>this.setState({ hover : true  })}
        onMouseOver  = {()=>this.setState({ hover : true  })}
        onMouseLeave = {()=>this.setState({ hover : false })}
      >
        ログアウト
      </button>
    );
  }
}
class ChangeUserIcon extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false,
    }
    this.changeUserIcon_style = {
      padding: 4,
      height: 35,
      width: 35,
      borderRadius: 5,
      transition:"0.3s",
    }
  }
  // ______________________________________________________________________________

  render(){
    const { onClick } = this.props;
    const { hover } = this.state;
    Object.assign(this.changeUserIcon_style,{
      backgroundColor: 
        hover ? "#fff" :
        null,
    })
    return(
      <SKY.Icon 
        label        = "編集する施設を変更する" 
        src          = {changeUser} 
        style        = {Object.assign({},this.changeUserIcon_style)}
        onClick      = {onClick}
        onBlur       = {()=>this.setState({ hover : false })}
        onFocus      = {()=>this.setState({ hover : true  })}
        onMouseOver  = {()=>this.setState({ hover : true  })}
        onMouseLeave = {()=>this.setState({ hover : false })}
      />
    );
  }
}