import React, { Fragment } from 'react';

export default class TagChangeMenu extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      page:true,
    }
  }

  handleClick=()=>{
    this.setState({ page : !this.state.page })
  }


  render(){
    const wrap ={
      display:"flex",
      flexDirection: "column",
      width: "100%",
      boxSizing:"border-box",
    }

    const tag_wrap ={
      zIndex:2,
    }
    
    const selected = {
      display:"inline-flex",
      margin:"8px 8px -4px 0px",
      borderRadius:"5px 5px 0px 0px",
      padding:16,
      fontSize:14,
      borderTop:"2px solid #999",
      borderRight:"2px solid #999",
      borderLeft:"2px solid #999",
      borderBottom:"2px solid #FFF",
      boxSizing:"border-box",
      backgroundColor:"#FFF",
      fontWeight:"bold",
      cursor:"pointer",
      
    }

    const unselected = {
      display:"inline-flex",
      margin:"8px 8px -2px 0px",
      borderRadius:"5px 5px 0px 0px",
      border:"2px solid #999",
      padding:16,
      fontSize:14,
      boxSizing:"border-box",
      backgroundColor:"#ddd",
      // color:"#fff",
      // fontWeight:"bold",
      cursor:"pointer",
      
    }

    const resultArea ={
      padding:"24px 16px",
      border:"2px solid #999",
      borderRadius:"0px 5px 5px 5px",
      backgroundColor:"#FFF",
      boxSizing:"border-box",
    }

    return(
      <div style={wrap}>
        <div style={ tag_wrap }>
          <label　style={  this.state.page ? selected : unselected } onClick={ ()=> this.setState({ page: true  }) } >コントロール毎に入力する</label>
          <label　style={ !this.state.page ? selected : unselected } onClick={ ()=> this.setState({ page: false }) } >分析項目毎に入力する</label>
        </div>
        <div style={ resultArea} >
          {this.state.page ? this.props.page1 : this.props.page2 }
        </div>
      </div>
    )
  }

}