import React,{Fragment} from 'react';

export default class Col extends React.Component{
  constructor(props){
    super(props);
    this.state={
      responsiveStyle: null,
    }
  }
  componentDidMount() {
    const responsive = this.props.responsive
    if(responsive){

      window.addEventListener('resize',this.resizeEvent);

      if( window.innerWidth < responsive[0].break ){
        // console.log(responsive[0].style)
        this.setState({ responsiveStyle : responsive[0].style })
      }
    }
    
  }

  componentWillUnmount() {
    if(this.props.responsive){
      window.removeEventListener('resize', this.resizeEvent);
    }
  }

  resizeEvent=()=>{
    const responsive  = this.props.responsive;
    if( window.innerWidth < responsive[0].break ){
      this.setState({responsiveStyle : responsive[0].style })
    }
    if( window.innerWidth > responsive[0].break ){
      this.setState({responsiveStyle : null })
    }
  }

  render(){
    const {
      middle,
      h,
    } =this.props
    
    const style={
      display:"flex",
      flexDirection:"column",
      // backgroundColor:"blue",
      justifyContent:
        middle ? 'center' :
        null,
      alignContent:"space-between",
      whiteSpace:this.props.nowrap? "nowrap" : null,
      width:this.props.w ? this.props.w : "100%",
      padding:this.props.padding,
      margin:this.props.margin,
      height:
       h ? h :
       null
    }

    return(
      <div style={Object.assign({},style,this.props.style, this.state.responsiveStyle)}
          className={this.props.className}
      >
        {this.props.children}
      </div>

    );
  }
}