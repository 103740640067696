import React, { Fragment } from 'react';

export default class SaerchBtn extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover : false,
    }
  }
  // ______________________________________________________________________________
  
  render(){
    const { hover } = this.state
    const { label } = this.props

    const btn_style = {
      height: 30,
      width: 
        label ? null :
        80,
      padding:
        label ? "0px 16px": 
        null,
      backgroundColor: 
        hover ?  "#fff" :
        "#2296F3",
      border: 
        hover ?  "2px solid #000":
        "2px solid #2296F3",
      color:
        hover ? "#000" :
        "#fff",
      fontWeight: "bold",
      fontSize : 14,
      borderRadius: 5,
      boxShadow: "2px 2px 2px #aaa",
      cursor: "pointer",
      alignSelf:"bottom",
      marginBottom:16,
      marginRight:16,
      transition:"0.3s",
    }
    // ______________________________________________________________________________
    
    return(
      <button 
        style        = { btn_style }
        onClick      = { this.props.onClick } 
        onMouseOver  = { () => this.setState({ hover : true }) }
        onMouseLeave = { () => this.setState({ hover : false }) }
        onFocus      = { () => this.setState({ hover : true }) }
        onBlur       = { () => this.setState({ hover : false }) }
      >
        { label ? label : "検索"}
      </button>
    )
  }
}
