import {LOGIN, LOGOUT, LOGSTATUS, ERR, SEARCH_ERR, INPUT_ERR,FATALERR,SESSIONERR,MENU, PAGEMOVE, INTRODUCE} from '../../comRedux/ActionConst';
  
export const key = 'COMMON';

const initLogin = {
  userCode:'',
  loginWithSurvey:'',
  //password:'',
  lang:'',
  entryType:0, // entryType 0:loginPage 1:Menu
  isChanged:false,
  errMsg:'',
  selectMenuId : 'TEST',
  selectMenuName :'MENU_NAME（）',
  logStatus:'0', // 0: 未登録　1:ログイン済
}

const initMenu = {
  didset : false,
  menuList : {
    menuList : [],
    welcomeList : [],
    informationList:[],
  },
}
const initIntroduce = {
  didset : false,
}
const initCommon = {
  searchErrMsg:'',
  inputErrMsg:'',
  fatalErrMsg:'',
  currentPage: 1 , //0画面転移頁 1検索画面　2入力画面　9システムエラー
  mode : '0',// 0初期値　1追加 2編集 3削除 4複写
}

export const initialState = {
  //Loginデータの保管
  Login:JSON.parse(JSON.stringify(initLogin)),
  //Menu情報の保管
  Menu:JSON.parse(JSON.stringify(initMenu)),
  //Menu情報の保管
  Introduce:JSON.parse(JSON.stringify(initIntroduce)),
  //共通パラメーターの保管
  Common:JSON.parse(JSON.stringify(initCommon)),
}



export default (state = initialState, action) => {
    switch (action.type) {
      case LOGIN:
        return  Object.assign({}, state, { 
          Login:{
            ...state.Login,
            userCode:'',
            logStatus:'1',
            lang:'',
            entryType: 1,
            isChanged:false,
          }
      });
      case LOGOUT:
        return Object.assign({}, state, {
          Login:{
            ...state.Login, 
            userCode:'',
            logStatus:'1',
            lang:'',
            entryType: 0,
            isChanged:false,
          },
          Menu:{},
          Common:{},
      });
      case MENU:
        return Object.assign({},state,{
          Menu:{
            didSet:true,
            welcomeList : action.welcomeList,
            informationList:action.informationList,
            menuList:action.menuList
          }
        });
      case INTRODUCE:
        // console.log('通過')
        return Object.assign({},state,{
          Login:{
            ...state.Login, 
            entryType: 2,
          }
        });
      //機能内画面推移

      case LOGSTATUS:{
        return Object.assign({}, state, {
          Login:{
            ...state.Login, 
            logStatus:action.status,
          },
      }); 
      };

      case PAGEMOVE:
        return Object.assign({},state,{
          Common:{
            ...state.Common,
            currentPage:action.payload.currentPage,
            mode:action.payload.mode,
          }
        });
      case ERR:
        return Object.assign({}, state, {
          Login:{
            ...state.Login,
            errMsg:action.errMsg,
          }
        });
      case SEARCH_ERR :
        return Object.assign({}, state,{
          Common:{
            ...state.Common,
            searchErrMsg:action.errMsg,
          }
        });
      case INPUT_ERR :
        return Object.assign({}, state,{
          Common:{
            ...state.Common,
            inputErrMsg:action.errMsg,
          }
        });
      case FATALERR :
        return Object.assign({}, state , {
          Common:{
            ...state.Common,
            currentPage:9,
            fatalErrMsg:action.errMsg,
          }
        });
      case SESSIONERR :
        return Object.assign({}, state , {
          Common:{
            ...state.Common,
            currentPage:0,
          }
        });
      default : 
      if(action.type === '@@router/LOCATION_CHANGE'){
        return Object.assign({},state,{
            Common:JSON.parse(JSON.stringify(initCommon)),
        });
      }else{
        return state;
      }
    }
}