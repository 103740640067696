import React from 'react'
import Logo from "../WQCImage/FUJIFILM.png"
import FujiFooterImg from '../WQCImage/fujifilm-footer.jpg'
import { Link } from "react-router-dom";

export default class Footer extends React.Component{
  constructor(props){
    super(props);
    this.wrap = {
      position:"absolute",
      bottom: "0",
      width: "100%",
      height : 60,
      backgroundColor : '#000',
      display:"flex",
      flexDirection: 'column',
    } 
    this.logo_style = {
      display:"inline-flex",
      justifyContent: 'center',
      alignItems: 'center',
      padding : 5,
      backgroundColor : '#000',
      zIndex: 1,
    } 
    this.copyright_style = {
      fontSize : 13,
      color : '#fff',
      zIndex: 1,
    }
    this.footerImg_style = {
      width : '100%', 
      height : 20,
    }
    this.inner_style = {
      display:"flex",
      width: "100%",
      justifyContent:"space-between",
      paddingLeft:24,
      paddingRight:24,
      height :40,
      alignItems: 'center',
    }
    this.linkArea_style = {
      display:"flex",
      alignItems:"center",
      height :'100%',
    }
    this.link_style = {
      color : "#fff",
      fontSize : 12,
      marginLeft: 16,
      textDecoration: 'underline solid',
      cursor : 'pointer',
    }
  }
  render(){  
    const { setData } = this.props;
    const link_arr = []
    for( let i in setData ){
      const { label, link, } = setData[i];
      link_arr.push(
        <Link key={'link'+i} style={this.link_style} to={link}>
          {label}
        </Link>
      )
    }

    return(
      <footer style={this.wrap}>
        <img style={this.footerImg_style} src={FujiFooterImg}  alt="フッター" /> 
        <div style={this.inner_style}>
          <div style={this.linkArea_style}>
            <div style={this.logo_style}><img src={Logo} alt="ロゴ" width={87} height={15}/></div>
            <a style={this.link_style} href='http://fujifilm.com/ffwk' target="_blank" rel="noreferrer noopener" >企業情報</a>
            {link_arr}
          </div>
          <span style={this.copyright_style}>©富士フイルム和光純薬株式会社</span>
        </div>
      </footer>
    );
  }
}
