import React, { Fragment } from 'react';
import BasicComponent from './BasicComponent';
import SearchIcon from "../image/SearchIcon.png";
import triangle_icon from "../image/triangle.svg"
import { isObjectValueEqual } from '../util/commonInput';

export default class ListBox extends BasicComponent{
  constructor(props){
    super(props);
    const {
      value,
      setData,
      size,         // 横幅のサイズ SS,S,M,L,LL, 数値　で入力可能
    } = this.props;

    this.state = {
      value : value || [],    // 選択されているアイテムのvalue
      searchItems  : "",      // 検索ボックスの入力値
      showItems    : false,   // ボックスが表示されているか
      setData      : setData || [],　// セットされたデータ
    }
    let boxWidth
    switch(size) {
      case "ss" : case "SS" : { boxWidth = 70;   break; }
      case "s"  : case "S"  : { boxWidth = 113;  break; }
      case "m"  : case "M"  : { boxWidth = 156;  break; }
      case "l"  : case "L"  : { boxWidth = 328;  break; }
      case "ll" : case "LL" : { boxWidth = 672;  break; }  
      default   : { boxWidth = size ? size : 156; break }
    }
    
    this.wrap_style = {          // <div>　一番大元の Wrap要素
      position:"relative",
      display: "inline-flex",
      flexDirection:"column",
      marginRight:16,
      marginBottom:16,
    }
    this.selectWrap_style = {         // <div>　セレクトのWrap要素
      display:"inline-flex",
      flexDirection:"column",
      position:"relative",
      boxSizing:"border-box",
      width: boxWidth,
    }
    this.select_style = {        // <button>値を表示するメインのボタン
      background:`url(${triangle_icon}) no-repeat`,
      backgroundPosition:"center right",
      backgroundSize:"18px 18px",
      width: boxWidth,
      textAlign:"left",
      paddingLeft:5,
      border : "1px solid #aaa",
      boxSizing:"border-box",
      borderRadius:5,
      fontSize:16,
      transition:"0.2s",
      cursor : "pointer",
      minHeight:30,
      display:"inline-flex",
      flexDirection:"column",
    }
    this.value_style = {
      margin:"4px 0px",
    }
    this.hiddenbox_style = {          // <div>ボックス
      flexDirection:"column",
      backgroundColor:"#fff",
      position:"absolute",
      zIndex:5,
      width:boxWidth,
      maxHeight:"250px",
      overflowY:"scroll",
      whiteSpace: "nowrap",
      boxSizing:"border-box",
      border:"1px solid #aaa",
      borderRadius:5,
    }
    this.searchWrap_style = {              // <div> 検索テキストボックスのラップ要素
      padding:"5px 5px",
      boxSizing:"border-box",
    }
    this.search_style = {            // <input> 検索テキストボックス
      background:`url(${SearchIcon}) no-repeat`,
      backgroundPosition:"center left 5px",
      backgroundSize:"18px 18px",
      height:25,
      width:"100%",
      paddingLeft:30,
      border:"1px solid #aaa",
      borderRadius:5,
      boxSizing:"border-box",
    }
    this.itemsWrap_style = {              // <div> リストアイテムのラップ要素
      display:"inline-flex",
      flexDirection:"column",
      padding:"5px 5px",
      width:"100%",
      boxSizing:"border-box",
    }

    this.select_ref      = React.createRef();       // <button>要素：選択されているアイテムを表示するメインのボタン
    this.hiddenbox_ref   = React.createRef();       // <button>要素：選択されているアイテムを表示するメインのボタン
    this.itemsWrap_ref   = React.createRef();       // <div>要素：アイテムボックスのラップ要素
    this.search_ref      = React.createRef();       // <input>要素：検索用テキストボックス
    this.elementClickFlag = false;   // クリックされた場所が、要素上かどうか検知するために定義

  }
  // ________________________________________________________________________________________________________________

  componentDidMount(){
    const { autoFocus } = this.props
    autoFocus && this.select_ref.current.focus() // オートフォーカス機能
  }
  // no-op alert 対応
  componentWillUnmount(){
    this.setState = (state,callback)=>{
      return;
    };  
  }
  // ________________________________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { onChange, name } = this.props
    if((!!nextProps.setData && !isObjectValueEqual(this.props.setData,nextProps.setData)) || this.props.value !== nextProps.value){
      let nextValueArr = [];
      for(let i in nextProps.setData) {
        const { value } = nextProps.setData[i];
        if(nextProps.value.indexOf(value) !== -1){
          nextValueArr.push(value);
        }
      }
      this.setState({ 
        setData : nextProps.setData,
        value :  nextValueArr,
      })
      // onChange && onChange(name,nextValueArr); // 親にnameとvalueを渡している
    }
    return true;
  }
  // ________________________________________________________________________________________________________________

  componentDidUpdate(){
    if( !this.state.showItems ){                               // ボックスが閉じている時
     window.removeEventListener('click',this.pageClick,)      // windowイベントpageClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }
  }
  // ________________________________________________________________________________________________________________

  // 画面をクリックした時
  pageClick = () => {
    if( this.elementClickFlag ){                  // もしセレクトボックス上で、マウスダウンされていたら
      this.elementClickFlag = false;              //　elementClickFlag を初期値に戻して
      return;                                    // クリック処理を中断
    }
    this.setState({showItems:false})             // ボックスを閉じる
  }
  // ________________________________________________________________________________________________________________

  // セレクト上またはテキストボックスでマウスダウンした時
  selectOnMouseDown=()=>{ 
    this.selectMouseDown = true;  // セレクト上またはテキストボックスでマウスダウンされたことを検知
  }
  // ________________________________________________________________________________________________________________

  // メインのボタンがフォーカスされた時
  selectFocus = () => {
    // const { showItems } = this.state  コールバックが使えなくなる

    this.setState({ 
      focus       : true,    // 背景色を変える
      showItems   : !this.state.showItems,  // セレクトボックスを開閉切り替え
      setData     : this.props.setData || [],  // フィルターをリセット
      searchItems : "", // 検索ボックスの初期化
    },()=>{   // コールバックで同期
      if( this.state.showItems ){  // セレクトボックスが開いている時  
        window.addEventListener('click',this.pageClick);        // windowイベントpageClick関数を作成します。

        //  表示する位置を切り替える処理
        let boxPosition = this.select_ref.current.getBoundingClientRect().bottom  // hiddenboxの下辺の位置を取得
        let boxHeight   = this.hiddenbox_ref.current.clientHeight                 // hiddenboxの縦サイズを取得
        if( boxPosition + boxHeight > window.innerHeight ){   // hiddenboxの下辺の位置 + 縦サイズが windowの縦サイズより大きいなら
          this.setState({ showTop : true })       // boxを上部に表示する（スタイルと連携）
        }else{
          this.setState({ showTop : false })      // boxを下部に表示する（スタイルと連携）
        }
      }
    }) 
  }
  // ________________________________________________________________________________________________________________

  // メインのボタンをクリックしたとき
  selectClick =(e)=> {
    e.preventDefault()
    window.addEventListener('click',this.pageClick);        // windowイベントpageClick関数を作成します。

    this.setState({ 
      showItems: true  // ボックスを開く
    },()=>{            // コールバックで同期
      //  表示する位置を切り替える処理
      let boxPosition = this.select_ref.current.getBoundingClientRect().bottom  // hiddenboxの下辺の位置を取得
      let boxHeight   = this.hiddenbox_ref.current.clientHeight                 // hiddenboxの縦サイズを取得
      if( boxPosition + boxHeight > window.innerHeight ){   // hiddenboxの下辺の位置 + 縦サイズが windowの縦サイズより大きいなら
        this.setState({ showTop : true })       // boxを上部に表示する（スタイルと連携）
      }else{
        this.setState({ showTop : false })      // boxを下部に表示する（スタイルと連携）
      }
    })        
  }
  // ________________________________________________________________________________________________________________
    
  // メインのボタンに対してキーを押した時
  selectKeyDown =(e)=>{
    const { showItems, setData } = this.state;
    const { searchable } = this.props;
    if( !showItems || setData.length <= 0 ){ return; }       // セレクトボックスが閉じているとき、またはsetDataがない時にreturnさせて、処理をさせないためのif文です
    
    switch( e.keyCode ){ 
      case 9: { // Tabキー
        this.setState({showItems:false});         // セレクトボックスを閉じる
        break;
      }
      default: {
        e.preventDefault();
        if(!searchable) { this.itemsWrap_ref.current.firstChild.focus(); }  // (親から)searchableが設定されていなかったら（検索ボックスがなければ）firstChildで先頭の要素を参照して、フォーカス
        else{ this.search_ref.current.focus(); }   // 検索ボックスがあったら 検索ボックスにフォーカス                   
        break;
      }
    }
  }
  // ________________________________________________________________________________________________________________

  // 検索ボックスに入力した時
  searchChange =(e)=>{
    const { setData } = this.props 
    e.preventDefault();
    
    // 絞り込み処理
    let filterdData = setData.filter( setData  => { // setDataにフィルター
      return (setData.label.toLowerCase().search(e.target.value.toLowerCase()) !== -1); // searchメソッドで検索 -1 じゃなかったら値がある。.toLowerCase()は大文字小文字を区別しないため。
    })

    this.setState({ 
      setData : filterdData,      // 絞り込み後のデータをセット 
      searchItems:e.target.value, // 検索ボックス内のstateを更新するための処理。（外すと検索ボックスに文字が打てなくなります）
    })          
  }
  // ________________________________________________________________________________________________________________
  
  // 検索ボックスに対してキーを押した時
  searchKeyDown =(e)=>{
    switch( e.keyCode ){
      case 9: // Tabキー
        e.preventDefault();
        this.select_ref.current.focus();                    // メインのボタンにフォーカスを戻しています。(IEとEdgeは、フォーカスが最初の位置に戻ってしまうので)
      break
      case 40 :　// 下
      case 13 :
        e.preventDefault();
        if( this.itemsWrap_ref.current.children.length > 0 ){   // 検索結果あれば
         this.itemsWrap_ref.current.firstChild.focus();         // firstChildで先頭の要素を参照して、フォーカス
        }
      break;
      default:
    }
  }
  // ________________________________________________________________________________________________________________


  // アイテムをクリックした時
  itemsClick = (item) => {
    let value_copy = this.state.value.slice();       // コピーを作成
    const result = value_copy.indexOf( item.value )  // indexOf()メソッドで値の有無を確認
    if( result === -1 ){                             // 値が入ってなければ
      value_copy.push( item.value )                  // push()メソッドで値を挿入
    }else{
      value_copy = value_copy.filter( a => {         // 入っていたら
        return a !== item.value;                     // filter()メソッドで値を削除
      });
    }
    this.setState({value:value_copy});                // コピーをstateにセット。色を変えるのと、メインのボタンに格納するために使っています

    this.props.onChange && this.props.onChange(this.props.name,value_copy); // 親にnameとvalueを渡している
  }
  // ________________________________________________________________________________________________________________
  
  // アイテム対してキーを押した時
  itemsKeyDown = (item, e) => {
    const { searchabel, onChange, name } = this.props;
    switch( e.keyCode ){
      case 9: // Tabキー
        e.preventDefault();
        this.select_ref.current.focus();                    // メインのボタンにフォーカスを戻しています。(IEとEdgeは、フォーカスが最初の位置に戻ってしまうので)
      
        break

      case 38 :　// 上
        e.preventDefault();
        if(e.target.previousElementSibling) {            // previousElementSiblingで前の要素を参照して、要素があるなら
          e.target.previousElementSibling.focus();        // フォーカスを前の要素へ移動
        }
        else if(searchabel) {                   // (親から)noSearchが設定されていなかったら
          this.search_ref.current.focus();                // 検索ボックスにフォーカス
        }
      break;

      case 40 :　// 下
        e.preventDefault();
        if(e.target.nextElementSibling) {                // previousElementSiblingで次の要素を参照して、要素があるなら
          e.target.nextElementSibling.focus();            // フォーカスを次の要素へ移動
        }
      break;
      case 13 : // Enter キー
        e.preventDefault();                            // onClickとしても発火するのでここだけで制御するためにしています。（これをつけないと、IE,Edgeはボックスが閉じない）
        let value_copy = this.state.value.slice();     // コピーを作成
        const result = value_copy.indexOf(item.value); // indexOf()メソッドで値の有無を確認
        if( result === -1 ) {                          // 値が入ってなければ
          value_copy.push(item.value);                 // push()メソッドで値を挿入
        }else{
          value_copy = value_copy.filter( a => {        // 入っていたら
            return a !== item.value;                    // filter()メソッドで値を削除
          });
        }
        this.setState({ value: value_copy });           // コピーをstateにセット。色を変えるのと、メインのボタンに格納するために使っています
        onChange && onChange(name, item.value);         // 親にnameとvalueを渡している
      break;
      default: 
    }
  }
  // ________________________________________________________________________________________________________________

  render(){
    // state と props の省略宣言
    const {
      label,        // ラベル
      must,         // boolean型 必須マーク「＊」を表示
      disabled,     // 編集不可にする
      searchable,   // boolean型 trueなら検索可能セレクトになる　
    } = this.props

    const { 
      setData,  //  ArrayObject型 [{ value : ... , label : ... }, { value : ... , label : ... }]で設定
      value,    // 選択されているアイテム
      focus,    // boolean型 スタイル変更
      hover,    // boolean型 スタイル変更
      showTop,  // boolean型 ボックスを表示する位置　trueなら上に表示
      showItems,   // boolean型 trueならボックスを表示
    } = this.state
    // ________________________________________________________________________________________________________________

    // 状態によって変更されるスタイルの記述
    Object.assign( this.select_style, {
      backgroundColor : //　優先順位順に背景色を設定
        disabled ? "transparent" : 　
        focus    ? "#eef" :
        value && value.length >= 1 ? "#fff" :
        must     ? "#fee" :
        "#fff", 
      boxShadow: 
        hover ? "0px 0px 3px 0px #00f" :
        null,
      pointerEvents: 
        disabled? "none" : 
        null,
    })
    Object.assign( this.hiddenbox_style, {
      top: 
        showTop ? null:
        "100%",
      bottom: 
        showTop ? "100%" : 
        null,
      display:
        showItems ? "inline-flex" :
        'none',
    })
    // ________________________________________________________________________________________________________________

    // 変数の宣言
    let itemsArr       = [];                              // 最終的に表示するリストアイテムの配列
    let valueArr       = [];
    // ________________________________________________________________________________________________________________
    
    // setDataからセレクトボックスを生成
    for(let i in setData ){
      let result = value && value.indexOf( setData[i].value )      // 配列の中身を検索

      // 抽出したデータを格納
      itemsArr.push(
        <ItemButton
          selected     = {result !== -1}
          onClick      = {()=>this.itemsClick(setData[i])}
          onKeyDown    = {(e)=>this.itemsKeyDown( setData[i], e)}
          key          = {'itemsArr_'+i}
          label        = {setData[i].label}
        />
      )
    }
    // ________________________________________________________________________________________________________________

    // selectValueを格納
    for( let i in value ){
      let filterdData = setData.filter(function(setData){
        if(setData.value === value[i]){ return true }
      })
      valueArr.push(
        <span style={this.value_style}　key={"value"+i}>
          {filterdData[0] && filterdData[0].label}
        </span>
      )
    } 
    
    // ________________________________________________________________________________________________________________

    return(
      <div style={this.wrap_style}>
        <Labeling label={label} must={must}/>
        <div 
          style       = {this.selectWrap_style} 
          onMouseDown = {()=> this.elementClickFlag = true} 
          onKeyDown   = {()=> this.elementClickFlag = true} 
        >          
          <button 
            style        = {Object.assign({}, this.select_style)}
            onClick      = {this.selectClick}
            onKeyDown    = {this.selectKeyDown} 
            ref          = {this.select_ref}
            disabled     = {disabled}
            onMouseOver  = {()=> this.setState({ hover : true  })}
            onMouseLeave = {()=> this.setState({ hover : false })}
            onFocus      = {this.selectFocus}
            onBlur       = {()=> this.setState({ focus : false })}
          >
            {valueArr}
          </button>
          <div style={Object.assign({},this.hiddenbox_style)} ref={this.hiddenbox_ref}>
            {searchable && 
            <div style={this.searchWrap_style}>
              <input 
                style     = {this.search_style}
                value     = {this.state.searchItems}
                onChange  = {this.searchChange}
                onKeyDown = {this.searchKeyDown}
                ref       = {this.search_ref}
              />  
            </div> 
            }
            <div style={this.itemsWrap_style} ref={this.itemsWrap_ref}>
              { itemsArr } {/* リストアイテム */}
              <CloseBtn label="閉じる" onClick={()=>{ this.select_ref.current.focus()}}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class ItemButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
    this.button_style = { // <button> リストアイテム
      height:20,
      textAlign:"left",
      paddingLeft:5,
      border:"none",
      cursor:"pointer",
      borderRadius:5,
      width:"100%",
      marginBottom:"2px",
      boxSizing:"border-box",
      transition:"0.2s",
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state;
    const { 
      selected,
      value,
      onClick,
      onKeyDown,
      label,
    } = this.props;

    // 状態によって変更されるスタイル
    Object.assign( this.button_style, {
      backgroundColor:
        hover    ? "#ccc":
        selected ? "#2b406c":
        "transparent",
      color :
        hover    ? null:
        selected ? '#fff': 
        null,
    })
    //______________________________________________________________________

    return(
      <button 
        style        = {Object.assign({},this.button_style)} 
        value        = { value }
        onClick      = { onClick }
        onKeyDown    = { onKeyDown }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        {label}
      </button>
    )
  }  
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class CloseBtn extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state 
    const button_style = {              // <button> ソートボタン
      height:25,
      width:"100%",
      backgroundColor: 
        hover ? "#fff" : 
        "#ccf",
      border: "2px solid #ccf",
      borderRadius:5,
      // textAlign:"left",
      marginBottom:8,
      cursor:"pointer",
      transition:"0.2s",
    }

    return(
      <button 
        style        = { button_style } 
        onClick      = { this.props.onClick }
        onKeyDown    = { this.props.onKeyDown }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { this.props.label }
      </button>
    )
  }  
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Labeling extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      paddingBottom : "4px"
    }
    this.label_style = {
      fontSize:14,
      marginRight : "1px",
      paddingLeft : "1px",
    }
    this.must_style = {
      fontSize:14,
      fontWeight : "bolder",
      color : "#d00", 
    }
  }
  //___________________________________________________________________
  
  render(){
    const { label, must } = this.props
    if( !label ){ return null }
    return(
      <div style={this.wrap_style}>
        <label style={this.label_style}>{label}</label>
        { must && <span style={this.must_style}>＊</span> }
      </div>
    )
  }  
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
const testData=[
  { value :" " , label :" " },
  { value :1 , label :"test1" },
  { value :2 , label :"test2" },
  { value :3 , label :"test3" },
  { value :4 , label :"test4" },
]

*/