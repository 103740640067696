/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, {Fragment} from 'react';
import SearchIcon from '../image/SearchIcon.png';
import TableCalendar from './TableCalendar';

export default class InputTable extends React.Component{
  constructor(props){
    super(props);
    const {
      color1, 
      fontColor, 
      minWidth,

      setData
    } = this.props;
    this.state = {
      setData     : setData || [],
      headerCheck : [],     // ヘッダーのチェックボックスの値
    }
    
    this.tableWrap_style = {   // <div> テーブルのwapper
      width:"100%",
      minWidth: minWidth,
      marginBottom:"16px",
    }
    this.table_style = {            // <table> テーブル
      width : "100%",
    }
    this.th_style = {                      // <td> テーブルヘッダー
      border:"1px solid #ccc",
      backgroundColor:color1,
      fontSize:14,
      height:25,
      verticalAlign:"middle",
      // padding:8,
      color:fontColor,
      fontWeight:"bold",
      textAlign:"center",
    }
    this.tbody_style = {
      // overflow:"auto",
      // maxHeight:"500px",
    }

    this.modal_style ={
      backgroundColor:"transparent",
      background:`url(${SearchIcon}) no-repeat`,
      backgroundPosition:"center",
      backgroundSize:"16px 16px",
      border:"none",
      cursor:"pointer",
      height:25,
      width:"100%",
    }
    this.delete_style = {            // <button> 削除ボタン
      width:"100%",
      backgroundColor:"transparent",
      border:"none",
      color:"#c55",
      fontSize:25,
      fontWeight:"bolder",
      cursor:"pointer",
    }
    this.must_style = {         // <span> 「※必須」のスタイル
      fontSize:16,
      color:"red",
    }

    this.rowsButton_style = {
      height:25,
      border:"none",
      backgroundColor:color1,
      padding:"0px 8px",
      marginRight: 16,
      borderRadius:5,
      color:fontColor,
      cursor:"pointer",
      boxShadow : "2px 2px 2px #ccc",
    }
    this.buttonArea_style = {
      paddingTop:8,
    }
    this.firstTbodyFocus = false; // 一回目のフォーカスかどうか
    this.firstRender = true;

  }
  // ______________________________________________________________________________________________________

  componentDidMount(){
    const { children, rows, } = this.props
    const { setData } = this.state
    const setData_copy = setData.slice()  // 配列stateのコピーを作成

    for( let i = 0; i < rows ; i++){      // rowsの数だけループ
      setData_copy[i] = {}   // Jsonが入るように初期化
      if( setData ){
        for (let key in setData[0]) {
          Object.assign( setData_copy[i] ,{ [key] : null })  // 初期値を設定。Object.assign()メソッドでJson結合。テーブルに表示しないデータを裏で持つために。
        }
      }
      
      for( let j in children){       // childrenで設定されている数だけループ
        const { name, defaultValue } = children[j]                    // 省略宣言
        if( name ){                                     // nameが設定されていたら
          Object.assign( setData_copy[i] ,{ [name]: defaultValue || ""})  // Object.assign()メソッドでJson結合。defaultValueがあればセット、なければ ""をセット
        }
      }

    }
    for( let i in setData ){         // 初期値の文だけループ
      setData_copy[i] = setData[i]   // Jsonが入るように初期化
      for( let j in children){       // childrenで設定されている数だけループ
        const { name } = children[j] // 省略宣言
        if( setData[i][name] ){      // 値が設定されているところだけ
          Object.assign( setData_copy[i] ,{ [name]: setData[i][name] })  // Object.assign()メソッドでJson結合
        }
      }
    }
    this.setState({ setData : setData_copy }) // コピーした配列をセット
  }
  // ______________________________________________________________________________________________________
  
  shouldComponentUpdate(nextProps) {
    const { setData }  = nextProps;     // 省略宣言
    const { children, } = this.props;
    if(this.props.setData !== setData) {   // 親から渡されるsetDataが変化したら
      const setData_copy = setData.slice(); // 配列stateのコピーを作成
      for( let i in setData ){        // 新たに送られてくる配列の文だけループ
        for( let j in children){      // childrenで設定されている数だけループ
          const { name } = children[j]                    // 省略宣言
          if( setData[i][name] ){                                          // nameが設定されていたら
            Object.assign( setData_copy[i] ,{ [name]: setData[i][name] })  // Object.assign()メソッドでJson結合
          }
        }
      }
      this.setState({ setData : setData_copy }) // コピーした配列をセット
    }

    return true
  }
  // ______________________________________________________________________________________________________

  // 「行追加」ボタンを押した時
  addRows = () => {
    const { setData } = this.state       // state省略宣言
    const { children } = this.props

    const setData_copy = setData.slice(); // 配列をコピー
    setData_copy[ setData.length ] = {}   // Jsonが入るように初期化

    if( setData ){
      for (let key in setData[0]) {
        Object.assign( setData_copy[ setData.length ] ,{ [key] : null })  // 初期値を設定。Object.assign()メソッドでJson結合
      }
    }

    for( let j in children){       // childrenで設定されている数だけループ
      const { name, defaultValue } = children[j] // 省略宣言
      if( name ){                  // nameが設定されていたら
        Object.assign( setData_copy[ setData.length ] ,{ [name]: defaultValue || "" })  // Object.assign()メソッドでJson結合。defaultValueがあればセット、なければ ""をセット
      }
    }

    this.setState({ setData : setData_copy }) // コピーした配列をセット
    this.props.onChange && this.props.onChange(setData_copy)  // 親に渡す
  }
  // _______________________________________________________________________________________________________
  
  // 「行削除」ボタンを押した時

  delRow =(row)=>{
    const { onChange } = this.props;

    const setData_copy = this.state.setData.slice();     // 配列をコピー
    setData_copy.splice( row, 1 );            // spliceメソッドで配列を削除（行を削除）
    this.setState({ 
      setData: setData_copy  // コピーした配列をセット
    },()=>{
      onChange && onChange(this.state.setData) // 親に渡す
    }) 
    
  }
  // _______________________________________________________________________________________________________

  // フォーカスが外れた時
  
  tbodyOnBlur =()=> {
    const {onChange} = this.props;
    const {setData} = this.state;
    onChange && onChange(setData)  // 親に渡す
  }
  // _______________________________________________________________________________________________________

  tbodyOnFocus = () => {
    const { onChange } = this.props;
    const {setData} = this.state;
    if(!this.firstTbodyFocus){
      onChange && onChange(setData);
      this.firstTbodyFocus = true;
    }
  }
  // _______________________________________________________________________________________________________

  // カレンダーに値が入力された時

  // calendarOnChange =(date,i,j)=>{
  //   const value_copy = this.state.value.slice();
  //   value_copy[i][j] = date
  //   this.setState({ value : value_copy })
  // }
  // // _______________________________________________________________________________________________________

  setJsonData =( row,name,value )=>{
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    setData_copy[row][name] = value  // true or false を反転
    this.setState({ setData : setData_copy })        // 変更後のデータをセット
    this.props.onChange && this.props.onChange( setData_copy　) // 親に渡す
  }
  // ________________________________________________________________________________________________________________

  // セルをクリックしたとき
  cellOnClick =( onClick, type, name, row, disabled, e ) =>{
    const { setData } = this.state;       // state省略宣言
    if( disabled ) { return; }
    if(onClick) {
      onClick({   // 親に送っています
        data    : setData,            // 送られてきた全体のデータ
        rowData : setData[row],       // クリックしたセルを含む行のJSONデータ
        value   : setData[row][name], // クリックしたセルの値
        name    : name,               // クリックしたセルのJSONのKey
        rowIdx  : row,                // クリックしたセルの行番号
      })
    }else {
      switch( type ){
        case "check": {  // チェックボックス
          e.stopPropagation() // 親にイベントが伝播するのを停止
          e.preventDefault() 
          let inputValue = setData[row][name] == '1' ? "" : '1'; // チェックボックスの値が 1 だったなら''にする
          this.setJsonData(row, name, inputValue)
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  // ________________________________________________________________________________________________________________

  // セルからフォーカスが外れたとき
  cellOnBlur = (onBlur, name, row, cell) => {
    const { setData } = this.state;       // state省略宣言
    onBlur && onBlur(name, setData[row][name], row)  // 親に送っています
  }
  // ________________________________________________________________________________________________________________

  // ヘッダーのチェックボックスをクリックしたとき
  checkedAll =( cell, name)=>{                        // 引数・・・バインドされているKey情報
    const { headerCheck, setData } = this.state;          // state省略宣言
    const { disabledAll } = this.props;
    if(disabledAll) { return; }

    let inputValue = headerCheck[cell] == '1' ? "" : '1'; // チェックボックスの値が 1 だったなら''にする

    const headerCheck_copy = headerCheck.slice();　// 配列をコピー    
    headerCheck_copy[cell] = inputValue;   // コピーした配列にセット

    const setData_copy = setData.slice(); // 配列をコピー  
    for(let i in setData){  // その行の全てのチェックボックスに対してループ
      setData_copy[i][name] = inputValue;
    }
    this.setState({ 
      headerCheck : headerCheck_copy,
      setData     : setData_copy,
    }) 
  }
  // ________________________________________________________________________________________________________________

  render(){
    // console.log("INPUTTABLE",this.props.setData)

    // スタイル定義
    const { 
      color1, 
      color2, 
      fontColor, 

      children, 
      disabledAll, 
      addHeader,
      rowFixed, 
      must,
    } = this.props

    const { 
      setData, 
      headerCheck,
    } = this.state    
  // ________________________________________________________________________________________________________________

    const headerArr   = [];                   // 親から渡されたheaderを入れて、表示するための配列
    let   typeArr     = [];                   // 親から渡されたtypeを入れて、セルに挿入するための配列
    let   trArr       = [];
    let   tdArr       = [];
    let   tr_color    = null;                 // 行の背景色を変更するための変数    
    // _____________________________________________________________________________________________________

    
    // 初期値用の for ループです。 親からJson形式で渡された中身によって項目を設定しています
    for(let i in children ){
      
      const{ 
        header, 
        colSpan,
        rowSpan, 
        width, 
        allCheck,
        name,
        color,
        colorF,
        headerMust,
      } = children[i]

      const column_style = {
        width:　width,
        background : 
          color ? color : // カラム設定の色
          color1,         // 全体設定の色
        color:
          colorF ? colorF :
          fontColor
      }

      if(header){  // headerが指定されていなかったら処理をスキップ（セル結合をしている）
        headerArr.push(                                         // <th>テーブルヘッダーを作成
          <td
            colSpan = {colSpan}                                 // （親から）colsでセル結合
            rowSpan = {rowSpan}
            style   = {Object.assign({},this.th_style, column_style )}   // （親から）widthでセル幅指定
            key     = {"header" + i }
          >
          {!allCheck ? 
            header: 
            <CheckBox 
              onClick  = {()=>{this.checkedAll(i, name)}} 
              disabled = {disabledAll} 
              value    = {headerCheck[i]} 
              label    = {header} 
            /> // {/* allCheckが設定されていたらチェックボックスを表示*/}
          } 
          {(headerMust || (must && must.indexOf(name) !== -1)) && <span style={this.must_style}>*</span> } {/* indexOf()メソッドで値の有無を確認} */}
          </td>
        )
      }
    }
    // _________________________________________________________________________________________________________

    for(let i in setData ){
      tdArr = [null];
      for(let j in children ){
        const {
          name,
          type,
          onClick,
          onBlur,
          options,
          maxLength,

          width,
          minWidth,
          maxWidth,
          align,
        } = children[j]

        const disabled = disabledAll ? disabledAll : children[j].disabled // 親にdisabledAllが設定されていたらdisabledAll。それ以外なら子のdisabledに対応

        let column_style = {  // カラム設定されたスタイル
          width:　width,
          minWidth : minWidth,
          maxWidth : maxWidth,
          textAlign : align,
        }
              
        // （親から）typeによってセル内に配置する要素を設定しています
        switch(type){
          case("header"):                                              // ヘッダー
          column_style.textAlign = "center" // 真ん中寄せ
          tdArr.push(
              typeArr[j] = <td style = {Object.assign({},this.th_style, column_style )} key={"cell"+i+j}>{ Number(i) + 1　}</td> 
            );
          continue;

          case("check"):{
            typeArr[j] = <CheckBox value = { setData[i][name] } disabled={disabled} color={ color1 }/>
            break;
          }
          
          case("date"):{                                                 // 日付けカレンダー
            typeArr[j] = <TableCalendar disabled={disabled} value={setData[i][name]} onChange  = {(value)=>this.setJsonData(i, name ,value)}/> 
            break                                                         
          }
          case("text"):                                                //　テキストボックス
            typeArr[j] = 
              <TableInput
                value     = {setData[i][name]} 
                disabled  = {disabled}
                maxLength = {maxLength}
                onChange  = {(e)=>this.setJsonData(i, name ,e.target.value)}
              />
          break

          case("number"):{                                                //　テキストボックス(数値)
            typeArr[j] = 
              <TableInput
                number    = {true}
                value     = {setData[i][name]} 
                disabled  = {disabled}
                maxLength = {maxLength}
                onChange  = {(e)=>this.setJsonData(i, name ,e.target.value)}
              />
            break
          }

          case("select"):{
            let filteredData = options.filter((options) => {
              if(options.value === setData[i][name]) return true;
            });        

            typeArr[j] = 
              <Select
                value    = {setData[i][name]}
                label    = {filteredData[0] && filteredData[0].label} 
                options  = {options}
                onChange = {(value) => this.setJsonData(i, name, value)}
                disabled = {disabled}
              />
            break
          }
          case("modal"):{                                                  // モーダル
            typeArr[j] = <button style={this.modal_style} disabled={disabled}/>
            break
          }
          case("delete"): {                                             // 行削除ボタン
            typeArr[j] = 
              <button style={this.delete_style} onClick={()=>this.delRow(i)} disabled={disabled}>
                ×
              </button>
            break;
          }
          case("label"):{
            typeArr[j] = <span style={{padding:"0px 5px"}}>{setData[i][name]}</span>
            break;
          }
          default :                                        // デフォルト
            typeArr[j] = type;                             // type指定したもの（コンポーネントなど）
          break
        }
        // ___________________________________________________________________________________________________________________

        tdArr.push(                                                   // typeに基づいて、セルの作成
          <TD
            style    = { column_style } 
            key      = { "cell" + i + j }
            onClick  = { (e)=> this.cellOnClick( onClick,type,name,i, disabled,e ) }
            onBlur   = { ()=> this.cellOnBlur(onBlur, name, i, j) }
            type     = { type }
            disabled = { disabled }
          >
            {typeArr[j]}
          </TD>
        )
      }

      tr_color = {backgroundColor:"#fff"}    // tr_colorの初期化                                                    
      if(i % 2 !== 0) { tr_color = {backgroundColor: color2} }  // 奇数行に背景色を設定           
                 
      trArr.push(  // 行の作成
        <tr key={"row"+ i} style={tr_color}>
          {tdArr}
        </tr>
      )
    }
    // ___________________________________________________________________________________________________________
    
    return(
      <div style={this.tableWrap_style}>
        <table style={this.table_style}>
          <thead>
            { addHeader }
            <tr>
              {headerArr}
            </tr>
          </thead>
          <tbody 
            style   = {this.tbody_style } 
            onBlur  = {this.tbodyOnBlur }
            onFocus = {this.tbodyOnFocus}
          >
            {trArr}
          </tbody>
        </table>
        <div style={this.buttonArea_style} >
        {!rowFixed && 
          <button style={this.rowsButton_style} onClick={this.addRows} disabled={disabledAll}>行を追加する</button>
        }
        </div>
      </div>

    );

  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class CheckBox extends React.Component{
  constructor(props){
    super(props);
    const { color } = this.props;
    this.boxColor = color ? color:"#777";

    this.wrap_style = {
      display : "flex",
      alignItems:"center",
      height:"100%",
      justifyContent:"center"
    }

    this.boxWrap_style = {
      display:"inline-flex",
      padding:0,
      border:"none",
      backgroundColor:"transparent",
      alignItems:"center",
      boxSizing:"border-box",
      cursor:"pointer",
      fontSize:16,
    }

    this.box_style = {
      height:20,
      width:20,
      boxSizing:"border-box",
      borderRadius:5,
      borderStyle:"solid",
      borderWidth:"2px",
      borderColor:this.boxColor,
      position:"relative",
    }

    this.checked_style = {
      position:"absolute",
      bottom:2,
      left:3,
      boxSizing:"border-box",
      height:15,
      width:10,
      borderRight: "4px solid white",
      borderBottom: "4px solid white",
      transform: "rotate(45deg)",
      borderRadius:5,
    }
  }
  // ________________________________________________________________________________

  render() {
    const {
      value,
      label,
      onClick,
    }=this.props

    Object.assign(this.box_style, {
      backgroundColor: 
      value != '1' ? "white" :
      this.boxColor,
    })
    // ________________________________________________________________________________

    return (
      <div style={ this.wrap_style }>
        { label != "　" && <span>{ label }</span>}
        <button style={ this.boxWrap_style } onClick={ onClick }>
          <div style = { Object.assign({}, this.box_style) }>
            { value != '1' ? null : <div style = { this.checked_style }></div> }
          </div>
        </button>
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// テーブル用インプット
class TableInput extends React.Component{
  constructor(props){
    super(props);
    const { number } = this.props;

    this.text_style = {  // <input> テキストボックスのスタイル
      width:"100%",
      backgroundColor:"transparent",
      border:"none",
      height:25,
      outline:"none",
      boxSizing:"border-box",
      padding:"0px 5px",
      textAlign:
        number ? "right":   // 数値型なら文字を右寄せに
        null,   
    }
  }
  //______________________________________________________________________________________________________
  
  shouldComponentUpdate(nextProps) {
    const { value, disabled } = this.props;
    if(value !== nextProps.value ) { return true; }
    if(disabled !== nextProps.disabled ) { return true; }
    return false;
  }
  //______________________________________________________________________________________________________

  render(){
    const { number, value, disabled, maxLength, onChange } = this.props
    return(
      <input 
        type      = {number ? "tel" : null}
        value     = {value || ""}
        style     = {this.text_style} 
        disabled  = {disabled}
        maxLength = {maxLength}
        onChange  = {onChange} 
      />
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class TD extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false,
      focus : false,
    }
  }
  // ________________________________________________________________________________________________________________
  
  handleOnBlur = () => {
    const { onBlur } = this.props;
    this.setState({ focus : false });
    onBlur && onBlur()
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover, focus } = this.state           // 省略宣言
    const { type, style, disabled } = this.props
    // const { color, index } = this.props.anyProps  // 省略宣言

    const td_style = {            // <td> 各セル
      width:"100%",
      textAlign:"center",
      verticalAlign:"middle",
      border:"1px solid #ccc",
      boxSizing:"border-box",
      transition:"0.2s",
      height : 25,
      boxShadow : 
        disabled ? null :
        (type === 'label' )? null :
        focus ? "0px 0px 2px 0px #00f inset" :
        hover ? "0px 0px 5px 0px #00f inset" :
        null,
      backgroundColor:
        disabled ? "#f7f7f7" :
        focus ? "#eef" :
        null,
      cursor:
        disabled ? null :
        (type === 'label') ? null :
        "pointer",
    }

    return(
      <td
        style        = {Object.assign( {}, td_style, style ) } 
        onClick      = {this.props.onClick }
        onMouseOver  = {()=> this.setState({ hover : true  }) }
        onMouseLeave = {()=> this.setState({ hover : false }) }
        onFocus      = {()=> this.setState({ focus : true  }) }
        onBlur       = {this.handleOnBlur}
      >
        { this.props.children }
      </td>
    )
  }  
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Select extends React.Component{
  constructor(props){
    super(props);
    const { disabled } = this.props;
    this.select_style = {
      height:25,
      width: "100%",
      textAlign:"left",
      paddingLeft:5,
      backgroundColor : "transparent",
      border : "none",
      boxSizing:"border-box",
      cursor : 
        disabled ? null :
        "pointer",
    }
  }
  // ________________________________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { value, options, disabled } = this.props;
    if(value !== nextProps.value) { return true; };
    if(options !== nextProps.options) { return true; };
    if(disabled !== nextProps.disabled) { return true; };
    return false;
  }
  //______________________________________________________________________________________________________

  render(){
    const { options, value, disabled, onChange } = this.props;
    const optArr = [];

    // option を作成するループ
    for(let i in options){
      const { value, label } = options[i];
      optArr.push(
        <option key={value} value={value}>{label}</option>
      )
    }
    // ________________________________________________________________________________________________________________
    
    return(
      <select
        style    = {this.select_style} 
        value    = {value} 
        disabled = {disabled} 
        onChange = {(e)=>{onChange && onChange(e.target.value)}}
      >
        {optArr} 
      </select>
    );
  }
  
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
