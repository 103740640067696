import React from 'react';

export default class Btn extends React.Component {

    render(){
       return (
         // eslint-disable-next-line no-useless-concat
          <button  type='button' 
            className={this.props.className}
            onClick={this.props.onClick}
            onMouseOver = {this.props.onMouseOver}
            onMouseDown = {this.props.onMouseDown}
            style = {this.props.style}
            disabled={this.props.disabled}
          >
          {this.props.children}
          {this.props.label}
          <br />
          {this.props.label2}
          </button>
       );
    }
}

