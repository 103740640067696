import React, { Fragment } from 'react';

export default class Body extends React.Component{
  render(){
    const { image, color, children, style } = this.props
    const wrap_style = {
      padding: "100px 24px 70px 24px",
      minHeight:"100vh",
      background:`url(${image})  no-repeat`,
      backgroundPosition:"center",
      backgroundSize:"100%",
      backgroundColor: color,
      width: "100%",
      minWidth: 1000,
    }
    return(
      <div style={Object.assign({},wrap_style,style)}>
        { children }
      </div>
    );
  }

}