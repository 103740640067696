import React, { Fragment } from 'react';
import {isValidS} from '../util/commonInput';

export default class BasicComponent extends React.Component {
    constructor(props) {
        super(props);
        this.requiredCheck = isValidS;
        this.addOnCheck = this.props.isValid || null;//propsから検証ファンクションを取得
        this.addOnMessage = this.props.error || '';//propsから検証エラーMsgを取得
        this.error = "";
        this.isVaild = true;//データ整合性Default:True
    }
      //Component整合性Check
      makeCheck = (must) =>{
        //1、必須Check
        this.isVaild = this.requiredCheck(this.props,must);
        if(!this.isVaild){this.error="必須‼"};
        //２、カスタマイズCheck
        if(this.addOnCheck){
          this.isVaild = this.isVaild && this.addOnCheck(this.props);
          if(!this.isVaild){this.error += " " + this.addOnMessage };
         }
        return this.isVaild
      }
}
