import React, { Fragment } from 'react';

export default class RadioLisit extends React.Component{
  constructor(props){
    super(props);
    const { value } = this.props
    this.state = {
      value: Number(value) || 0,     // 選択されているアイテム
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps){
    const { value } = nextProps;
    if(this.props.value !== value) {
      this.setState({ value: value})
    }
    return true;
  }
  handleOnClick = (i) => {
    this.setState({ value: i })
    this.props.onChange && this.props.onChange( this.props.name, i )
  }
  // ________________________________________________________________________________

  render() {

    // スタイル定義
    const wrap = {
      margin:"0px 16px 16px 0px"
    }

    const label_style = {
      fontWeight:"bolder",
      fontSize:15,
      // marginRight:,
    }

    const must_style = {
      fontSize:14,
      fontWeight : "bolder",
      color : "#d00", 
    }

    // ________________________________________________________________________________

    // 使用する変数を定義
    const { children, type, disabled, must, label } = this.props
    const { value } = this.state
    const listView  = [];
    // ________________________________________________________________________________

    for( let i in children ){
      let i_copy = Number(i)
      if( type !== "flag" ){
        i_copy += 1
      }

      listView.push(
        <RadioButton 
          key          = { i_copy } 
          selected     = { i_copy == value }
          onClick      = { ()=>this.handleOnClick(i_copy) }
          disabled     = { disabled }
          label        = { children[i] }
        />
      )

    }
    // ________________________________________________________________________________

    return (
      <div style={ wrap } >
      {label &&
        <Fragment>
          <label style={label_style}>{label}</label>
          { must && <span style={must_style}>＊</span> }
          <label style={label_style}>：</label>
        </Fragment>
      }
        {listView}
      </div>
    )
  }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class RadioButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state 
    const { 
      selected, 
      disabled,
      onClick,
      onKeyDown,
    } = this.props

    const button_style = {              // <button> 
      backgroundColor : 
        selected ? "#2b406c" : 
        "transparent",
      color:
        selected ? "#fff" :
        hover    ? "#2b406c" :
        null, 
      borderColor :
        hover    ? "#2b406c" :   
        "transparent",
      marginRight:8,
      cursor:
        disabled ? null :
        "pointer",
      height : 30,
      padding:"0px 16px",
      borderRadius:15,
      fontSize:15,
      boxSizing:"border-box",
      transition:"0.3s",
    }

    return(
      <button 
        style        = { button_style } 
        onClick      = { onClick }
        onKeyDown    = { onKeyDown }
        disabled     = { disabled  }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { this.props.label }
      </button>
    )
  }  
}
