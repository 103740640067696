/* eslint-disable no-unused-expressions */
import React from 'react';

export default class RichTextDisplay extends React.Component {
  constructor(props){
    super(props);
    this.richText_style = {
      fontSize : 16,
      lineHeight :1.5,
    }
  }
  componentDidMount(){
    const { value } = this.props;
    if(value) {this.editor_ref.innerHTML = value;}
  }
  //_______________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if(this.props.value !== nextProps.value) {
      this.editor_ref.innerHTML = nextProps.value
      this.props.onChange && this.props.onChange( this.props.name , this.editor_ref.innerHTML )
      return true;
    }
    return false;
  }
  //_______________________________________________________________________________________________

  render(){
  
    // const { style } = this.props
    //_______________________________________________________________________________________________

    return (
      <div
        style = {this.richText_style} 
        ref   = {(el) => this.editor_ref = el }
      />
    );
  }
}


