import React from 'react'
import * as WQC from "../WQCComponent/WQCComponent";
import * as SKY from "../../component/ComComponent";
import AdobeReader from '../WQCImage/AdobeReader.gif'

export default class PR002 extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <WQC.Body>
        <WQC.Title noDetail noBtn>サイトについて</WQC.Title>
        <WQC.PRParagram label='推奨動作環境'>
          <div>
            <p>当サイトを快適にご利用いただくには、下記のブラウザ・ソフトウェアの環境を推奨します。</p>
            <p>これ以外の環境でご利用いただいた場合、画面表示に若干の違いが出る、もしくは正常に動作しない等の現象がおきる可能性があります。 </p>
          </div>
          <div>
            <p>【最適なブラウザ】</p>
            <p>現在のバージョンではIE 11以上、Crome 85以上、Edge 85以上、Firefox 78以上でのみ動作確認しております。</p>
            <p>またスクリプトやファイルのダウンロードを有効にする等、当サイトが利用できる設定にして下さい。</p>
            {/*
            <p>なお、当サイトで使われるクッキーはご利用の際、フォーム入力等各項目の再入力の負荷を軽減すること及び機能間の一時的連絡を目的としており、</p>
            <p>ユーザーの意図しないセキュリティに関する情報はブラウザを閉じた段階ですべて破棄されます。</p> */}
        </div>        
        </WQC.PRParagram>
        <WQC.PRParagram label='PDF(Portable Document Format)について'>
          <p>本サイトは、各種資料の表示にPDF(Portable Document Format)を使用している機能があります。</p>
          <p>これらのファイルを見るためには、Adobe社のAcrobat Readerプラグインが必要になります。</p>
          <p>お持ちでない方はあらかじめインストールしてから本サイトをご覧ください。</p>
          <p>プラグインは以下の場所より入手して下さい。</p>
          <a href="http://www.adobe.com/jp/products/acrobat/readstep2.html">
            <img alt="Get Acrobat Reader" src={AdobeReader} height={31} width={88} />
          </a>
        </WQC.PRParagram>
        <WQC.PRParagram label='著作権について'>
          <p>当サイトで提供されている情報及び画像等の著作権は富士フイルム和光純薬株式会社、または情報提供企業に帰属します。</p>
          <p>営利・非営利、個人・団体・企業を問わず、弊社サイトに掲載されているコンテンツ等の著作物を、いかなる場合においても無断でコピー・加工・配布・再出版することを認めておりません。</p>        
        </WQC.PRParagram>
        <WQC.PRParagram label='リンクについて'>
          <p>当サイトから、もしくは当サイトにリンクを張っている第三者のウエブサイトの内容は、それぞれ各社の責任で管理されるものであり、富士フイルム和光純薬株式会社の管理下にあるものではありません。第三者のウエブサイトの利用により生じたいかなる損害について富士フイルム和光純薬株式会社は何ら責任を負いません。</p>
          <p>当サイトへのリンクは会員制のサイトである性格上、ご遠慮願います。</p>
        </WQC.PRParagram>
      </WQC.Body>
    )
  }
}