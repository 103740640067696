import React, { Fragment,Component } from 'react';
import {connect} from 'react-redux';
import WQCLogin from './Login';
import WQCTop from './Top';
import WQCIntroduce from './Introduce';
import Blank from '../../component/Blank';
import '../css/wqc-style.css';

class WQCIndex extends Component{

  render(){
    // メニューの選択状態
    let Content = null;
    let {entryType} = this.props;
    switch (entryType) {
        case 0:
         // トップページ移動
          Content = (() => <WQCLogin/>);
          break;
        case 1:
          // 入力画面にページ移動
          Content = (() => <WQCTop/>);
          break;
        case 2:
          // 入力画面にページ移動
          Content = (() => <WQCIntroduce/>);
          break;
        default: 
          Content = (()=> <Blank />);
          break;
      }

    return(
      <Fragment>
        <Content />
      </Fragment>
    );
  }
}

const mapStateToProps = (state,ownPropS) =>{
  return {
    ...state.COMMON.Login
  }
}
export default connect(mapStateToProps)(WQCIndex);