import React, { Fragment } from 'react';
import SearchIcon from "../image/SearchIcon.png";


export default class TextModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value : this.props.value || '',
      hover : false,
      focus : false,
    };
  }
  // ____________________________________________________________________
  componentDidMount(){
    switch( this.props.type ){
      case "password":
        this.forward_ref.type = "password";
      break;
      case "number": case "decimal" : case "comma" :
        this.forward_ref.type = "tel";
      break
      default:
        this.forward_ref.type = "text";
      break;
    }       
    if( this.props.autoFocus ){ this.forward_ref.focus() }    // autoFocus機能
  }
  // _____________________________________________________________________

  shouldComponentUpdate(nextProps){
    if( this.props.value !== nextProps.value){
      this.setState({ value : nextProps.value })
    }
    return true
  }
  // _____________________________________________________________________

  handleOnBlur = e =>{
    this.setState({ focus : false })

    if(this.props.email && e.target.value != ''){
      let regExp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/; 
      if(!regExp.test(e.target.value)){
        e.target.focus();
        alert(' please input correct email!');
      }
    }
    // this.props.onChange && this.props.onBlur(this.props.name ,e.target.value)
    this.props.onChange && this.props.onChange( this.props.name ,this.state.value )
  }
  // _____________________________________________________________________________________________________
  
  handleOnChange = (e) => {
    
    let replaceValue = e.target.value
    // typeによって文字列変換をするためのswitch文です
    switch( this.props.type ){
      case "number" : {
        replaceValue = replaceValue.replace(/[^\d]/g,'');
        break;
      }
      case "decimal" : {
        replaceValue = e.target.value.replace(/[^\d||.]/g,'');
        break;
      }
      case "comma" : {
        replaceValue = e.target.value.replace(/[^\d||,]/g,'');
        break;
      }
      default : 
      break;
    }
    this.setState({ value: replaceValue })
  }
  // _______________________________________________________________________________________________________

  render() {
    const { 
      hover,
      value,
      focus,
    } = this.state;

    const {
      size,
      disabled,
      must,
      label,
    } = this.props
      
    let boxWidth = 156;
    if( size ){
      switch( size ){
        case "ss" : case "SS" :  boxWidth = 70;   break
        case "s"  : case "S"  :  boxWidth = 113;  break
        case "m"  : case "M"  :  boxWidth = 156;  break
        case "l"  : case "L"  :  boxWidth = 328;  break
        case "ll" : case "LL" :  boxWidth = 672; break    
        default   : boxWidth = size; 
      } 
    }
  // _______________________________________________________________________________________________________

  const wrap = {
      position:"relative",
      display:"inline-flex",
      flexDirection:"column",
    }

    const textModal_wrap = {
      height: 30,
      borderRadius:5,
      border : "1px solid #aaa",
      boxSizing:"border-box",
      marginRight:16,
      marginBottom:16,
      display:"inline-flex",
      backgroundColor : 
        disabled ? "transparent" : 
        focus    ? "#eef"  :
        value    ? "#fff"  :
        must     ? "#fee"  :
        "#fff",
      boxShadow: 
        disabled ? null :
        hover    ? "0px 0px 3px 0px #00f" :
        null,
      width: boxWidth,
      transition:"0.3s",
    }

    const text = {
      fontSize:16,
      borderRadius:5,
      // height: 30,
      paddingLeft: 5,
      width:"100%",
      backgroundColor: "transparent",
      border : "none",
      pointerEvents: 
        disabled ? "none" : 
        null,
      boxSizing:"border-box",
      outline:"none",
    }

    const modal = {
      background:`url(${SearchIcon}) no-repeat`,
      backgroundPosition:"center",
      backgroundSize:"18px 18px",
      backgroundColor:"transparent",
      padding:"0px 5px",
      border:"none",
      cursor:"pointer",
      minWidth :30,
      height:"100%",
      pointerEvents: 
      disabled ? "none" : 
      null,
    }
  // _______________________________________________________________________________________________________

    return (
      <Fragment>
        <div style={wrap}>
          <TextLabel label={ label } must = { must } />
          <div
            style        = { textModal_wrap }
            onFocus      = { () => this.setState({ focus : true }) }
            onMouseOver  = { () => this.setState({ hover : true  }) }
            onMouseLeave = { () => this.setState({ hover : false }) }
            onBlur       = { this.handleOnBlur }
          >
            <button style={ modal } onClick ={this.props.onClick} disabled={disabled}/>
            <input 
              // autoComplete = "off"
              style        = { text }
              name         = { this.props.name }
              maxLength    = { this.props.maxLength }
              value        = { this.state.value }
              onChange     = { this.handleOnChange }
              readOnly     = { this.props.readOnly }
              disabled     = { this.props.disabled }
              ref          = { (e) => this.forward_ref = e }
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class TextLabel extends React.Component{
  render(){
    const { label, must }= this.props

    if( !label ){ return null }

    const label_style = {   // <label> テキストのラベル
      fontSize:14,
      marginRight:"1px",
      paddingLeft:"1px",
    }
    const must_style = {    // <span> 必須表示のアスタリスク（＊）
      fontSize:14,
      fontWeight:"bolder",
      color:"#d00", 
    }
    return(
      <div style={{ paddingBottom:"4px"}}>
        <label style={label_style}>{ label }</label>
        { must && <span style={ must_style } >＊</span> }
      </div>
    )
  }  
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
