/* eslint-disable eqeqeq */
import React, { Fragment } from 'react';
import RichTextDisplay from './RichTextDisplay';
import {axiosPost,server} from '../../comRedux/axiosPost';
import FileDownload from '../../image/FileDownload'
import {confirm} from '../../component/ComComponent';

export default class SupportComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setData : this.props.setData || []
    }
    this.commentArea_style = {
      maxWidth : 800,
      margin : 'auto'
    }
  }
  //____________________________________________________________________________\
  shouldComponentUpdate(nextProps){
    const {setData } = nextProps
    if(this.props.setData !== setData){
      this.setState({setData: setData})
    }
    return true;
  }
  //____________________________________________________________________________\
  render(){
    const { loginRegUser, mode, onDeleteComment } = this.props;
    const { setData } = this.state;
    if(!mode){ return null}
    const commentListArr = []; // 値をリスト化して表示するための配列
    for(let i in setData){
      commentListArr.push(
        <Comment
          key         = {'comment'+i}
          {...setData[i]}
          onDeleteComment = {onDeleteComment}
          loginRegUser = {loginRegUser}
        />
      )
    }
    return (
      <div style={this.wrap_style}>
        <SupportTitle {...this.props} />
        <div style={this.commentArea_style}>
        {commentListArr}
        </div>
      </div>
    );
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Comment extends React.Component {
  constructor(props){
    super(props);
    const { extString3 } = this.props;

    this.wrap_style = {
      marginBottom : 16,
      display : 'flex',
      justifyContent : 
      extString3 !== "92" ? null :
        "flex-end",
    };
    this.infoArea_style = {
      display : 'flex',
      flexDirection : "column",
    };
    this.bodyWrap_style = {
      display : "inline-flex",
      alignItems : "flex-end",
      flexDirection :
      extString3 !== "92" ? null :
        "row-reverse",
    }
    this.body_style = {
      padding:8,
      fontSize:16,
      borderRadius: 
      extString3 !== "92" ? "0px 20px 20px 20px" :
        "20px 0px 20px 20px",
      border : "3px solid rgb(224, 224, 224)",
      boxSizing:"border-box",
      maxWidth : 600,
    };
    this.bodyInfo_style = {
      display : "inline-flex",
      alignItems: 'center',
      flexDirection :
        extString3 !== "92" ? null :
          "row-reverse",
    }
    this.extString1_style = {
      padding : "0px 8px",
      fontSize :12,
    }
    this.user_style = {
      display : "flex",
      justifyContent : 
      extString3 !== "92" ? null :
        "flex-end" ,
      marginBottom : 4,
      padding : "0px 1px",
      fontSize :12,
    }
  }
  //______________________________________________________________________

  render() {
    const { 
      toUser,
      fromUser,
      fromUserId,
      body,
      extString1,
      regUser,
      fileName1,
      fileName2,
      fileName3,
    } = this.props;
    if (!body){ return null}
    const { loginRegUser } = this.props
    return(
      <div style={this.wrap_style} >
        <div style={this.infoArea_style}>
          <div style={this.user_style}>
          {/* {toUser != regUser && <span>{toUser}　</span> }  */}
            <span>{fromUser}</span>
          </div>
          <div style={this.bodyWrap_style} >
            <div style={this.body_style} >
              <RichTextDisplay value={body} />
            </div>
            <div style={this.bodyInfo_style}>
              <span style={this.extString1_style}>{extString1}</span>
              <DeleteLink {...this.props} render={loginRegUser == fromUserId} />
            </div>
          </div>
          <FileComponent {...this.props} fileName={fileName1} fileNo={'1'}/>
          <FileComponent {...this.props} fileName={fileName2} fileNo={'2'}/>
          <FileComponent {...this.props} fileName={fileName3} fileNo={'3'}/>
        </div>
      </div>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class SupportTitle extends React.Component {
  constructor(props){
    super(props);
    this.sdeskType1Name = "";
    this.sdeskType2Name = "";
    this.wrap_style = {
      display : "flex",
      justifyContent : "space-between",
      borderBottom : "5px solid rgb(224, 224, 224)",
      paddingBottom : 4,
      marginBottom : 16,
      alignItems : "flex-end",
    }
    this.sdeskType_style = {
      marginLeft : 16,
      marginTop:4,
      whiteSpace : 'nowrap'
    }
    this.title_style = {
      fontSize : 20,
      fontWeight : 'bolder',
      marginRight : 16,
      color : 'rgb(38, 176, 136)',
    }
    this.user_style = {
      marginTop:4,
      whiteSpace : 'nowrap'
    }
  }
  //_______________________________________________________________________________________________

  render(){

    const {
      title,
      sdeskType1,
      sdeskType2,
      sdeskTypeData1,
      sdeskTypeData2,
      regUser,
      regUserName,
    } = this.props;
    //_______________________________________________________________________________________________

    if(sdeskType1) {
      let filteredData1 = sdeskTypeData1.filter( sdeskTypeData1 => { // setDataにフィルターをかけて
        if (sdeskTypeData1.value === sdeskType1 ) return true;  // valueがあるデータを参照 filteredData = [{ value :"", label : ""}]
      })
      this.sdeskType1Name = filteredData1[0].label
    }
    if(sdeskType2) {
      let filteredData2 = sdeskTypeData2.filter( sdeskTypeData2 => { // setDataにフィルターをかけて
        if (sdeskTypeData2.value === sdeskType2 ) return true;  // valueがあるデータを参照 filteredData = [{ value :"", label : ""}]
      })
      this.sdeskType2Name = filteredData2[0].label
    }
    //_______________________________________________________________________________________________

    return (
      <div style={this.wrap_style}>
        <div>
          <span style={this.title_style}>{title}</span>
          <span style={this.user_style}>施設名：{regUser}　{regUserName}</span>
        </div>
        <div>
          <span style={this.sdeskType_style}>種別1：{this.sdeskType1Name}</span>
          <span style={this.sdeskType_style}>種別2：{this.sdeskType2Name}</span>
        </div>
      </div>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class FileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: '#eee',
      padding: '16px',
      borderRadius: '20px',
      marginBottom: '4px',
      marginTop: '16px',
      width: '400px',
      fontWeight: 'bolder',
    }
    this.file_style = {
      cursor: 'pointer',
      marginRight: 8,
    }
  }
  //_______________________________________________________________________________________________
  click_file = () => {
    const { fileName, sdeskNo, sdeskSeq, fileNo } = this.props;
    let json = {sdeskNo:sdeskNo ,sdeskSeq:sdeskSeq, fileNo:fileNo};
    axiosPost.post(server+'/SA001/download',json, {responseType: 'arraybuffer'})
    .then((res) => {
        // ダウンロードしたいコンテンツ、MIMEType、ファイル名
        var mimeType = 'application/octet-stream';
  
        // BOMは文字化け対策
        var bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
        var blob = new Blob([res.data], {type : mimeType});
  
        var a = document.createElement('a');
        a.download = fileName;
        a.target   = '_blank';
  
        if (window.navigator.msSaveBlob) {
          // for IE
          window.navigator.msSaveBlob(blob, fileName)
        }
        else if (window.URL && window.URL.createObjectURL) {
          // for Firefox
          a.href = window.URL.createObjectURL(blob);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        else if (window.webkitURL && window.webkitURL.createObject) {
          // for Chrome
          a.href = window.webkitURL.createObjectURL(blob);
          a.click();
        }
        else {
          // for Safari
          window.open('data:' + mimeType + ';base64,' + window.Base64.encode(res.data), '_blank');
        }
    }) 
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
        return
    })
   };
  // _______________________________________________________________________________________

  render(){
    const { fileName } = this.props
    if(!fileName) { return null}
    return (
      <div style={this.wrap_style}>
        <div style={this.file_style} onClick={this.click_file}>
          <FileDownload color='rgb(43, 64, 108)' width={30} height={30} color2={'rgb(86, 128, 180)'}/>
        </div>
        <div>
          {fileName}
        </div>
      </div>
    )
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class DeleteLink extends React.Component {
  constructor(props) {
    super(props);
    this.delete_style = {
      fontSize: 12,
      border: 'none',
      backgroundColor: 'transparent',
      color: 'red',
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  }
  //_______________________________________________________________________________________________  
  click_delete = async() =>{
    const result = await confirm({ message: '削除してもいいですか?' });　// 確認コンファームをします
    if(!result) { return null}
    const { sdeskNo, sdeskSeq, } = this.props;
    var json = {sdeskNo:sdeskNo ,sdeskSeq:sdeskSeq };
    axiosPost.post(server+'/SA001/delete',json,)
    .then((res) => {
      this.props.onDeleteComment && this.props.onDeleteComment(res.data)
      // console.log(res.data);
    })
      .catch(err => {
        alert(err);
        console.log('JSON読み込みエラー')
          return
      })
  };
  // _______________________________________________________________________________________

  render(){
    const { render } = this.props
    if(!render) { return null}
    return (
      <button style={this.delete_style} onClick={this.click_delete}>削除</button>
    )
  }
}