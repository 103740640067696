import React from 'react';

export default class PRParagram extends React.Component{
  render(){
    const { label, hidden, children } = this.props
    if(hidden){ return null};
    const wrap_style = {
      display: 'flex',
      flexDirection: 'column',
    }
    const label_style = {
      fontWeight: 'bolder',
      marginBottom: 8,
      fontSize: 18,
      color: 'rgb(1, 144, 109)',
    }
    const contents_style = {
      marginBottom: 16,
      lineHeight: 1.7,
      fontSize: 16,
    }

    return(
      <div style={wrap_style}>
        <div style={label_style}>{label}</div>
        <div style={contents_style}>
          {children}
        </div>
      </div>
    )
  }
}