import React from 'react';

export default class ValidationInputs extends React.Component {
    // constructor(props) {
    //     super(props);
    //   }

      // .span-error{

      //   /* box-shadow:  */
      // }
      
      // .span-error:after{
      //   content: '';
      //   position: absolute;
      //   top:100%;
      //   left:5px;
      //   border-top: 6px solid #e21;;
      //   border-right: 6px solid transparent;
      //   border-bottom: 6px solid transparent;
      //   border-left: 6px solid transparent;
      // }

      render() {
        const wrap = {
          position:"absolute", 
          right:16,
          boxSizing:"border-box"
        }
        const error = {
          backgroundColor: "#fff",
          border: "1px solid red",
          borderRadius: 5,
          fontSize: 12,
          padding: "4px 6px",
          color: "#f00",
          boxSizing:"border-box",
          position: "relative",
        }

        const triangle = {
          width: 0,
          borderTop: "7px solid red",
          borderRight: "10px solid transparent",
          borderLeft: "10px solid transparent",
          position: "absolute",
          top: "100%",
          left: 0,
        }
        // _________________________________________________________


        return(
            <div style={ wrap } >
             <div style={ error } >
               {this.props.msg || ""}
               <div style = { triangle }></div>
             </div>
            </div>
        )
      }
} 