import React, { Fragment } from 'react';

export default class Wrapper extends React.Component {

  render() {
    const {
      disabled,
      children
    } = this.props
      
  // _______________________________________________________________________________________________________

  const wrap = {
      position:"relative",
      display:"inline-flex",
      borderRadius:5,
      border : "1px solid #aaa",
      backgroundColor : "#fff",
      paddingLeft: 16,
      paddingTop: 16,
      marginBottom: 16,
    }
  // _______________________________________________________________________________________________________

    return (
      <div style={wrap}>
        {children}
      </div>
    )
  }
}

