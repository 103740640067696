import React,{Fragment} from 'react';

export default class Col extends React.Component{
  constructor(props){
    super(props);
    this.state={
      responsiveStyle: null,
      setData : this.props.setData || []

    }
  }
  componentDidMount() {
    const responsive = this.props.responsive
    if(responsive){

      window.addEventListener('resize',this.resizeEvent);

      if( window.innerWidth < responsive[0].break ){
        // console.log(responsive[0].style)
        this.setState({ responsiveStyle : responsive[0].style })
      }
    }
    
  }

  componentWillUnmount() {
    if(this.props.responsive){
      window.removeEventListener('resize', this.resizeEvent);
    }
  }

  resizeEvent=()=>{
    const responsive  = this.props.responsive;
    if( window.innerWidth < responsive[0].break ){
      this.setState({responsiveStyle : responsive[0].style })
    }
    if( window.innerWidth > responsive[0].break ){
      this.setState({responsiveStyle : null })
    }
  }

  render(){
    
    const style={
      display:"flex",
      flexDirection:"column",
      // backgroundColor:"blue",
      // justifyContent:"space-between",
      alignContent:"space-between",
      whiteSpace:this.props.nowrap? "nowrap" : null,
      width:this.props.w ? this.props.w : "100%",
      padding:this.props.padding,
      margin:this.props.margin,
    }

    const label_style = {
      padding:"16px 16px 16px 5px",
      fontSize:20,
      fontWeight:"bolder",
    }

    const { setData } = this.state
    const { name    } = this.props
    const listView = []

    for( let i in setData ){
      listView.push(
        <ListButton 
          onClick = { ()=> { this.props.onClick && this.props.onClick( setData[i] )} }
          label   = { setData[i][name] } 
        />
      )
    }


    return(
      <div style={Object.assign({},style,this.props.style, this.state.responsiveStyle)}>
        <div style={ label_style }>
          {this.props.label}
        </div>
        { listView }
      </div>
    );
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class ListButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state 
    const { selected } = this.props
    const button_style = {       
      fontSize:16,
      textAlign:"left",
      padding:5,
      border:"none",
      backgroundColor:
        hover    ? "#ccc":
        selected ? "#ccf":
        "transparent",
      cursor:"pointer",
      borderRadius:5,
      width:"100%",
      marginBottom:"2px",
      boxSizing:"border-box",
      transition:"0.2s",
    }

    return(
      <button 
        style        = { button_style } 
        value        = { this.props.value }
        onClick      = { this.props.onClick }
        onKeyDown    = { this.props.onKeyDown }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { this.props.label }
      </button>
    )
  }  
}