import React, { Fragment } from 'react';
import { connect } from 'react-redux';

class ErrorMsgBar extends React.Component{
  constructor(props){
    super(props);
    this.err_ref = React.createRef();
    
  }
  componentDidMount() {
    const { currentPage, searchErrMsg, inputErrMsg } = this.props;
    switch( currentPage ){
      case 1 : this.err_ref.innerHTML = searchErrMsg; break;
      case 2 : this.err_ref.innerHTML = inputErrMsg; break;
      default : break;
    };
  }
  // ______________________________________________________________________________

  shouldComponentUpdate(nextProps,){
    switch(nextProps.currentPage){
      case 1 : {
        if(this.props.searchErrMsg !== nextProps.searchErrMsg){
          this.err_ref.innerHTML = nextProps.searchErrMsg;
          return true;
        }
        break;
      }
      case 2 : {
        if(this.props.inputErrMsg !== nextProps.inputErrMsg){
          this.err_ref.innerHTML = nextProps.inputErrMsg;
          return true;
        }
        break;
      }
      default : return false;
    };
    return false;
  };
  // ______________________________________________________________________________


  render(){
    const { style } = this.props;
    const err_style = {
      display :
        (!this.err_ref.innerHTML) ? "none" :
        null, 
    };
    return( 
      <div style={Object.assign({}, style, err_style)} ref={(el)=> this.err_ref = el} /> 
    ); 
  };
}

const mapStateToProps = (state,props) =>{
    return {
        searchErrMsg:state.COMMON.Common.searchErrMsg,
        inputErrMsg:state.COMMON.Common.inputErrMsg,
        currentPage:state.COMMON.Common.currentPage,
    }
  }
  
export default connect(mapStateToProps,null)(ErrorMsgBar);
