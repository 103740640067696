import React, { Fragment } from 'react';
import * as WQC from "../../WQCComponent/WQCComponent";
import * as SKY from "../../../component/ComComponent";
import {axiosPost,server} from '../../../comRedux/axiosPost';

export default class PU_001 extends React.Component{
  constructor(props){
    super(props);
      this.state = {
        tableOpen   : false,　// テーブルの表示／非表示  
        showLoading : false,　// ローディングの表示／非表示
      }
  }
  //___________________________________________________________________________

  componentDidMount(){
    const { value } = this.props;
    this.setState({ value : value });
  }
  //___________________________________________________________________________

  // 一覧を選択した時
  click_select = ( rowData ) => {
    const { onClick } = this.props;
    const { regUser, regUserName } = rowData;
    onClick && onClick(regUser, regUserName);
  }
  //___________________________________________________________________________

  // テキストボックスの内容が変更された時にstateを更新
  handleOnChangeValue = (name, value) => {
    this.setState({ [name] : value });
  }
  //___________________________________________________________________________

  // 検索ボタンを押した時
  click_search = () => {
    this.setState({ showLoading : true, tableOpen:false, });
    const data1 = this.regUser.state.value;
    const data2 = this.regUserName.state.value;
    const json = { regUser : data1, regUserName : data2 };
    axiosPost.post(server+'/AC001/searchPop',json)
    .then((res) => {
      if(res.data != null){
        if (res.data.formErrorMsg != null) {
          // dispatch(error(key,res.data.formErrorMsg))
        }else{
          this.setData = res.data;      // ここでデータが変わる
          this.setState({
            tableOpen   : true,
            showLoading : false,
          });
        }
      }
    })
    .catch(err => {
      if(err.response){
        if (err.response.status==400) {
          return  alert('タイムアウトしました。再度ログインしてください');
        } else {
          return alert(err.response.data.exception+'\n'+err.response.data.message)
        }
      }else{
        //FataErrorのNetWorkNotExist
       return alert('unknown error'+err);
      }
    })
  }
  //___________________________________________________________________________

  render(){
    const { tableOpen, showLoading } = this.state;
    return(
      <Fragment>
      { showLoading && <SKY.Loading/> }
      <WQC.PopupBody>
        <WQC.PopupContents label="施設マスタ" onClick={()=>SKY.closeModal()}>
        <SKY.Text label = "登録番号" ref = { (el) => this.regUser = el } autoFocus />
          <SKY.Text label = "施設名" ref = { (el) => this.regUserName = el } size="L"/>
          <WQC.SearchBtn onClick = { this.click_search } />
        </WQC.PopupContents>
        <SKY.Low>
        { tableOpen &&
          <SKY.ListTable
            setData     = { this.setData }　
            rowOnClick  = { this.click_select } 
            maxRows     = { 10 }
            color1      = { WQC.SearchTableColor1 }
            color2      = { WQC.SearchTableColor2 }
            fontColor   = { WQC.SearchTableColorF }
          >
          {[
            { header:" ", type:"header", width:"50px",},
            { header:"登録番号", type:"number", binding:"regUser", width:"100px", align:"center"},
            { header:"システム上の施設名", type:"text", binding:"regUserName", },
            { header:"施設名称", type:"text", binding:"shisetsuName", },
            { header:"都道府県", type:"text", binding:"adrs1", width:"100px"},
          ]}
          </SKY.ListTable>
          }
        </SKY.Low>
      </WQC.PopupBody>
      </Fragment>
    );
  }
}