import React from 'react';

export default class PopupBody extends React.Component{
  render(){
    const { children } = this.props
    const wrap_style = {
      padding: "0px 16px",
      width : "100%",
    }
    return(
      <div style={ wrap_style }>
        { children }
      </div>
    );
  }

}