import React, { Fragment } from 'react';

export default class CheckList extends React.Component{
  constructor(props){
    super(props);
    this.state={
      value     : [null],     // 選択されているアイテム
      hover     : null,   // オンマウス中のアイテム
      setData   : this.props.setData,
    }
  }
  // ________________________________________________________________________________

  componentDidMount(){
    if( this.props.value ){
      this.setState({ value: this.props.value })           // 初期値の設定
    }
  }
  // ________________________________________________________________________________
  
  // <CheckList ref={ e => this.checklistRef = e } />
  // <button onClick={()=> this.checklistRef.getJson() }>
  // という形で、このファンクションを親から指定できます。

  getJson(){
    const binding = this.props.binding;
    const value   = this.state.value;
    let jsonData = [];
    for( let i = 0; i < value.length; i++ ){
      jsonData.push( { [binding] : value[i] } )
    } 
    return jsonData
  }
  // ________________________________________________________________________________


  handleClick =(i,e)=>{
    const { onChange, name, } = this.props
    const { value } = this.state
    const value_copy = value.slice();
    if( value_copy[i] !== e.target.value ){
      value_copy[i] = e.target.value
    }else{
      value_copy[i] = null
    } 
    this.setState({ value : value_copy })
    onChange && onChange( name,value_copy )
  }
  // ________________________________________________________________________________

  hover =(action,e)=> {
    switch(action){
      case "in" : 
        e.target.style.backgroundColor = "#2b406c";
        e.target.style.color = "#FFF";
      break;
      case "out": 
        e.target.style.backgroundColor = "transparent";
        e.target.style.color = null ;
      break;
      default : break;
    }
  }
  // ________________________________________________________________________________________________________________

  render() {
    const {
      sideways,
      disabled,
      must,
      binding,
      label,
    } = this.props 

    const { 
      value,
      setData,
      hover,
    } = this.state
    // スタイル定義
    let notInValue = false
    if( must ){
      notInValue = true
      for( let i = 0 ; i < value.length; i++ ){
        if( value[i] ){ 
          notInValue = false 
        }
      }
    }

    const wrap  = {
      display:"inline-flex",
      flexDirection:"column",
      boxSizing:"border-box",
      marginBottom:16,
    }

    const list_wrap = {
      display:"inline-flex",
      flexDirection: sideways ? "row" :"column",
      flexWrap:"wrap",
      boxSizing:"border-box",
      border:"1px solid #aaa",
      borderRadius:5,
      padding:5,
      marginBottom:4,
      backgroundColor : 
        disabled? "transparent" : 
        notInValue ? "#fee" : 
        "#fff",
    }

    const default_style = {
      backgroundColor : "transparent",
      border :"none",
      cursor:"pointer",
      padding:"4px 8px",
      margin:2,
      fontSize:16,
      borderRadius:5,
      textAlign:"left",
      boxSizing:"border-box",
      transition:"0.2s",
    }

    const allButton = {
      borderRadius:5,
      fontSize:12,
      marginRight:8,
      padding:4,
      cursor:"pointer",
      border :"none",
      backgroundColor : "transparent",
      transition:"0.2s",
      fontWeight:"bolder"
    }
    
    // ________________________________________________________________________________

    // 使用する変数を定義
    let takenData         = [];
    const listArr         = [];
    let   selected_style  = null
    // ________________________________________________________________________________

    // データ抽出処理

    for ( let i = 0; i < setData.length; i++ ){
      takenData.push( setData[i][binding] );
    }
    //__________________________________________________________________________________

    // 重複データを削除する処理

    takenData = takenData.filter(function (x, i, self) {
      return self.indexOf(x) === i;
    });
    // ________________________________________________________________________________________________________________


    for( let i = 0; i < takenData.length; i++ ){
      selected_style  = null;
      if( value[i] === takenData[i] || i === hover ){       // 選択中のアイテム、またはオンマウス中のアイテムなら
        selected_style = {
          backgroundColor : "#2b406c",
          color:"#fff",
        }
      }

      listArr.push(
        <button 
          key          = { takenData[i] } 
          value        = { takenData[i] }
          style        = { Object.assign({}, default_style, selected_style) }
          onClick      = { (e)=> this.handleClick(i,e) }
          onMouseOver  = { ()=> this.setState({ hover: i }) }
          onMouseLeave = { ()=> this.setState({ hover: null }) }
          disabled     = { disabled }
        >
          { takenData[i] }
        </button>
      )

    }
    // ________________________________________________________________________________
    return (
      <Fragment>
        <div style={wrap}>
          <Label label={ label } must={ must } />
          <div style={ list_wrap }>
            {listArr}
          </div>
          <div>
            <button
              style        = { allButton } 
              onClick      = { ()=> this.setState( {value: takenData }) }
              onMouseOver  = { (e)=> this.hover( "in" ,e ) } 
              onMouseLeave = { (e)=> this.hover( "out",e ) } 
            >
              すべて選択
            </button>
            <button
              style        = { allButton } 
              onClick      = { ()=>  this.setState( {value: [] }) }
              onMouseOver  = { (e)=> this.hover( "in" ,e ) } 
              onMouseLeave = { (e)=> this.hover( "out",e ) } 
            >
              すべて選択解除
            </button>
          </div>
        </div>
      </Fragment>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class ItemButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state 
    const { 
      selected,
      value,
      onClick,
      onKeyDown,
      label,
    } = this.props

    const button_style = {              // <button> リストアイテム
      height:25,
      textAlign:"left",
      paddingLeft:5,
      border:"none",
      backgroundColor:
        hover    ? "#ccc":
        selected ? "#ccf":
        "transparent",
      cursor:"pointer",
      borderRadius:5,
      width:"100%",
      marginBottom:"2px",
      boxSizing:"border-box",
      transition:"0.2s",
    }

    return(
      <button 
        style        = { button_style } 
        value        = { value }
        onClick      = { onClick }
        onKeyDown    = { onKeyDown }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { label }
      </button>
    )
  }  
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Label extends React.Component{
  render(){
    const { label, must }= this.props

    if( !label ){ return null }

    const label_style = {   // <label> テキストのラベル
      fontSize:14,
      marginRight:"1px",
      paddingLeft:"1px",
    }
    const must_style = {    // <span> 必須表示のアスタリスク（＊）
      fontSize:14,
      fontWeight:"bolder",
      color:"#d00", 
    }
    return(
      <div style={{ paddingBottom:"4px"}}>
        <label style={label_style}>{ label }</label>
        { must && <span style={ must_style } >＊</span> }
      </div>
    )
  }  
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



/*
const testData=[
  { value :" " , label :" " },
  { value :1 , label :"test1" },
  { value :2 , label :"test2" },
  { value :3 , label :"test3" },
  { value :4 , label :"test4" },
]

*/
