import React from "react";

export default class Loading extends React.Component{
  constructor(props){
    super(props);
    this.state={
      secondsElapsed:0,
    }
  }
  tick=()=>{
    this.setState({secondsElapsed: this.state.secondsElapsed + 5 });
  }
  componentDidMount(){
    this.interval = setInterval( this.tick , 10 )
  }
  componentWillUnmount(){
    clearInterval(this.interval);
  }
  stopAction =(e)=>{
    e.stopPropagation() // 親にイベントが伝播するのを停止
    e.preventDefault()  // 親にイベントが伝播するのを停止
    return;
  }
  render(){
  const wrap = {
    position:"fixed",
    zIndex:999,
    left: '0px',
    top: '0px',
    right: '0px',
    bottom: '0px',
    width: '100%',
    height: '100%',
    backgroundColor:'rgba(255,255,255,0.3 )',
  }
  const loading_style ={
    position:"fixed",
    left: '0px',
    top: '0px',
    right: '0px',
    bottom: '0px',
    margin: 'auto',
    width:200,
    height:200,
    borderRadius:"100%",
    borderTop:"15px solid transparent",
    borderRight:"15px solid   rgba(85, 109, 174, 0.3)",
    borderBottom:"15px solid  rgba(85, 109, 174, 0.3)",
    borderLeft:"15px dashed   rgba(85, 109, 174, 0.3)",
    backgroundColor:"transparent",
    outline:"transparent",
    transform: "rotate(" + this.state.secondsElapsed + "deg)",
    
  }
    return(
      this.props.wrap ? 
      <div style={ wrap }>
        <button style={loading_style} autoFocus onKeyDown = { this.stopAction }  >
        </button>
      </div>
      :
      <button style={loading_style} autoFocus onKeyDown = { this.stopAction } >
      </button>
      
    );
  }
}