import React from 'react';

export default class CheckBox extends React.Component{
  constructor(props){
    super(props);
    this.state = { 
     value  : this.props.value || "",//true:'1' false:''
    }
  }
  // _______________________________________________________________________________

  componentDidMount(){
    this.setState({ value: this.props.value })
  }
  // _______________________________________________________________________________

  shouldComponentUpdate(nextProps){
    if(nextProps.value !== this.state.value){
      this.setState({ value: nextProps.value　})
      this.props.onChange && this.props.onChange(this.props.name, nextProps.value );
    }
    return true;
  }
  // _______________________________________________________________________________

  handleOnChange = () => {
    let inputValue = this.state.value == '1'? '': '1';  // チェックボックスの値が 1 だったなら''にする
    this.setState({ value: inputValue })
    this.props.onChange && this.props.onChange(this.props.name, inputValue );
}
  // _______________________________________________________________________________

  // チェックボックスをEnterでも操作できるようにする処理。　キーダウン時
  handleKeyDown=(e)=>{
    if(e.keyCode === 13 ){  // チェックボックスかつ、Enterをクリックしたなら
      let inputValue = this.state.value == '1'? '': '1';  // チェックボックスの値が 1 だったなら''にする
      this.setState({ value: inputValue })
      this.props.onChange && this.props.onChange(this.props.name, inputValue );
    }
  }
  // _______________________________________________________________________________

  render() {
    const {
      color,
      disabled,
      label,
      children,
    }=this.props

    const { 
      value
    } = this.state

    const boxColor = color ? color:"#777";
    
    // ________________________________________________________________________________

      const wrap = {
      display:"inline-flex",
      padding:0,
      border:"none",
      backgroundColor:"transparent",
      alignItems:"center",
      boxSizing:"border-box",
      cursor:
        disabled ? null : 
        "pointer",
      fontSize:16,
      marginBottom:16,
      marginRight:16,
    }

    const box = {
      height:20,
      width:20,
      marginRight:4,
      backgroundColor: 
        value != '1' ? "white" :
        boxColor,
      boxSizing:"border-box",
      borderRadius:5,
      borderStyle:"solid",
      borderWidth:"2px",
      borderColor:boxColor,
      position:"relative",
      transition:"0.3s",
    }

    const checkedStyle = {
      position:"absolute",
      bottom:2,
      left:3,
      boxSizing:"border-box",
      height:15,
      width:10,
      borderRight: "4px solid white",
      borderBottom: "4px solid white",
      transform: "rotate(45deg)",
      borderRadius:5,
    }
    // ________________________________________________________________________________

    return (
      <div>
        <button style={ wrap } onKeyDown={this.handleKeyDown} onClick={this.handleOnChange} disabled={ disabled }>
          <div style = { box }>
            { value != '1' ? null : <div style = { checkedStyle }></div> }
          </div>
        <span>{label}{children}</span>
        </button>
      </div>
    );
  }
}

