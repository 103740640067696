import React, { Fragment,Component } from 'react';
import {BrowserRouter,Router, Link, Route, Switch} from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux'
import { history } from '../../comRedux/createStore';
import RouterContainer from './RouterContainer';
import {logOut, logStatus} from '../../comRedux/Actions';
import PopPU001 from '../pu/pu001/PU001';
import * as WQC from "../WQCComponent/WQCComponent";
import * as SKY from "../../component/ComComponent";
import  '../css/wqc-style.css';
//________________________________________________________________
import { push } from 'connected-react-router'　//Logout用
//________________________________________________________________

import {axiosPost,server} from '../../comRedux/axiosPost';

//________________________________________________________________

class Top extends Component {

  constructor (props) {
    super(props);
    this.state = {
      regUser     : '',　//　表示されている編集中の施設番号 
      regUserName : '',　//　表示されている編集中の施設名
      loginWithSurvey : '',　//
      superGrant  : '',  //  管理者権限の有無
      cntSdeskNew : 0, //  新規の書き込み件数
      // cntSdeskInSupport : '0', // 対応中の書き込み件数
    }
  }
  // _______________________________________________________________________________________
  
  // 画面ロード時
  async componentDidMount() {
    axiosPost.post(server+'/top/getRegUser')
    .then((res) => {
      console.log(res.data);
      if (res.data != null) {
        this.setState({ 
          regUser     : res.data.regUser, 
          regUserName : res.data.regUserName,
          loginWithSurvey : res.data.loginWithSurvey, 
          superGrant  : res.data.superGrant,
        });
      } 
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
        return
    })
    // 
    // axiosPost.post(server+'/top/geSdeskInfo')
    // .then((res) => {
    //   console.log(res.data);
    //   if (res.data != null) {
    //     this.setState({ 
    //       cntSdeskNew     : res.data.cntSdeskNew, 
    //       cntSdeskInSupport : res.data.cntSdeskInSupport 
    //     });
    //   } 
    // })
    // .catch(err => {
    //   alert(err);
    //   console.log('JSON読み込みエラー')
    //     return
    // })

    axiosPost.post(server+'/menu-WQC/supportCnt')
    .then((res) => {
      console.log(res.data);
      this.setState({ cntSdeskNew : res.data});
    })
    .catch(err => {
      console.log(err)
      return
    })
  }
    
  // _______________________________________________________________________________________
  
  // ログアウト
  handleLogOut = async() => {
    const result = await SKY.confirm({ message: "LogOutしますか?" });
    if( result ){
      //history.push({location:'/',pathname:'/'})//clear path when logout
      this.props.dispatch(push('/',{showPrompt:false}))
      this.props.dispatch(logOut());
      
      // セッション削除
      axiosPost.get(server+'/session/remove')
      .then((res) => {})
    }


  }
  // _______________________________________________________________________________________

  updateEmpInfo = (e,code,name) =>{
    var selectValuesNew = Object.assign({}, this.state.selectValues)
    selectValuesNew['regUser'] = code;
    selectValuesNew['regUserName'] = name;
    this.setState({});
  }
  // _______________________________________________________________________________________

  // 「編集する施設を変更する」アイコンを押した時
  clickChangeUser = () => {
    SKY.openModal( <PopPU001 onClick={(code,name)=>this.set_PU001(code,name)}/> )
  }
  // _______________________________________________________________________________________

  // モーダルのリストを選択したとき
  set_PU001 = (code, name) => {
    history.push({location:'/',pathname:'/'})//clear path when logout
    let json = { regUser : code };
    axiosPost.post(server+'/top/chgUser',json)
    .then((res) => {
      if(res.data != null){
        this.setState({
          regUser     : code,
          regUserName : name
        })
      }
    })
    .catch(err => {
      // セッションエラー
      if (err.response.status==200) {
        // dispatch(sessionError(key,err))
      } else {
        // dispatch(fatalError(key,err))
      }
    })
    SKY.closeModal(); // モーダルを閉じる
    this.forceUpdate();//Render
  }
  // _______________________________________________________________________________________

  render(){
    // console.log(this.props)
    const { regUser, regUserName,loginWithSurvey, superGrant, cntSdeskNew } = this.state;
    let menuList = null
    if(this.props.menuList) { menuList = this.props.menuList.menuList }
    
    const linkList = [
      { label : 'WQC-webについて', link : '/pr/PR001'},
      { label : 'サイトについて', link : '/pr/PR002'},
      { label : 'プライバシーポリシー', link : '/pr/PR003'},
    ]

    return(
      <Fragment>
      <ConnectedRouter history={history}>
      <WQC.Header 
        logo          = 'WQC-web' 
        regUser       = {regUser} 
        regUserName   = {regUserName} 
        loginWithSurvey   = {loginWithSurvey} 
        superGrant    = {superGrant}
        onClickLogout = {this.handleLogOut} 
        onClickIcon   = {this.clickChangeUser}
      />
      <WQC.Menu setData={menuList} cntSdeskNew={cntSdeskNew}/>      
      <RouterContainer history={history}/>
      <WQC.Footer setData={linkList}/>    {/* フッター */}
      </ConnectedRouter>
    </Fragment>

    );
  }
}
// _______________________________________________________________________________________

const mapStateToProps = (state,ownPropS) =>{
  return {
    //stateの範囲を縮めるとRender回数は減ります
    ...state.COMMON.Menu
  }
}
export default connect(mapStateToProps)(Top);