import React, {Fragment} from 'react';
import search_icon from "../image/search_icon.png";
import filter_icon from "../image/filter.svg";
import copy_icon from "../image/copy_icon.png";
import edit_icon from "../image/edit_icon.png";
import delete_icon from "../image/delete_icon.png";


export default class ListTable extends React.Component{
  constructor(props){
    super(props);
    const {
      setData,
      maxRows,
      startPage,
      initialState,
    } = this.props;

    this.state = {
      setData     : setData,   // セットされたデータ
      maxRows     : maxRows,   // 一度に表示する行の最大数
      paging      : startPage || 1,   // 現在のページ数
      pageX       : null,   // ヘッダークリック時のマウスのX座標
      pageY       : null,   // ヘッダークリック時のマウスのY座標
      startRows   : 0,      // 表示中の開始行数
      showFilter  : false,  // フィルタリングメニューの表示／非表示
      searchItems : "",     // フィルタリングの検索ボックスの値。
      filterName  : null,   // フィルタリングに使用するヘッダー情報
      filterLabel : null,   // フィルタリングメニューに表示されるヘッダー情報
      sortedData  : null,   // データをソートするときに使用される昇順／降順の管理
      rowHover    : null,   // どこの行がonMouseされているか管理
      tableHeight : null,   // ウィンドウの高さを自動検知して、テーブルにスクロールを出す高さを計算する
      headerCheck : [],     // ヘッダーのチェックボックスの値
      changeRow　 : "",     // 表示件数を変更するテキストボックスの値
      tableScroll : null,   // テーブルのスクロール量
    }
    if ( initialState ){
      Object.assign( this.state, initialState) // 初期値の設定
    }

    this.filterMouseDown = false;                // クリックされた場所が、フィルタリングメニュー外かどうか検知するために定義。
  }
  // _______________________________________________________________________________________________________________

  componentDidMount(){
    this.tableTop = this.scroll_ref.getBoundingClientRect().top               // tableの位置（Y座標）を取得して
    this.setState({ tableHeight : window.innerHeight - this.tableTop - 50 })      // テーブルの最大の高さを設定（画面内スクロールを出すため）
    
    this.setState({ setData:this.props.setData })  
  }
  // ________________________________________________________________________________________________________________

  componentDidUpdate(){
    if( !this.state.showFilter ){                              // フィルタリングメニューが閉じている時
      window.removeEventListener('click',this.windowClick,)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }
    if( !this.tableScroll ){
      // this.scroll_ref.scroll(0,this.state.tableScroll)       // スクロールの初期値の設定
      this.tableScroll = true;                                // DidUpdateが走ると毎回レンダー時に初期値に戻ってしまうので、処理を最初の一度のみにするために変数を作成しています。
    }

  }
  // ________________________________________________________________________________________________________________

  componentWillUnmount(){
    window.removeEventListener('click',this.windowClick,)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
  }
  // ________________________________________________________________________________________________________________

  // shouldComponentUpdate( nextProps , nextState ){
  //   const newTableTop = this.scroll_ref.getBoundingClientRect().top               // tableの位置（Y座標）を取得して
  //   if( this.tableTop !== newTableTop ){
  //     this.tableTop = newTableTop                                                 // tableの位置（Y座標）を取得して
  //     this.setState({ tableHeight : window.innerHeight - this.tableTop - 50 })    // テーブルの最大の高さを設定（画面内スクロールを出すため）
  //   }
  //   return true;
  // }
  // ________________________________________________________________________________________________________________

  // 画面をクリックした時
  
  windowClick=()=>{               
    if( this.filterMouseDown ){                  // もしフィルタリングメニュー上で、マウスダウンされていたら
      this.filterMouseDown = false;              //　filterMouseDown を初期値に戻して
      return;                                    // クリック処理を中断
    }
    this.setState({showFilter:false})            // フィルタリングメニューを閉じる
  }
  // ________________________________________________________________________________________________________________

  // フィルタリングメニュー上でマウスダウンした時

  filterOnMouseDown=()=>{ 
    this.filterMouseDown = true;                  // フィルタリングメニュー上でマウスダウンされたことを検知
  }
  // ________________________________________________________________________________________________________________

  // ヘッダーをクリックした時

  headerClick =(header, type, binding, e)=>{                // 引数・・・ヘッダー名、親から設定されているタイプ、バインドされているKey情報
    if(!binding){                                           // バインドされているデータがなければ（削除ボタン等）
      this.setState({ showFilter : false });                // フィルタリングメニューを閉じて
      return;                                               // 処理を中断
    }
    if( type === "check"){ return; }                        // タイプがチェックボックスなら処理を中断。（フィルターやソートができなくエラーが発生する）
    window.addEventListener('click',this.windowClick);      // windowイベントwindowClick関数を作成します。
    
    if ( binding === "changeRow"){
      this.setState({ showChangeRow : true })
    }else(
      this.setState({ showChangeRow : false })
    )

    if ( window.innerWidth < e.pageX + 200){                // 「ブラウザの横幅」 ＜　「クリックしたX座標」＋「フィルタリングメニューの横幅」　
      this.setState({ pageX : e.pageX - 200 })              // クリック時に右側に表示されるメニューを、左側に表示させる（画面の右の方でクリックしてもデザインが崩れないように…）
    }else{                                                  // それ以外なら
      this.setState({ pageX : e.pageX })                    // 普通に表示（スタイル時に使用）
    }
    let getPageY = e.pageY - window.pageYOffset;
    if ( window.innerHeight < getPageY + 150){                // 「ブラウザの横幅」 ＜　「クリックしたX座標」＋「フィルタリングメニューの横幅」　
      this.setState({ pageY : getPageY - 150 })              // クリック時に右側に表示されるメニューを、左側に表示させる（画面の右の方でクリックしてもデザインが崩れないように…）
    }else{                                                  // それ以外なら
      this.setState({ pageY : getPageY })                    // 普通に表示（スタイル時に使用）
    }        
    this.setState({ 
      // pageY       : e.pageY - window.pageYOffset,      // クリックされたY座標を検知
      showFilter  : true,         // フィルタリングメニューを表示
      filterName  : binding,      // フィルターをかけるためのデータのKey情報をセット
      filterLabel : header,       // フィルタリングメニューにヘッダー名を表示
      searchItems : "",           // フィルタリングメニューの検索ボックスを初期化
    })    
  }

  // ______________________________________________________________________________________________________
  
  // ヘッダーの上にマウスを乗せた時("in")、外した時("out")　（ホバーアクション）

  headerHover =(action, type, binding, e)=> {              // 引数・・・"in"か"out"、親から設定されているタイプ、バインドされているKey情報、
    if(!binding){ return; }                                // 親でbindingが設定されていなかったら処理を中断（フィルター機能使わないので）
    if( type === "check" ){ return; }                      // タイプがチェックボックスなら処理を中断。（フィルター機能使わないので）
    
    const style = e.target.style;                          // 省略宣言
    switch(action){
      case "in" : // onMouseOver時のスタイル
        style.backgroundImage = `url(${filter_icon})`;     // アイコンを表示    
        style.cursor          = "pointer";                 // カーソルを指のやつに
        style.backgroundColor = "#ddd";                    // 背景色を変更
      break;
      case "out": // onMouseLeave時のスタイル
        style.backgroundImage = "" ;                       // アイコンを非表示（IEではnullが上手く動作しないので...）
        style.backgroundColor = this.props.color1;         // 背景色を戻す
      break;
      default   : break;
    }

  }
  // ________________________________________________________________________________________________________________

  // 検索ボックスに値を入力した時 フィルタリング処理

  searchChange =(e)=>{
    const { filterName } = this.state    // state省略宣言
    const { setData }    = this.props    // props省略宣言

    let filterdData = setData.filter( setData  => {
      let value = ''+ setData[filterName]
      return( value.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
    })
    
    this.setState({ setData : filterdData })          // 絞り込み後のデータをセット
    this.setState({ searchItems : e.target.value })   // 自身の値を更新するための処理             
    this.setState({ startRows : 0 })                  // ページの開始行をリセット。
    this.setState({ paging : 1})                      // ページ数カウントを初期化。（データがないのにページがそのままにならないように）

  }
  // _______________________________________________________________________________________________________

  // onKeyDownイベント

  closeFilter=(e)=>{
    if( e.keyCode === 13){  // Enterを押した時
      this.setState({ 
        showFilter  : false,  // フィルターを閉じる
        searchItems : "",     //　検索結果をリセット
      }) 
    }
  }
  // _______________________________________________________________________________________________________

  // ソート用のボタンを押した時（データをソートする処理）
  
  sort =( orderBy )=>{                            // 引数・・・"up"か"down"
    const { setData, filterName } = this.state    // state省略宣言
    let sortedData = null;                        // ソートをした後のデータ

    switch ( orderBy ){
      case "down" : // 降順
        sortedData = setData.sort( function(a, b){    
          if ( a[filterName] > b[filterName] ) { return 1; } 
          else { return -1; }
        })
      break;

      case "up" : // 昇順
        sortedData = setData.sort( function(a, b){
          if ( a[filterName] < b[filterName] ) { return 1;} 
          else { return -1 }
        })
      break;

      default : break;
    }

    this.setState({ setData : sortedData })      // 並び替え後のデータをセット
    this.setState({ showFilter : false   })      // ボックスを閉じる
  }
  // _______________________________________________________________________________________________________

  // 「検索結果のリセット」ボタンを押した時

  resetFilter =()=> {
    this.setState({ 
      setData    : this.props.setData,
      showFilter : false,  // フィルタリングメニューを閉じる
    })
  }
  // _______________________________________________________________________________________________________

  // ページングの"次へ"ボタンを押した時

  nextPaging =(e)=>{
    const { paging, maxRows,startRows  } = this.state
    e.preventDefault()
    this.setState({ 
      paging      : paging + 1,           // ページ数カウントを＋１して
      startRows   : startRows + maxRows,  // 現在の開始行に、現在の表示件数を追加             
      filterName  : null,                 // filterNameを初期化
    })
  }
  // ________________________________________________________________________________________________________________

  // ページングの"前へ"ボタンを押した時

  prePaging =(e)=>{
    const { paging, maxRows,startRows  } = this.state
    e.preventDefault()
    this.setState({ 
      paging      : paging - 1,             // ページ数カウントを-１して
      startRows   : startRows - maxRows,    // 現在の開始行から現在の表示件数を引く         
      filterName  : null                    // filterNameを初期化（フィルタリングが勝手に走らないように）
    })
  }
  // ________________________________________________________________________________________________________________
  
  // ヘッダーのチェックボックスをクリックしたとき

  checkedAll =( cell, binding)=>{                        // 引数・・・バインドされているKey情報
    const { headerCheck,setData } = this.state          // state省略宣言
    
    const headerCheck_copy = headerCheck.slice()
    headerCheck_copy[cell] = !headerCheck[cell]
    this.setState({ headerCheck : headerCheck_copy })

    const setData_copy = setData.slice();
    for( let i = 0; i < setData.length; i++){
      setData_copy[i][binding] = !headerCheck[cell]
    }
    this.setState({ setData : setData_copy })
  }
  // ________________________________________________________________________________________________________________

  // 【全件を表示する】ボタン押した時

  allView =(e)=>{
    e.preventDefault()
    this.setState({ 
      maxRows    : null,  // 最大表示件数をnull
      startRows  : 0,     // 表示開始行を初期化
      paging     : 1,     // ページ数を初期化
      changeRow  : "",    // テキストボックスを初期化
      showFilter :false,  // フィルタリングメニューを閉じる
    })
  }
  // ________________________________________________________________________________________________________________

  // 【初期状態に戻す】ボタンを押した時

  resetView =(e)=>{
    e.preventDefault()
    this.setState({ 
      maxRows    : this.props.maxRows,   // 最大表示件数を初期化
      startRows  : 0,                    // 表示開始行を初期化
      paging     : 1,                    // ページ数を初期化
      changeRow  : "",                   // テキストボックスを初期化
      showFilter : false,                // フィルタリングメニューを閉じる
      setData    : this.props.setData,   // フィルタリングメニューを閉じる
    })
  }
  // ________________________________________________________________________________________________________________

  // 【表示件数を入力】テキストボックスに入力したとき

  changeRow =(e)=>{
    let replaceValue = e.target.value.replace(/[^\d]/g,"");  // 0~9以外の入力値を空値に変換
    replaceValue = Number( replaceValue )                    // 数値型に変換( "" は 0 になる）
    this.setState({ changeRow : replaceValue })              // テキストボックスに表示
    if( replaceValue !== 0 ){                                
      this.setState({ 
        maxRows   : replaceValue,  // 最大表示件数を変更 
        startRows : 0,             // 表示開始行を初期化
        paging    : 1,             // ページ数を初期化
      })                       
    }
  }
  // ________________________________________________________________________________________________________________

  //　行をクリックしたとき

  rowOnClick =( rowData, i )=>{
    if( window.getSelection().type == "Range" ){ return ;}

    this.setState({ 
      tableScroll : this.scroll_ref.scrollTop,
      rowHover    : i,
      showFilter  : false, // フィルターボックスを閉じる
    },()=> { // この方法（コールバック関数）で同期をとっています（同期を取らないとsetStateされた値が反映されないので）
      this.props.rowOnClick && this.props.rowOnClick( rowData, this.state ) 
    })
  }
  // ________________________________________________________________________________________________________________

  // セルをクリックしたとき

  cellOnClick =( onClick, type, binding, i, e ) =>{
    const { setData } = this.state       // state省略宣言
    if(onClick || type === "check" ) {
      e.stopPropagation() // 親にイベントが伝播するのを停止
    }

    this.setState({ 
      tableScroll : this.scroll_ref.scrollTop,
      rowHover    : i,
    },()=>{ // この方法（コールバック関数）で同期をとっています（同期を取らないとsetStateされた値が反映されないので）
      switch( type ){
        case "check":{  // チェックボックス
          const setData_copy = setData.slice();            // 配列をコピー
          setData_copy[i][binding] = !setData[i][binding]  // true or false を反転
          this.setState({ setData : setData_copy })        // 変更後のデータをセット
          break;
        }
        default:{
          onClick && onClick( this.state.setData[i], this.state )    // stateを親に渡す
          break;
        }
      }  
    })
  }
  // ________________________________________________________________________________________________________________
  
  //　外部使用 function （　ref = { (el) = this.listTable_ref = el } の形で宣言できるようになります　)
  //return all the datas
  getTableData = () => {
    return this.state.setData;
  }
  //return checked records only
  //行をCheckする時、CheckのBindは'ediChecked'に設定してください
  getCheckedTableData = () =>{
    const { setData } = this.state;
    let returnArr = new Array();
    for(let i in setData ){
      if(this.state.setData[i]['ediChecked'] === true){ returnArr.push(this.state.setData[i]);}
    }
    return returnArr;
  }
  // ______________________________________________________________________________________________________
  /////////////////////////////////////////////// ここからレンダー　///////////////////////////////////////////////////
  render(){
    const { 
      color1,
      color2,
      fontColor,
      innerScroll,
      children,       // (親から) <Table>{[ {...},{...} ]}</Table>　{...}の部分のこと
    } = this.props               
    
    const { 
      maxRows,        // 一度に表示する最大件数を設定する時に使用
      paging,
      searchItems,
      setData,         // 親から渡されたデータの集合体
      headerCheck,
      filterLabel,
      changeRow,
      showChangeRow,
      showFilter,
      startRows,
      rowHover,
      tableHeight, 
    } = this.state

    // スタイル定義

    const scroll_style = {            // <div> テーブルのwrap
      overflow:
        innerScroll ?"auto":
        null,
      position:"relative",
      maxHeight:
        innerScroll ? tableHeight : 
        null ,
      width:'100%',
    }
    const table_style = {             // <table> テーブル
      width : "100%",
      tableLayout: "fixed",
      borderCollapse: "separate",
      border: "solid 1px #c2c2c2",
      borderRadius: 5,
      backgroundColor: "#fff",
    }
    const theader_style={         // <table> テーブルヘッダー
      position: "sticky",
      zIndex: 1,
      width: "100%",
      top: 0,
      tableLayout: "fixed",
      borderCollapse: "separate",
      border: "solid 1px #c2c2c2",
      borderRadius: 5,
      backgroundColor: "#fff",
    }

    const th_style = {                // <td> テーブルヘッダー
      backgroundRepeat:"no-repeat",
      backgroundPosition:"center right",
      backgroundSize:25,
      padding:"8px 5px",
      backgroundColor: color1,
      // border:"1px solid #eee",
      fontWeight:"bold",
      fontSize:15,
      height:25,
      transition:"0.3s",
      color:fontColor,
      textAlign:"center",
      verticalAlign: "middle",
    }
    const td_style = {                // <td> 各セル
      width:"100%",
      borderBottom: "1px solid #bbb",
      textAlign:"center",
      verticalAlign:"middle",
      // border:"1px solid #eee",
      fontSize:14,
      padding:"2px 5px",
      height:25,
    }
    const link_style = {                // <button> リンク
      color:"#44f",
      textDecoration:"underline",
      cursor:"pointer",
      backgroundColor:"transparent",
      border:"none",
      width:"100%",
      height:"100%",
      textAlign:"inherit",
      fontSize:"inherit",
      fontFamily:"inherit",
    }

    const pagingWrap_style = {        // <div> ページングの外枠のスタイル
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      height:40,
    }

    const changeRowBtn_style = {           // <button> 全件表示ボタン
      height:25,
      fontSize:14,
      borderRadius:5,
      padding:"0px 8px",
      color:fontColor,
      backgroundColor:color1,
      boxShadow : "1px 1px 1px #ccc",
      cursor:"pointer",
      border:"none",
      marginRight:this.state.maxRows ? 16 : null,
    }

    const nopaging_style ={           // <button> ページングの押せないボタン
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      height:25,
      border:"none",
      backgroundColor:"transparent",
      width:50,
      fontSize:14,
      borderRadius:5,
      pointerEvents:"none",
    }
    const paging_style = {            // <button> ページングの押せるボタン
      height:25,
      border:"none",
      backgroundColor:color1,
      width:50,
      borderRadius:5,
      color:fontColor,
      fontSize:14,
      cursor:"pointer",
      boxShadow : "1px 1px 1px #ccc",
    }
    const pageCount_style = {         // <span> ページ数表示
      margin:"0px 8px",

    }
    const filter_style = {            // <div> フィルターの外枠
      width:200,
      position:"fixed",
      display:"inline-flex",
      flexDirection:"column",
      alignItems:"center",
      top :this.state.pageY,
      left:this.state.pageX,
      backgroundColor:"#fff",
      border:"1px solid #aaa",
      boxShadow:"0px 0px 2px 0px #aaa",
      padding:8,
      borderRadius:3,
      transition:"0.2s",
      zIndex: 5,
    }
    const search_style = {            // <input> 検索テキストボックス
      background:`url(${search_icon}) no-repeat`,
      backgroundPosition:"center left 5px",
      backgroundSize:"18px 18px",
      height:25,
      width:"100%",
      margin:"8px 0px",
      boxSizing:"border-box",
      paddingLeft:30,
      border:"1px solid #aaa",
      borderRadius:5,
    }

    const changeRow_style = {       // <input> 表示件数を変更するテキストボックス
      height:25,
      width:"100%",
      margin:"8px 0px",
      boxSizing:"border-box",
      paddingRight:5,
      border:"1px solid #aaa",
      textAlign:"right",
      borderRadius:5,
    }

    // _____________________________________________________________________________________________________

    // render関数内で使用される変数


    let   setValue    = null;                     // 親から渡されたデータを適切なフォームに割り振るための変数
    let   headerArr   = [];                       // 親から渡されたheaderを入れて、テーブルのヘッダーに表示するための配列
    let   tdArr       = [];                       // セルデータを格納するための配列
    let   trArr       = [];                       // 行データを格納するための配列（表示にも使用）
    let   prePage     = null;                     // "前へ"<button>の表示に使用
    let   nextPage    = null;                     // "次へ"<button>の表示に使用
    // _____________________________________________________________________________________________________

    if( !setData ){ return <div>データがありません</div>  }

    // _____________________________________________________________________________________________________
    
    // テーブルヘッダーの設定用ループ

    for(let i = 0; i < children.length; i++){
      
      const{      //（親から）設定オプションの省略宣言
        header, 
        type,
        binding,
        width,
        cols,
        allCheck,
        align,
        minWidth,
      } = children[i]

      const column_style = {
        width:　width,
        minWidth : minWidth,
        textAlign : align,
      }

      if( !header){ continue; }   // headerが指定されていなかったら処理をスキップ（セル結合をしている）
      headerArr.push(                                                     // <th>テーブルヘッダーを作成
        <td
          colSpan      = { cols }                                        // （親から）colsでセル結合
          style        = { Object.assign({},th_style, column_style) }   // （親から）widthでセル幅指定
          onClick      = { (e)=> this.headerClick(header, type, binding, e) }
          onMouseOver  = { (e)=> this.headerHover("in",  type, binding, e) }
          onMouseLeave = { (e)=> this.headerHover("out", type, binding, e) }
          onMouseDown  = { this.filterOnMouseDown }
          key = {'headerArr'+i}
        >
          {!allCheck ? 
            header: 
            <CheckBox  onClick={()=>{ this.checkedAll(i,binding) }} checked={ headerCheck[i] } label={ header }/> // {/* allCheckが設定されていたらチェックボックスを表示*/}
          }  
        </td>
      )
      
    }
    // _________________________________________________________________________________________________________
    
    // ページング機能

    if( maxRows ){
      if( paging > 1){
        prePage = <button style={paging_style} onClick = { this.prePaging }>前へ</button>
      }else{
        prePage = <label style={nopaging_style} >前へ</label>
      }
      if( setData.length - maxRows * paging >= 1){
        nextPage = <button style={paging_style} onClick = { this.nextPaging }>次へ</button>
      }else{
        nextPage = <label style={nopaging_style} >次へ</label>
      }
    }
    // ___________________________________________________________________________________________________________


    // 親から受け取った値に対してデータを行に当てはめていく処理。

    for(let i = startRows ; i < setData.length; i++){
      tdArr = [null];
      for(let j in children ){
        const {     //（親から）設定オプションの省略宣言
          binding,
          type,
          width,
          onClick,
          label,
          align,
          minWidth,
          value,
        } = children[j]

        const column_style = {
          width:　width,
          minWidth : minWidth,
          textAlign : align,
        }

        let cellItem
        setValue = null;                                    // 初期値の設定
        if(setData[i]){ 
          if (typeof(value) === 'function') {
            setValue = value(setData[i]);
          }else if (value) { 
            setValue = value;
          }
          else { 
            setValue = setData[i][binding]
          } 
        }   // （親から）データがあれば、バインドされたkey情報に基づいてデータを設定
      
        // 設定情報typeによってセル内に配置する要素を設定しています
        switch(type){
          case("header"):                                              // ヘッダー
            tdArr.push(
              <td style = {Object.assign({},th_style, {width:width, textAlign:"center"})}  
                key = {'tdArr'+j} 
              >
                { i + 1 }
              </td> 
            );
          continue;

          case("check"):                                               // チェックボックス
            cellItem = <CheckBox checked  = { setValue } color={color1}/>
          break
                                                   
          case("text"):                                                //　テキストボックス
            cellItem = setValue
          break;

          case("date"): //　テキストボックス(日付)
            if( new Date( setValue ) != "Invalid Date" ){ // もしデータが日付として正しいなら
              setValue = setValue.replace( /-/g,"/")  // "-"(ハイフン)を"/"(スラッシュ)に置換
            }
            setData[i][binding] = setValue;
            cellItem = setValue;
          break;

          case("number"):                                                //　テキストボックス(数値)
            // if( new Date( setValue ) != "Invalid Date" ){ // もしデータが日付けなら
            //   setValue = setValue.replace( /-/g,".")  // "-"(ハイフン)を"/"(スラッシュ)に置換
            //   console.log( new Date( setValue ) )
            // }  
            cellItem = setValue
          break;

          case("link"):                                              // リンク        
            cellItem = <button style={ link_style }>{setValue}</button>  
          break;

          case("copy"):                                              // 複写                                               
            cellItem = <TableIcon src={copy_icon}  label={label}/>
          break;

          case("edit"):                                              // 編集
            cellItem = <TableIcon src={edit_icon}  label={children[j].label}/>
          break;

          case("delete"):                                              // 行削除ボタン
            cellItem = <TableIcon src={delete_icon} w={15} h={15} label={children[j].label}/>
          break;

          default :                                                    // デフォルト
            cellItem = { type }                     
          break;
        }
        // ______________________________________________________________________

        tdArr.push( 
          <td style = {Object.assign({},td_style, column_style)} 
            onClick = { (e)=> this.cellOnClick( onClick,type,binding,i,e ) }
            key = { i+""+j } 
          >
            { cellItem }
          </td>     // type指定したもの（コンポーネントなど）
        )                         
        
      }//　j ループここまで

      // 行を作成する処理
      trArr.push(                                
        <TR key    = {"tr"+i}
          anyProps = {{color:color2, index: i, initHover:rowHover }}    
          onClick  = {()=> this.rowOnClick( setData[i], i )}
        >
          {tdArr}
        </TR>
      )
      // ______________________________________________________________________ 

      if( maxRows && i + 1 >= maxRows * paging){ break; }        // （親から）maxRowsで指定した行でループを抜ける

    }//　i ループここまで
    // ___________________________________________________________________________________________________________________
    
    return(
      <div>
        {/*  ページング　 */}
        <div style={pagingWrap_style}>
          <span>
            データ件数：{setData.length}件
          </span>
          <div style={pagingWrap_style}>
          <button 
            style       = { changeRowBtn_style } 
            onClick     = { (e)=> this.headerClick( null, null, "changeRow", e) }
            onMouseDown = { this.filterOnMouseDown }
          >
            表示件数を変更する
          </button>
          {maxRows && setData.length > 0 &&
            <Fragment>
              {prePage}
              <span style={pageCount_style}>
                ページ数：{paging}/{ Math.ceil(setData.length / maxRows)}
              </span>
              {nextPage}
            </Fragment>
          }
          </div>
        </div>

        {/* テーブル */}
        <div style= {scroll_style } ref = { (e) => this.scroll_ref = e}>
          <table style={theader_style}>
            <thead>
              <tr>{headerArr}</tr>{/* ヘッダー */}
            </thead>
          </table>
          <table style={ table_style }>
            <tbody>
              {trArr}
            </tbody>
          </table>
        </div>

        {/*  ページング　 */}
        <div style={pagingWrap_style}>
          <span> データ件数：{setData.length}件 </span>
            <div style={pagingWrap_style}>
            {maxRows && setData.length > 0 &&
              <Fragment>
                {prePage}
                <span style={pageCount_style}>
                  ページ数：{paging}/{ Math.ceil(setData.length / maxRows)}
                </span>
                {nextPage}
              </Fragment>
            }
          </div>
        </div>

        { showFilter && 
          <div 
            style       = { filter_style }
            onMouseDown = { this.filterOnMouseDown }
          >
            { showChangeRow ?
            <Fragment>
              <div >表示する件数を入力</div>
              <input type="tel" 
                style={ changeRow_style } 
                value= { changeRow } 
                onChange= { this.changeRow } 
                onKeyDown= { this.closeFilter } 
                autoFocus 
              />
              <MenuButton onClick = { this.allView   } label="全件数を表示する" anyProps={{color:color1,fontColor:fontColor}}/>
              <MenuButton onClick = { this.resetView } label="初期状態に戻す" 　anyProps={{color:color1,fontColor:fontColor}}/>
            </Fragment>
            :
            <Fragment>
            <div >{filterLabel}</div>
            <input 
              style        = { search_style } 
              value        = { searchItems } 
              onChange     = { this.searchChange } 
              onKeyDown    = { this.closeFilter  }
              placeholder  = "Search..."
              autoFocus
            />
            <MenuButton onClick = { this.resetFilter  } label="検索結果のリセット"  anyProps={{color:color1,fontColor:fontColor}}/>
            <MenuButton onClick = { () => this.sort("down") } label="A→Zで並び替え" anyProps={{color:color1,fontColor:fontColor}}/>
            <MenuButton onClick = { () => this.sort("up")   } label="Z→Aで並び替え" anyProps={{color:color1,fontColor:fontColor}}/>
            </Fragment>
          }
          </div>
        }
      </div>
    );

  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class TableIcon extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    }
  }
  render(){
    const { w, h } = this.props

    ///////  スタイル記述欄　ここから
    const icon_wrap = {                             // <div> アイコンのwrap 
      position:"relative",
      display:"inline-flex",
      justifyContent:"center",
      flexDirection:"column",
      alignItems:"center",
      cursor:"pointer",
    }
    
    const balloon_wrap = {                             // <div> 吹き出しのWrap
      position:"absolute",
      top:"100%",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      whiteSpace:"nowrap",
      zIndex:2,
      height:0,
      width:0,
    }
    const balloon_parts = {                            // <div> 吹き出し上部の三角形
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #000",
      borderLeft: "10px solid transparent",
    }
    const balloon_title = {                             // <div> 吹き出しの言葉
        padding:8,
        backgroundColor:"black",
        borderRadius:5,
        fontSize:12,
        color:"#fff",
    }
    //　＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿

    return(   
      <div style={icon_wrap}>
        <img
          src     = { this.props.src }
          width   = { w? w : 20 } // デフォルトで20pxを設定
          height  = { h? h : 20 } 
          onMouseOver  = { ()=> this.setState({ hover:true  }) }
          onMouseLeave = { ()=> this.setState({ hover:false }) }
          alt = { this.props.label }
        />

        {/* ※１　親で label の引数が設定されていて、かつマウスがアイコン上にある時にレンダーします */}
        {this.props.label && this.state.hover && 
        <div style={balloon_wrap}>
          <div style={balloon_parts}></div>
          <div style={balloon_title}>
            {this.props.label}
          </div>
        </div>
        }   
        {/* _______________________________________________________________________________________*/}
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class CheckBox extends React.Component{
  
  render() {
    const boxColor = this.props.color? this.props.color:"#777";
    const wrap = {
      display : "flex",
      alignItems:"center",
      height:"100%",
      justifyContent:"center"
    }

    const boxWrap = {
      display:"inline-flex",
      padding:0,
      border:"none",
      backgroundColor:"transparent",
      alignItems:"center",
      boxSizing:"border-box",
      cursor:this.props.disabled ? null : "pointer",
      fontSize:16,
    }

    const box = {
      height:20,
      width:20,
      backgroundColor: this.props.checked ? boxColor : "white",
      boxSizing:"border-box",
      borderRadius:5,
      borderStyle:"solid",
      borderWidth:"2px",
      borderColor:boxColor,
      position:"relative",
    }

    const checkedStyle = {
      position:"absolute",
      bottom:2,
      left:3,
      boxSizing:"border-box",
      height:15,
      width:10,
      borderRight: "4px solid white",
      borderBottom: "4px solid white",
      transform: "rotate(45deg)",
      borderRadius:5,
    }
    // ________________________________________________________________________________


    
    return (
      <div style={ wrap }>
        <label>{ this.props.label }</label>
        <button style={ boxWrap } onClick={this.props.onClick} disabled={ this.props.disabled }>
          <div style = { box }>
            { this.props.checked && <div style = { checkedStyle }></div> }
          </div>
        </button>
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class MenuButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { color, fontColor } = this.props.anyProps 
    const { hover } = this.state 
    const button_style = {              // <button> ソートボタン
      height:25,
      width:"100%",
      backgroundColor: hover ? color : "#fff",
      color :  hover ? fontColor : null ,
      border:"none",
      borderRadius:3,
      textAlign:"left",
      marginBottom:8,
      cursor:"pointer",
      transition:"0.2s",
    }

    return(
      <button 
        style        = { button_style } 
        onClick      = {  this.props.onClick }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { this.props.label }
      </button>
    )
  }  
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class TR extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
  
  componentDidMount(){
    const { index, initHover } = this.props.anyProps  // 省略宣言
    if( index === initHover ){
      this.setState({ hover : true  })
    }
  }
  // ________________________________________________________________________________________________________________

  render(){
    const { hover }        = this.state           // 省略宣言
    const { color, index } = this.props.anyProps  // 省略宣言

    const tr_style = {              
      backgroundColor: 
        hover           ? "#ffd" : // ホバー中の行
        index % 2 === 1 ? color  : // 偶数行なら指定してある色  
        "#fff",                    //　デフォルトは白
      cursor:"pointer",
      transition:"0.1s",
    }

    return(
      <tr
        style        = { tr_style } 
        onClick      = { this.props.onClick }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { this.props.children }
      </tr>
    )
  }  
}