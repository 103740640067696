import React, { Fragment } from 'react';

export default class PageBtn extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hoverStyle:null,
    }
  }

  buttonOnMouse =()=>{
    
    this.setState({ 
      hoverStyle : { 
        backgroundColor: "#fff", 
        border: "2px solid #000", 
        color:"#000" 
      }
    })
  
  }
  // ______________________________________________________________________________

  
  render(){
    const { hidden, checkPage, mode } = this.props;
    if( checkPage || mode !== "2") { return null}
    if( hidden ) { return null}
    const btn_style = {
      marginBottom:16,
      height: 35,
      padding:"0px 25px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 20,
      boxShadow:" 2px 2px 2px #aaa",
      cursor: "pointer",
      borderStyle:"solid",
      borderWidth:"2px",
      border: "2px solid #d22",
      color: "#fff",
      backgroundColor: "#d22",
      transition:"0.2s",
      boxSizing:"border-box",
      marginLeft:16,
    }
    // ______________________________________________________________________________
    
    return(
      <button 
        style        = { Object.assign({}, btn_style, this.state.hoverStyle ) }
        onClick      = { this.props.onClick } 
        onMouseOver  = { this.buttonOnMouse }
        onMouseLeave = { () => this.setState({ hoverStyle : null }) }
      >
        このデータを削除する
      </button>
    )
  }
}
