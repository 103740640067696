import React, { Fragment } from 'react';
import BasicComponent from './BasicComponent';


export default class TextLabel extends BasicComponent {
  render() {
    const { size, name, label, type, value } = this.props
      
    let boxWidth = 156;
    if( size ){
      switch( size ){
        case "ss" : case "SS" : { boxWidth = 70;  break; }
        case "s"  : case "S"  : { boxWidth = 113; break; }
        case "m"  : case "M"  : { boxWidth = 156; break; }
        case "l"  : case "L"  : { boxWidth = 328; break; }
        case "ll" : case "LL" : { boxWidth = 672; break; }    
        default   : boxWidth = size; 
      } 
    }
    // ______________________________________________________________________
    
    const wrapStyle = {
      display: "inline-flex",
      flexDirection: "column",
    }

    const textLabelStyle = {
      fontSize: 16,
      borderRadius: 5,
      height: 30,
      paddingLeft: 5,
      paddingRight: 5,
      minWidth: boxWidth,
      border : "1px solid #aaa",
      boxSizing: "border-box",
      marginRight: 16,
      marginBottom: 16,
      justifyContent:
        type === "number" ? 'right' :
        null,
      display: "flex",
      alignItems: "center",
    }
    // ______________________________________________________________________

    return (
      <Fragment>
        <div style={wrapStyle}>
          <Labeling label={label}/>
          <label style={textLabelStyle} name={name}>{value}</label>
        </div>
      </Fragment>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Labeling extends React.Component{
  render(){
    const { label }= this.props

    if(!label) { return null; }

    const label_style = {   // <label> テキストのラベル
      fontSize:14,
      marginRight:"1px",
      paddingLeft:"1px",
    }
    return(
      <div style={{ paddingBottom:"4px"}}>
        <label style={label_style}>{label}</label>
      </div>
    )
  }  
}


