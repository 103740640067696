import React, { Fragment } from 'react';

export default class Form extends React.Component{

  avoidEnter = (e) =>{
    // Enterを押したときに他要素のEnterアクションが起こる挙動を止めています
    e.nativeEvent.keyCode === 13 && e.target.type !== 'textarea' && e.preventDefault();
  }
    
  render(){
    
    return(
      <form id={this.props.id}
        className={this.props.className}
        onClick = {e => e.preventDefault()}
        onKeyPress={e =>this.avoidEnter(e)}
      >
        {this.props.children}
      </form>
    )
  }
}