import React, { Fragment, Children } from 'react';

export default class InputFile extends React.Component{
  constructor(props){
    super(props);
    const { label, fileName } = this.props;
    this.state={
      hover     : false,
      label     : label || "ファイルを取り込む",
      fileName  : fileName || null,
      irregularKey: Math.random().toString(36).substring(7), // 乱数生成
    }
  }
  // ______________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const {label, fileName} = nextProps;
    if(this.props.label !== label) {
      this.setState({ label : label})
    }
    if(this.props.fileName !== fileName) {
      this.setState({ label : "取り込み中のファイル名："})
      this.setState({ fileName : fileName})
    }
    return true;
  }
  // ______________________________________________________________________________

  hover =()=>{
    const { fileName } = this.state

    if( !fileName ){
      this.setState({ 
        hoverStyle : { 
          backgroundColor: "rgb(43, 64, 108)", 
          color:"rgb(255, 255, 255)" 
        }
      })
    }
  }
  // ______________________________________________________________________________

  importFile =(e)=>{
    if( !e.target.files[0] ){ return }
    this.setState({ 
      label    : "取り込み中のファイル名：",
      fileName : e.target.files[0].name,
      irregularKey: Math.random().toString(36).substring(7), // 乱数生成
    })
    this.props.onChange && this.props.onChange( e.target.files[0] )
  }
  // ______________________________________________________________________________

  resetFile = (handler = true) =>{
    this.setState({ 
      label    : this.props.label || "ファイルを取り込む",
      fileName : '',
    })
    if(handler) {
      this.props.onChange && this.props.onChange({})
    }
  }
  // ______________________________________________________________________________


  render(){
    const { none, disabled, cancelable } = this.props
    const { hover, fileName, label, irregularKey } = this.state
    if( none ){ return null }
    const wrap_style = {
      marginBottom:16,
      marginRight:16,
    }
    const fileStyle = {
      display:"inline-flex",
      alignItems:"center",
      padding:"0px 24px",
      height: 40,
      backgroundColor: 
        fileName ? "rgb(43, 64, 108)" :
        disabled ? null :
        hover    ? "rgb(43, 64, 108)" :
        "#fff",
      color:
        fileName ? "rgb(255, 255, 255)" :
        disabled ? 'rgb(43, 64, 108)' :
        hover    ? "rgb(255, 255, 255)" :
        'rgb(43, 64, 108)',
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 5,
      cursor: 
        disabled ? null :
        "pointer",
      border: "1px solid rgb(43, 64, 108)",
      transition:"0.3s",
    }

    // ______________________________________________________________________________
    
    return(
      <div style={wrap_style}>
        <label tabIndex="0" 
          style        = {fileStyle} 
          onMouseOver  = {()=>this.setState({ hover : true })}
          onMouseLeave = {()=>this.setState({ hover : false })}
          onFocus      = {()=>this.setState({ hover : true })}
          onBlur       = {()=>this.setState({ hover : false })}
        >
          { label }
          { fileName }
          <input key={'inputFile' + irregularKey}type="file" style={{display:"none"}} onChange={this.importFile} disabled={disabled}/>
        </label>
        <ResetBtn render={cancelable && fileName && !disabled} onClick={()=>this.resetFile()}/>
      </div>
    )
  }
}

class ResetBtn extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      hover:false,
      focus:false,
    }
  }
  render(){
    const { hover, focus} = this.state;
    const { render, onClick } = this.props;
    if(!render){ return null}
    const btn_style = {
      height: 40,
      backgroundColor: 
        hover || focus ? "rgb(43, 64, 108)" :
        "#fff",
      color:
      hover || focus ? "rgb(255, 255, 255)" :
        'rgb(43, 64, 108)',
      fontWeight: "bold",
      border: "1px solid rgb(43, 64, 108)",
      transition:"0.3s",
      borderRadius: 5,
      marginLeft:16,
      cursor: "pointer",
    }
    return(
      <button
        style        = {btn_style}
        onMouseOver  = {()=>this.setState({ hover : true })}
        onMouseLeave = {()=>this.setState({ hover : false })}
        onFocus      = {()=>this.setState({ focus : true })}
        onBlur       = {()=>this.setState({ focus : false })}
        onClick      = {onClick}
      >
        ×
      </button>
    )
  }
}