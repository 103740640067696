/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';
import RichTextDisplay from './RichTextDisplay';
import { changeToYMD } from '../../util/commonInput';
import {axiosPost,server} from '../../comRedux/axiosPost';
import file from '../WQCImage/file.png';
import Link from '../../component/Link'

export default class InfoComponent extends React.Component {
  constructor(props){
    super(props);
    this.textListArr = [];
    this.textList_style = {
      padding:"16px 0px",
      borderBottom:"1px solid #E0E0E0",
    };
    this.validfromWrap_style = {
      marginBottom : 16,
    };
    this.validfrom_style = {
      marginRight : 8,
    };
    this.new_style = {
      color : "#f00",
    };
    this.file_style = {
      display : 'flex',
      alignItems : 'center',
      marginTop : 16,
    };
    this.icon_style = {
      width : 20,
      height : 20,
      marginRight : 4,
    }
    this.fileName_style = {
      color : "rgb(43, 64, 108)",
      fontWeihgt : 'bold',
    }
  }
  //_______________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if(this.props.setData !== nextProps.setData){
      this.textListArr = []; // 値をリスト化して表示するための配列
      for(let i in nextProps.setData){
        let {
          newdisp,
          validfrom,
          info,
          fileName,
          infodate,
          infoseq,
        } = nextProps.setData[i];
        const year = new Date(validfrom).getFullYear();
        const month = new Date(validfrom).getMonth();
        const date = new Date(validfrom).getDate();
        let newDispDate = new Date(year, month, date + newdisp);
        newDispDate = changeToYMD(newDispDate);
        const today = changeToYMD(new Date());
        validfrom = changeToYMD(validfrom);
        this.textListArr.push(
          <div key={"textList" + i} style={this.textList_style}>
            <div key={"validfromWrap" + i} style={this.validfromWrap_style}>
              <span key={"validfrom" + i} style={this.validfrom_style}>
                配信日：{validfrom}
              </span>
              {today < newDispDate && <span key={"new" + i} style={this.new_style}>New</span> }
            </div>
            <RichTextDisplay key={"RichText" + i} value={info} />
            {fileName &&
            <Link key={'file' + i} style={this.file_style} onClick={()=>{this.click_showFile(infodate,infoseq,fileName)}}>
              <img src={file} style={this.icon_style} alt="" key={"icon" + i}/>
              <span key={"flieName" + i} style={this.fileName_style}>{fileName}</span>
            </Link>
            }
          </div>
        )
      }
    return true;
    }
    return false;
  }
  //_______________________________________________________________________________________________

  click_showFile = (infodate, infoseq, fileName) => {
    // console.log(infodate,infoseq,fileName,)
    var json = {infodate:infodate, infoseq:infoseq};
    axiosPost.post(server+'/MA014/pdf',json,{responseType: 'arraybuffer'})
    .then((res) => {
        // ダウンロードしたいコンテンツ、MIMEType、ファイル名
        var mimeType = 'application/octet-stream';
  
        // BOMは文字化け対策
        var bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
        var blob = new Blob([res.data], {type : mimeType});
  
        var a = document.createElement('a');
        a.download = fileName;
        a.target   = '_blank';
  
        if (window.navigator.msSaveBlob) {
          // for IE
          window.navigator.msSaveBlob(blob, fileName)
        }
        else if (window.URL && window.URL.createObjectURL) {
          // for Firefox
          a.href = window.URL.createObjectURL(blob);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        else if (window.webkitURL && window.webkitURL.createObject) {
          // for Chrome
          a.href = window.webkitURL.createObjectURL(blob);
          a.click();
        }
        else {
          // for Safari
          window.open('data:' + mimeType + ';base64,' + window.Base64.encode(res.data), '_blank');
        }
    }) 
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
        return
    })
   };
     // _______________________________________________________________________________________



  render(){
    return (<Fragment>{this.textListArr}</Fragment>);
  }
}


