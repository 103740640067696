import React from 'react';

export default class InputAreaHeading extends React.Component{
  render(){
    const { label } = this.props

    const heading_style = {
      fontSize : 18,
      fontWeight : "bold",
      marginBottom : 16,
    }

    return(
      <p style={heading_style}>{label}</p>
    )
  }
}