/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';

export default class SupportTitle extends React.Component {
  constructor(props){
    super(props);
    this.sdeskType1Name = "";
    this.sdeskType2Name = "";
    this.wrap_style = {
      display : "flex",
      justifyContent : "space-between",
      borderBottom : "5px solid rgb(224, 224, 224)",
      paddingBottom : 4,
      marginBottom : 16,
    }
    this.sdeskType_style = {
      marginLeft : 16
    }
  }
  //_______________________________________________________________________________________________

  render(){

    const {
      title,
      sdeskType1,
      sdeskType2,
      sdeskTypeData1,
      sdeskTypeData2,
    } = this.props;
    //_______________________________________________________________________________________________

    if(sdeskType1) {
      let filteredData1 = sdeskTypeData1.filter( sdeskTypeData1 => { // setDataにフィルターをかけて
        if (sdeskTypeData1.value === sdeskType1 ) return true;  // valueがあるデータを参照 filteredData = [{ value :"", label : ""}]
      })
      this.sdeskType1Name = filteredData1[0].label
    }
    if(sdeskType2) {
      let filteredData2 = sdeskTypeData2.filter( sdeskTypeData2 => { // setDataにフィルターをかけて
        if (sdeskTypeData2.value === sdeskType2 ) return true;  // valueがあるデータを参照 filteredData = [{ value :"", label : ""}]
      })
      this.sdeskType2Name = filteredData2[0].label
    }
    //_______________________________________________________________________________________________

    return (
      <div style={this.wrap_style}>
        <div>件名：{title}</div>
        <div>
          <span style={this.sdeskType_style}>種別1：{this.sdeskType1Name}</span>
          <span style={this.sdeskType_style}>種別2：{this.sdeskType2Name}</span>
        </div>
      </div>
    );
  }
}
