import React, { Fragment } from 'react';

export default class PageBtn extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hoverStyle:null,
    }
  }

  hover =()=>{
    
    this.setState({ 
      hoverStyle : { 
        backgroundColor: "#fff", 
        border: "2px solid #000", 
        color:"#000" 
      }
    })
  
  }
  // ______________________________________________________________________________

  
  render(){
    const { mode, checkPage, label } = this.props

    let btn_label     = label || "検索画面へ戻る"
    let btn_color     = "#2296F3"
    let btn_fcolor    = "#fff"

    if( checkPage ){
      switch( mode ){
        case "1" :                    // 新規登録画面
          btn_label  = "入力画面へ戻る"
          btn_color  = "#FFCC80"
          btn_fcolor = "#000"
        break;

        case "2" : case "4" :                   // 編集画面
          btn_label = "編集画面へ戻る"
          btn_color = "#FFCC80";
          btn_fcolor = "#000"
        break;

      default:
      }
      
    }
    // ______________________________________________________________________________


    const btn_style = {
      marginBottom:16,
      height: 35,
      padding:"0px 25px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 20,
      boxShadow:" 2px 2px 2px #aaa",
      cursor: "pointer",
      border:"2px solid " + btn_color,
      color: btn_fcolor,
      backgroundColor: btn_color,
      transition:"0.3s",
    }
    // ______________________________________________________________________________
    
    return(
      <button 
        style        = { Object.assign({}, btn_style, this.state.hoverStyle ) }
        onClick      = { this.props.onClick } 
        onMouseOver  = { this.hover }
        onMouseLeave = { () => this.setState({ hoverStyle : null }) }
        onFocus      = { this.hover }
        onBlur       = { () => this.setState({ hoverStyle : null }) }
      >
        {btn_label}
      </button>
    )
  }
}
