import React, { Fragment } from 'react';

export default class JurisdictionArea extends React.Component{
  constructor(props){
    super(props);
    const { value } = this.props;
    this.state={
      value : value || [],     // 選択されているアイテム
      hover : null,   // オンマウス中のアイテム
    }
    this.prefectures = [
      "北海道","青森県","岩手県","宮城県","秋田県",
      "山形県","福島県","茨城県","栃木県","群馬県",
      "埼玉県","千葉県","東京都","神奈川県","新潟県",
      "富山県","石川県","福井県","山梨県","長野県",
      "岐阜県","静岡県","愛知県","三重県","滋賀県",
      "京都府","大阪府","兵庫県","奈良県","和歌山県",
      "鳥取県","島根県","岡山県","広島県","山口県",
      "徳島県","香川県","愛媛県","高知県","福岡県",
      "佐賀県","長崎県","熊本県","大分県","宮崎県",
      "鹿児島県","沖縄県"
    ]
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if(this.props.value !== nextProps.value){
      this.setState({ value : nextProps.value });
    }
    return true;
  }
  // ________________________________________________________________________________
  
  // アイテムをクリックしたとき
  handleClick = (prefecture) => {
    let value_copy = this.state.value.slice();  // 配列のコピーを生成　setState用
    let result = value_copy.indexOf(prefecture);      // 配列の中身を検索
    if( result　== -1 ){       // 選択中いないアイテムなら
      value_copy.push(prefecture);
    }else{
      value_copy.splice(result, 1);
    } 
    this.setState({ value : value_copy });
    this.props.onChange && this.props.onChange(this.props.name, value_copy );
  }
  // ________________________________________________________________________________

  // 「全て選択」「全て選択解除」ボタンを押した時
  allSelectClick =( items )=> {
    this.setState({ 
      value: items,
    },()=>{
      this.props.onChange && this.props.onChange( this.props.name , this.state.value )
    })
  }
  // ________________________________________________________________________________________________________________

  render() {
    const { value　} = this.state
    const { disabled,label } = this.props
    // ________________________________________________________________________________
    // console.log( this.props.value )

    // スタイル定義
    const wrap  = {
      display:"inline-flex",
      flexDirection:"column",
      boxSizing:"border-box",
      marginBottom:16,
    }

    const label_style = {   // <label> テキストのラベル
      fontSize:14,
      marginRight:"1px",
      paddingLeft:"1px",
      display:"inline-block",
      paddingBottom:4,
    }

    const list_wrap = {
      display:"inline-flex",
      flexDirection:"column",
      width:"100%",
      flexWrap:"wrap",
      boxSizing:"border-box",
      backgroundColor:
        disabled ? null :
        "#fff",
      border:"1px solid #aaa",
      borderRadius:5,
      padding:5,
      marginBottom:4,
    }
    const areaStyle = {
      display:"inline-flex",
    }

    // ________________________________________________________________________________

    // 使用する変数を定義
    let listArr           = [];
    const outputArr       = [];
    // ________________________________________________________________________________

    for( let i = 0; i < this.prefectures.length; i++ ){
      let result = value.indexOf( this.prefectures[i] )      // 配列の中身を検索
      listArr.push(
        <JurisdictionBtn
          key          = { this.prefectures[i] } 
          onClick      = { ()=> this.handleClick( this.prefectures[i] ) }
          selected     = { result !== -1 }
          disabled     = { disabled }
          label        = { this.prefectures[i] }
        />
      )
      if( i === 6 || i === 13 || i === 22 || i === 29 || i === 34 || i === 38 || i === 46){
        
        outputArr.push( 
          <div 
            style = { areaStyle }
            key = { "area"+i }
          >
            {listArr}
          </div>  
        )

        listArr = [];
      }

    }
    // ________________________________________________________________________________
    return (
      <Fragment>
        <div style={wrap}>
          <label style={label_style}>
            { label }
          </label>
          <div style={ list_wrap }>
            {outputArr}
          </div>
          <div>
            <JurisdictionBtn label="すべて選択"     onClick = { ()=> this.allSelectClick( this.prefectures ) } disabled={ disabled } />
            <JurisdictionBtn label="すべて選択解除" onClick = { ()=> this.allSelectClick( [] ) } disabled={ disabled } />
          </div>
        </div>
      </Fragment>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class JurisdictionBtn extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state 
    const { 
      selected,
      onClick,
      label,
      disabled,
    } = this.props

    const button_style = {              // <button> リストアイテム
      borderColor :
        hover ? "#2b406c":
        "transparent",
      cursor:
        disabled ? null :
        "pointer",
      padding:"4px 8px",
      margin:2,
      fontSize:14,
      borderRadius:5,
      textAlign:"left",
      boxSizing:"border-box",
      transition:"0.2s",
      backgroundColor:
        selected ? "#2b406c":
        "transparent",
      color :
        selected ? '#fff' : 
        null,
    }

    return(
      <button 
        style        = { button_style } 
        onClick      = { onClick }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
        disabled     = { disabled }
      >
        { label }
      </button>
    )
  }  
}
