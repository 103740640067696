import React from 'react';

export default class Title extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover: false,
    }
  }
  // ______________________________________________________________________________

  render(){
    const { none, disabled  } = this.props
    const { hover } = this.state

    if( none ){ return null }
    const btnStyle = {
      display:"inline-flex",
      alignItems:"center",
      padding:"0px 24px",
      height: 40,
      backgroundColor:
        disabled ? "transparent" :
        hover    ? "rgb(43, 64, 108)" :
        "#fff",
      color:
        hover ? "#fff" :
        "rgb(43, 64, 108)" ,
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 5,
      cursor: 
        disabled ? null :
        "pointer",
      border: "1px solid rgb(43, 64, 108)",
      marginBottom:16,
      marginRight:16,
      transition:"0.3s",
      fontFamily : "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    }
    // ______________________________________________________________________________
    
    return(
      <button 
        style        = { btnStyle }
        onClick      = { this.props.onClick } 
        onMouseOver  = { () => this.setState({ hover : true  }) }
        onMouseLeave = { () => this.setState({ hover : false }) }
        onFocus      = { () => this.setState({ hover : true  }) }
        onBlur       = { () => this.setState({ hover : false }) }
        disabled     = { disabled }
      >
        { this.props.label }
        { this.props.children }
      </button>
    )
  }
}
