/* eslint-disable jsx-a11y/alt-text */
import React, {Fragment} from 'react'

export default class Card extends React.Component{
  render(){  
    ///////////// スタイル記述欄 ここから//////////////////
    const wrap = {
      padding:8,
      width:"100%",
    }

    const outline = {
      display:"flex",
      flexDirection:"column",
      width:this.props.w,
      borderRadius:10,
      // boxShadow: "1px 2px 2px 1px #ccc", 
    }

    const titlebar = {
      display:"flex",
      alignItems:"center",
      width:"100%",
      padding:"0px 8px",
      // boxShadow:"0px 1px 0px 0px #dfdfdf",
      backgroundColor:"rgba(255,	255,	255, 0.97)",
      borderTopLeftRadius:5,
      borderTopRightRadius:5,
    }

    const icon = {
      width:30,
      height:30,
    }

    const title = {
      width:"100%",
      marginTop:16,
      marginRight:8,
      marginLeft:8,
      paddingBottom:8,
      color:"#26B088",
      fontSize:20,
      fontWeight:"bold",
      borderBottom: this.props.border? "5px solid #E0E0E0": null,
    }

    const sentence_area = {
      padding:"0px 16px",
      backgroundColor:"rgba(255,	255,	255, 0.97)",
      borderBottomLeftRadius:5,
      borderBottomRightRadius:5,
    }
    // _________________________________________________________ 

    return(
      <Fragment>
      {this.props.children&&
      <div style={wrap}>
        <div style={outline} className={this.props.className}>
          <div style={titlebar}>
            {this.props.icon && <img style={icon} src={this.props.icon} /> }
            <div style={title}>
              {this.props.label}
            </div>
          </div>
          <div style={sentence_area}>
          {this.props.children}
          </div>
        </div>
      </div>
    }
    </Fragment>
    );
  }
}
