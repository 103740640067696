/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';
import RichTextDisplay from './RichTextDisplay';

export default class WelcomeComponent extends React.Component {
  constructor(props){
    super(props);
    this.textList_style = {
      paddingTop:8,
    }
    this.label_style = {
      fontWeight:"bold", 
      marginBottom:16, 
      fontSize:20,
    }
    this.wrap_style = {
      padding : 16, 
      margin : "32px 8px 16px 8px", 
      backgroundColor : "rgba(255,	251,	212, 0.97)",
      borderRadius : 5,
    }
  }
  //_______________________________________________________________________________________________

  render(){
    // console.log("render")
    const { 
      setData,
      label,
    } = this.props;
    let textListArr = [];
    for(let i in setData){
      textListArr.push(
        <div style={this.textList_style} key={"textList"+i}>
          <RichTextDisplay key={"RichText" + i} value={setData[i].info} />
        </div>
      )
    }
    //_______________________________________________________________________________________________

    return (
      <div style={this.wrap_style}>
        <span style={this.label_style}>{label}</span>
        {textListArr}
      </div>
    );
  }
}


