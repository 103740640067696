import React from 'react';

export default class Low extends React.Component{
  constructor(props){
    super(props);
    const { 
      top, 
      bottom,
      middle,
      center,
      between,
      end,
      noWrap,
      w,
      maxW,
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      margin,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
    } = this.props;
    
    this.rows_style = {
      display:"flex",
      flexDirection:"row",
      alignItems:
        top     ? "flex-start" : 
        bottom  ? "flex-end" : 
        middle  ? "center":
        "flex-end",
      justifyContent:
        center   ? "center" :
        between  ? "space-between": 
        end      ? "flex-end" :
        null,
      flexWrap:
        noWrap ? null :
        "wrap",
      width: w,
      maxWidth:maxW,
      padding:padding,
      paddingTop: paddingTop,
      paddingRight: paddingRight,
      paddingBottom: paddingBottom,
      paddingLeft: paddingLeft,
      margin:margin,
      marginTop: marginTop,
      marginRight: marginRight,
      marginBottom: marginBottom,
      marginLeft: marginLeft,
    }
  }
  //__________________________________________________________________________________________________________
  render(){
    const { style, className, children, hidden } = this.props;
    if(hidden) { return null };
    return(
      <div style={Object.assign({},this.rows_style,style)} className={className}>
        {children}
      </div>
    );
  }
}