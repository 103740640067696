import React, { Fragment } from 'react';

export default class TextArea extends React.Component{
  constructor(props) {
    super(props);
    const { 
      value, 
      size,
      style,
      type,
      disabled,
      h,
    } = this.props;

    this.state = {
      value : value || '',
      focus : false,
      hover : false,
    };
    
    let boxWidth
    switch( size ){
      case "ss" : case "SS" : boxWidth = 70;   break
      case "s"  : case "S"  : boxWidth = 113;  break
      case "m"  : case "M"  : boxWidth = 156;  break
      case "l"  : case "L"  : boxWidth = 328;  break
      case "ll" : case "LL" : boxWidth = 672; break    
      default   : { boxWidth = size ? size : 156; break }
    }

    this.textWrap_style = {
      display: 
        style ? null:
        "inline-flex",
      flexDirection:"column",
    }
    this.textarea_style = {
      fontSize:16,
      borderRadius:5,
      height: 
        h ? h : 
        60,
      paddingLeft: 5,
      width: boxWidth,
      boxSizing:"border-box",
      transition:"0.3s",
      marginRight:16,
      marginBottom:16,
      border : "1px solid #aaa",
      paddingRight:
        type === "number" ? 5 :
        null,
      pointerEvents: 
        disabled ? "none" : 
        null,
      textAlign:
        type === "number" ? 'right' :
        null,
    }
  }
  // _____________________________________________________________________

  shouldComponentUpdate(nextProps){
    if(this.props.value !== nextProps.value){
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      this.setState({ value: fixedValue });
    }
    return true;
  }
  // ______________________________________________________________________
  
  handleOnChange = (e) => {
    this.setState({ value : e.target.value });
  }
  // ______________________________________________________________________
   
  handleOnBlur = (e) =>{
    const { name, onChange } = this.props;
    onChange && onChange(name, e.target.value);
    this.setState({ focus : false })
  }
  // _______________________________________________________________________

  render(){
    const { 
      disabled, 
      must, 
      label, 
      style, 
      readOnly,
      maxLength,
      autoFocus,
    } = this.props;

    const { 
      value,    // 入力されている値
      focus,    // boolean型 スタイル変更
      hover,    // boolean型 スタイル変更
    } = this.state;
    // _______________________________________________________________________________________________________

    // 状態によって変更されるスタイルの記述
    Object.assign( this.textarea_style, {
      backgroundColor : //　優先順位順に背景色を設定
        disabled ? "transparent" : 　
        focus    ? "#eef" :
        value    ? "#fff" :
        must     ? "#fee" :
        null, 
      boxShadow: 
        hover ? "0px 0px 3px 0px #00f" :
        null,
    })
    // _______________________________________________________________________________________________________

    return (
      <div style={this.textareaWrap_style}>
        <Labeling label={label} must={must} />
        <textarea
          autoFocus    = {autoFocus}
          style        = {Object.assign({}, this.textarea_style, style )}
          maxLength    = {maxLength}
          value        = {value}
          onChange     = {this.handleOnChange}
          onBlur       = {this.handleOnBlur}
          readOnly     = {readOnly}
          disabled     = {disabled}
          onFocus      = {()=>this.setState({ focus : true  })}
          onMouseOver  = {()=>this.setState({ hover : true  })}
          onMouseLeave = {()=>this.setState({ hover : false })}
        />
      </div>
    )
  }    
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Labeling extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      paddingBottom : "4px"
    }
    this.label_style = {
      fontSize:14,
      marginRight : "1px",
      paddingLeft : "1px",
    }
    this.must_style = {
      fontSize:14,
      fontWeight : "bolder",
      color : "#d00", 
    }
  }
  //___________________________________________________________________
  
  render(){
    const { label, must } = this.props
    if( !label ){ return null }
    return(
      <div style={this.wrap_style}>
        <label style={this.label_style}>{label}</label>
        { must && <span style={this.must_style}>＊</span> }
      </div>
    )
  }  
}