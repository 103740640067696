import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import createStore from './comRedux/createStore';
import Index from './wqc/entry/Index'

export const store  = createStore();
ReactDOM.render(
  <Provider store={store}>
    <Index />  
  </Provider>
  ,document.getElementById('root'));
//ReactDOM.render(<Menu />,document.getElementById('root'));

serviceWorker.unregister();
