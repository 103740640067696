import React, { Fragment,Component } from 'react';
import { connect } from 'react-redux'
import * as WQC from "../WQCComponent/WQCComponent";
import {logOut} from '../../comRedux/Actions';

class Introduce extends Component {
  handleBack = () => {
    this.props.dispatch(logOut());
  }
  render(){
    const body_style={
      padding: "24px",
    }
    const p_style={
      fontWeight:'bold',
      marginTop:8,
    }
    return(
      <WQC.Body style={body_style}>
        <WQC.Title noDetail btnType='back' onClick={this.handleBack}>本システムへの新規登録方法について</WQC.Title>
        <WQC.PRParagram>
          <p>本システムは臨床検査の現場において当社製品をご利用いただいている皆様へのサービスとして開設いたしました。</p>
          <p>ご利用のためにはカスタマーポータルサイトの会員登録をしていただく必要があります。</p>
          <p>会員登録に関しては、下記URLよりご登録をお願いします。</p>
          <p><a href="https://diagnostic-wako.fujifilm.com/labo/support/contact.html">https://diagnostic-wako.fujifilm.com/labo/support/contact.html</a></p>
          <p>会員登録と初期設定を行った後、ご利用の為のパスワードを発行させていただきます。</p>
        </WQC.PRParagram>
        <WQC.PRParagram label="本システムでご提供する機能は、以下の通りです。">
          <p style={p_style}>１）内部精度管理システム各種</p>
          <ul>
            <li>・管理図</li>
            <li>・集計一覧表</li>
            <li>・精度管理データ一覧表</li>
            <li>・除外基準一覧表</li>
          </ul>
          <p style={p_style}>２）外部精度管理システム</p>
          <p style={p_style}>３）サポートデスク</p>
          <p>　双方向に連絡が可能なシステムです。迅速な問題解決にご利用いただけます。</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="お問い合わせ先">
          <p>富士フイルム和光純薬株式会社</p>
          <p>臨床検査薬　カスタマーサポートセンター</p>
          <p>東京都中央区日本橋本町2-4-1</p>
          <p>TEL　03-3270-9134</p>
        </WQC.PRParagram>
      </WQC.Body>
    )
  }

}
// _______________________________________________________________________________________

const mapStateToProps = (state,ownPropS) =>{
  return {
    //stateの範囲を縮めるとRender回数は減ります
    ...state.COMMON.Introduce
  }
}
export default connect(mapStateToProps)(Introduce);