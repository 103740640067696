import React from 'react';

export default class Blank  extends React.Component {

    render(){
       return (
            <div></div>
       );
    }
    
}