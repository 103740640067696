/* eslint-disable no-unused-expressions */
import React, { Fragment, Children } from 'react';

export default class RichTextEditor extends React.Component {
  constructor(props){
    super(props);
    const { must } = this.props;
    this.wrap_style = {
      marginRight:16,
      marginBottom:16,
    }
    this.btnArea_style = {
      display:'flex',
      justifyContent:'flex-end',
      backgroundColor : "#2b406c",
      padding:'4px 8px',
    }
    this.editor_style = {
      cursor: 'text',
      minHeight :200,
      padding :5,
      fontSize : 16,
      lineHeight :1.5,
    }
  }
  //_______________________________________________________________________________________________

  componentDidMount(){
    const { value } = this.props;
    if(value) {this.editor_ref.innerHTML = value;}
  }
  //_______________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if(this.props.value !== nextProps.value) {
      this.editor_ref.innerHTML = nextProps.value
      this.props.onChange && this.props.onChange( this.props.name , this.editor_ref.innerHTML )
    }
    return true;
  }
  //_______________________________________________________________________________________________

  // エディターに入力したとき
  editorOnBlur = () => {
    // console.log(this.editor_ref)
    this.props.onChange && this.props.onChange( this.props.name , this.editor_ref.innerHTML )
  }
  //_______________________________________________________________________________________________
  addLink =()=> {
    var linkURL = prompt('URLを入力してください', 'https://');
    var sText = document.getSelection();
    document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
  }
  //_______________________________________________________________________________________________
  handlePaste = (e) => {
    e.preventDefault()
    var text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  }
  //_______________________________________________________________________________________________
  render(){
  
    const { disabled, label, must } = this.props
    //_______________________________________________________________________________________________

    return (
      <div style={this.wrap_style}>
        <Labeling label={ label }　must={must}/>
        <EditorWrapper disabled = {disabled}>
          <div style={this.btnArea_style}>
            <EditBtn createLink onClick={this.addLink} />
            <EditBtn bold onClick={()=>document.execCommand( 'bold' )} />
            <EditBtn italic onClick={()=>document.execCommand( 'italic' )} />
            <EditBtn underline onClick={()=>document.execCommand( 'underline' )} />
            {/* <EditBtn underline onClick={()=>document.execCommand( "createLink", true, )} /> */}
            <EditBtn color="black" onClick={()=>document.execCommand("foreColor", false, 'black')} />
            <EditBtn color="blue" onClick={()=>document.execCommand("foreColor", false, 'blue')} />
            <EditBtn color="red" onClick={()=>document.execCommand("foreColor", false, 'red')} />
            <EditBtn color="yellow" onClick={()=>document.execCommand("foreColor", false, 'yellow')} />
            <EditBtn color="green" onClick={()=>document.execCommand("foreColor", false, 'green')} />
          </div>
          <div
            style           = { this.editor_style } 
            contentEditable = { !disabled }
            ref             = { (el) => this.editor_ref = el }
            onBlur          = { this.editorOnBlur }
            onPaste         = { this.handlePaste }
          />
        </EditorWrapper>
      </div>
    );
  }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class EditBtn extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state
    const { 
      onClick,
      onKeyDown,
      disabled,
      color,
      bold,
      underline,
      italic,
      reset,
      createLink,
    }= this.props

    const button_style = {              // <button> 
      backgroundColor : 
        color ?  color :
        hover ? "#fff" :
        "transparent",
      color:
        hover ? "#2b406c" :   
        "#fff", 
      border :
        hover && color ? "3px solid #fff" :   
        "none",
      cursor:"pointer",
      height : 30,
      width  : 
        createLink ? null:
        30,
      borderRadius:5,
      margin:'0px 2px',
      fontSize:25,
      boxSizing:"border-box",
      transition:"0.3s",
      textDecorationLine :
        underline ? 'underline' :
        null,
      fontStyle : 
        italic ? 'italic' :
        null,
      fontWeight : 
        bold ? "bolder" :  
        "bold",
      fontFamily :'Times New Roman',

    }

    return(
      <button 
        tabIndex     = {-1}
        style        = { button_style } 
        onClick      = { onClick }
        onKeyDown    = { onKeyDown }
        disabled     = { disabled  }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
      { 
        createLink  ? 'Link' :
        bold        ? 'B' :
        underline   ? 'U' :
        italic      ? 'I' :
        reset       ? 'D' :
        null
      }
      </button>
    )
  }  
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Labeling extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      paddingBottom : "4px"
    }
    this.label_style = {
      fontSize:14,
      marginRight : "1px",
      paddingLeft : "1px",
    }
    this.must_style = {
      fontSize:14,
      fontWeight : "bolder",
      color : "#d00", 
    }
  }
  //___________________________________________________________________
  
  render(){
    const { label, must } = this.props
    if( !label ){ return null }
    return(
      <div style={this.wrap_style}>
        <label style={this.label_style}>{label}</label>
        { must && <span style={this.must_style}>＊</span> }
      </div>
    )
  }  
}////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class EditorWrapper extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      hover : false,
      focus : false,
    };
  }
  //_______________________________________________________________________________________________
  render(){
    
  const { hover, focus } = this.state
  const { disabled, children, must, value}= this.props

  const wrap = {
    width : 768,
    border:'solid 1px #aaa',
    borderRadius:5,
    boxShadow: 
      disabled ? null :
      hover ? "0px 0px 3px 0px #00f" :
      null,
    backgroundColor : 
      disabled ? "transparent" : 
      // focus    ? "#eef"  :
      // value    ? "#fff" :
      // must     ? "#fee" :
      "#fff",
  }
  
    return(
      <div
        style        = { wrap }
        onFocus      = { () => this.setState({ focus : true }) }
        onBlur       = { () => this.setState({ focus : false }) }
        onMouseOver  = { () => this.setState({ hover : true  }) }
        onMouseLeave = { () => this.setState({ hover : false }) }
      >
        { children }
      </div>
    )
  }
}