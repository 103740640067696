import React, { Fragment } from 'react';
import ErrorMsgBar from './ErrorMsgBar';

export default class InputArea extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      backgroundColor: "#eee",
      padding:"16px 16px 0px 16px",
      marginBottom:32,
      borderRadius:5,
      width:"100%",
      display:"flex",
      flexDirection:"column",
    }
    this.err_style = {
      backgroundColor:"#d22",
      color:"white",
      fontWeight:"bolder",
      padding:"8px",
      borderRadius:5,
      marginBottom:16,
    }
  }
  // ______________________________________________________________________________

  render(){
    const { style, errMsg, children, hidden } = this.props;
    if( hidden ){ return null }
    return(
      <div style={Object.assign({}, this.wrap_style, style )}>
        <ErrorMsgBar style={this.err_style}/>
        {errMsg && <div style={this.err_style}>{errMsg}</div>}
        {children}
      </div>
    )
  }
}
