import React, { Fragment } from 'react';
import { connect } from 'react-redux';

class Title extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hoverStyle:null,
    }
  }

  hover =()=>{
    
    this.setState({ 
      hoverStyle : { 
        backgroundColor: "#fff", 
        border: "2px solid #000", 
        color:"#000" 
      }
    })
  
  }
  // ______________________________________________________________________________

  
  render(){
    const { mode, checkPage, noDetail, btnType } = this.props

    let btn_label     = null
    let btn_color     = null
    let btn_fcolor    = null
    let title_detail  = null
    switch( mode ){

      case "0" :　                  // 検索画面
        btn_label = "新規登録"
        btn_color = "#FFCC80"
        title_detail  ="（検索画面）"
      break;

      case "1" :                    // 新規登録画面
        btn_label  = "検索画面へ戻る"
        btn_color  = "#2296F3"
        btn_fcolor = "#fff"
        title_detail  ="（新規登録画面）"
      break;

      case "2" : case "4" :                    // 編集画面
        btn_label = "検索画面へ戻る"
        btn_color = "#2296F3";
        btn_fcolor = "#fff"
        title_detail  ="（編集画面）"
      break;

      default:
    }

    if( checkPage ){
      switch( mode ){
        case "1" :                    // 新規登録画面
          btn_label  = "入力画面へ戻る"
          btn_color  = "#FFCC80"
          btn_fcolor = "#000"
          title_detail  ="（確認画面）"
        break;

        case "2" : case "4" :                    // 編集画面
          btn_label = "編集画面へ戻る"
          btn_color = "#FFCC80";
          btn_fcolor = "#000"
          title_detail  ="（確認画面）"
        break;

      default:
      }
      
    }

    switch(btnType){

      case "add" :　                  // 検索画面
        btn_label = "新規登録"
        btn_color = "#FFCC80"
        title_detail  ="（検索画面）"
      break;

      case "back" :                    // 新規登録画面
        btn_label  = "戻る"
        btn_color  = "#555"
        btn_fcolor = "#fff"
      break;

      default:
    }


    if( noDetail ){ title_detail = null } // noDetailが設定されていたらタイトルの詳細は表示しません
    // ______________________________________________________________________________

    const wrap = {
      marginTop:16,
      marginBottom:24,
      display: "inline-flex",
      alignItems: "center",
      width:"100%",
      height: 35,
      paddingLeft:8,
      borderLeft:"10px solid #3faf7f",
      // backgroundColor:"#ccc",
      justifyContent:"space-between",
    }

    const title_style = {
      fontWeight: "bolder",
      fontSize: 18,
      color:"#333",
    } 

    const btn_style = {
      height: 35,
      padding:"0px 25px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 20,
      boxShadow:" 2px 2px 2px #aaa",
      cursor: "pointer",
      border:"2px solid " + btn_color,
      color: btn_fcolor,
      backgroundColor: btn_color,
      transition:"0.3s",
    }
    // ______________________________________________________________________________
    
    return(
      <div style={wrap}>
        <span style={ title_style }>{this.props.children}{title_detail}</span>
        { !this.props.noBtn &&
        <button 
          style        = { Object.assign({}, btn_style, this.state.hoverStyle ) }
          onClick      = { this.props.onClick} 
          onMouseOver  = { this.hover }
          onMouseLeave = { () => this.setState({ hoverStyle : null }) }
          onFocus      = { this.hover }
          onBlur       = { () => this.setState({ hoverStyle : null }) }

        >
          {btn_label}
        </button>
        }
      </div>
    )
  }
}

const mapStateToProps = (state,props) => {
  return {
    mode:state.COMMON.Common.mode,
    onClick:props.onClick,
  }
}

export default connect(mapStateToProps,null)(Title);
