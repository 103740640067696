import React, { Fragment } from 'react';
import BasicComponent from './BasicComponent';
import SearchIcon from "../image/SearchIcon.png";
import triangle_icon from "../image/triangle.svg"


export default class Select extends BasicComponent{
  constructor(props){
    super(props);
    const {
      value,
      setData,
      size,         // 横幅のサイズ SS,S,M,L,LL, 数値　で入力可能
    } = this.props;

    this.state = {
      value        : value || null,    // 選択されているアイテムのvalue
      searchItems  : "",      // 検索ボックスの入力値
      showItems    : false,   // ボックスが表示されているか
      setData      : setData || []　// セットされたデータ
    }

    let boxWidth
    switch( size ){
      case "ss" : case "SS" : { boxWidth = 70;   break; }
      case "s"  : case "S"  : { boxWidth = 113;  break; }
      case "m"  : case "M"  : { boxWidth = 156;  break; }
      case "l"  : case "L"  : { boxWidth = 328;  break; }
      case "ll" : case "LL" : { boxWidth = 672;  break; }  
      default   : { boxWidth = size ? size : 156; break }
    }
    
    this.wrap_style = {          // <div>　一番大元の Wrap要素
      position:"relative",
      display: "inline-flex",
      flexDirection:"column",
      marginRight:16,
      marginBottom:16,
    }
    this.selectWrap_style = {         // <div>　セレクトのWrap要素
      display:"inline-flex",
      flexDirection:"column",
      position:"relative",
      boxSizing:"border-box",
      width: boxWidth,
    }
    this.select_style = {        // <button>値を表示するメインのボタン
      background:`url(${triangle_icon}) no-repeat`,
      backgroundPosition:"center right",
      backgroundSize:"18px 18px",
      height:30,
      width: boxWidth,
      textAlign:"left",
      paddingLeft:5,
      border : "1px solid #aaa",
      boxSizing:"border-box",
      borderRadius:5,
      fontSize:16,
      transition:"0.2s",
      cursor : "pointer",
    }
    this.hiddenbox_style = {          // <div>ボックス
      flexDirection:"column",
      backgroundColor:"#fff",
      position:"absolute",
      zIndex:5,
      width:boxWidth,
      maxHeight:"250px",
      overflowY:"scroll",
      whiteSpace: "nowrap",
      boxSizing:"border-box",
      border:"1px solid #aaa",
      borderRadius:5,
    }
    this.searchWrap_style = {              // <div> 検索テキストボックスのラップ要素
      padding:"5px 5px",
      boxSizing:"border-box",
    }
    this.search_style = {            // <input> 検索テキストボックス
      background:`url(${SearchIcon}) no-repeat`,
      backgroundPosition:"center left 5px",
      backgroundSize:"18px 18px",
      height:25,
      width:"100%",
      paddingLeft:30,
      border:"1px solid #aaa",
      borderRadius:5,
      boxSizing:"border-box",
    }
    this.itemsWrap_style = {              // <div> リストアイテムのラップ要素
      display:"inline-flex",
      flexDirection:"column",
      padding:"5px 5px",
      width:"100%",
      boxSizing:"border-box",
    }

    this.select_ref      = React.createRef();       // <button>要素：選択されているアイテムを表示するメインのボタン
    this.hiddenbox_ref   = React.createRef();       // <button>要素：選択されているアイテムを表示するメインのボタン
    this.itemsWrap_ref   = React.createRef();       // <div>要素：アイテムボックスのラップ要素
    this.search_ref      = React.createRef();       // <input>要素：検索用テキストボックス
    this.elementClickFlag = false;   // クリックされた場所が、要素上かどうか検知するために定義
  }
  // ________________________________________________________________________________________________________________

  componentDidMount(){
    const { autoFocus } = this.props
    autoFocus && this.select_ref.current.focus() // オートフォーカス機能
  }
  // ________________________________________________________________________________________________________________

  shouldComponentUpdate( nextProps ){
    if( this.props.value !== nextProps.value ){ // 親から渡されるvalueに変更があれば
      // console.log( this.props.value,'→' ,nextProps.value )
      this.setState({ value : nextProps.value}) // 変更後の値を反映
    }
    return true;
  }
  // ________________________________________________________________________________________________________________

  componentDidUpdate(){
    if( !this.state.showItems ){                               // ボックスが閉じている時
      window.removeEventListener('click',this.pageClick,)      // windowイベントpageClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }
  }
  // ________________________________________________________________________________________________________________

  // 画面をクリックした時
  pageClick = () => {
    if( this.elementClickFlag ){                  // もしセレクトボックス上で、マウスダウンされていたら
      this.elementClickFlag = false;              //　elementClickFlag を初期値に戻して
      return;                                    // クリック処理を中断
    }
    this.setState({showItems:false})             // ボックスを閉じる
  }
  // ________________________________________________________________________________________________________________

  // メインのボタンがフォーカスされた時
  selectFocus = () => {
    // const { showItems } = this.state  コールバックが使えなくなる

    this.setState({ 
      focus       : true,    // 背景色を変える
      showItems   : !this.state.showItems,  // セレクトボックスを開閉切り替え
      setData     : this.props.setData || [],  // フィルターをリセット
      searchItems : "", // 検索ボックスの初期化
    },()=>{   // コールバックで同期
      if( this.state.showItems ){  // セレクトボックスが開いている時  
        window.addEventListener('click',this.pageClick);        // windowイベントpageClick関数を作成します。

        //  表示する位置を切り替える処理
        let boxPosition = this.select_ref.current.getBoundingClientRect().bottom  // hiddenboxの下辺の位置を取得
        let boxHeight   = this.hiddenbox_ref.current.clientHeight                 // hiddenboxの縦サイズを取得
        if( boxPosition + boxHeight > window.innerHeight ){   // hiddenboxの下辺の位置 + 縦サイズが windowの縦サイズより大きいなら
          this.setState({ showTop : true })       // boxを上部に表示する（スタイルと連携）
        }else{
          this.setState({ showTop : false })      // boxを下部に表示する（スタイルと連携）
        }
      }
    }) 
  }
  // ________________________________________________________________________________________________________________

  // メインのボタンをクリックしたとき
  selectClick =(e)=> {
    e.preventDefault()
    window.addEventListener('click',this.pageClick);        // windowイベントpageClick関数を作成します。

    this.setState({ 
      showItems: true  // ボックスを開く
    },()=>{            // コールバックで同期
      //  表示する位置を切り替える処理
      let boxPosition = this.select_ref.current.getBoundingClientRect().bottom  // hiddenboxの下辺の位置を取得
      let boxHeight   = this.hiddenbox_ref.current.clientHeight                 // hiddenboxの縦サイズを取得
      if( boxPosition + boxHeight > window.innerHeight ){   // hiddenboxの下辺の位置 + 縦サイズが windowの縦サイズより大きいなら
        this.setState({ showTop : true })       // boxを上部に表示する（スタイルと連携）
      }else{
        this.setState({ showTop : false })      // boxを下部に表示する（スタイルと連携）
      }
    })        
  }
  // ________________________________________________________________________________________________________________
    
  // メインのボタンに対してキーを押した時
  selectKeyDown =(e)=>{
    const { showItems, setData } = this.state;
    const { searchable } = this.props;
    if( !showItems || setData.length <= 0 ){ return; }       // セレクトボックスが閉じているとき、またはsetDataがない時にreturnさせて、処理をさせないためのif文です
    
    switch( e.keyCode ){ 
      case 9: { // Tabキー
        this.setState({showItems:false});         // セレクトボックスを閉じる
        break;
      }
      default: {
        e.preventDefault();
        if(!searchable) { this.itemsWrap_ref.current.firstChild.focus(); }  // (親から)searchableが設定されていなかったら（検索ボックスがなければ）firstChildで先頭の要素を参照して、フォーカス
        else{ this.search_ref.current.focus(); }   // 検索ボックスがあったら 検索ボックスにフォーカス                   
        break;
      }
    }
  }
  // ________________________________________________________________________________________________________________

  // 検索ボックスに入力した時
  searchChange =(e)=>{
    const { setData } = this.props 
    e.preventDefault();
    
    // 絞り込み処理
    let filterdData = setData.filter( setData  => { // setDataにフィルター
      return (setData.label.toLowerCase().search(e.target.value.toLowerCase()) !== -1); // searchメソッドで検索 -1 じゃなかったら値がある。.toLowerCase()は大文字小文字を区別しないため。
    })

    this.setState({ 
      setData : filterdData,      // 絞り込み後のデータをセット 
      searchItems:e.target.value, // 検索ボックス内のstateを更新するための処理。（外すと検索ボックスに文字が打てなくなります）
    })          }
  // ________________________________________________________________________________________________________________

  // 検索ボックスに対してキーを押した時
  searchKeyDown =(e)=>{
    switch( e.keyCode ){
      case 9: // Tabキー
        e.preventDefault();
        this.select_ref.current.focus();                    // メインのボタンにフォーカスを戻しています。(IEとEdgeは、フォーカスが最初の位置に戻ってしまうので)
      break
      case 40 :　// 下
      case 13 :
        e.preventDefault();
        if( this.itemsWrap_ref.current.children.length > 0 ){   // 検索結果あれば
         this.itemsWrap_ref.current.firstChild.focus();         // firstChildで先頭の要素を参照して、フォーカス
        }
      break;
      default:
    }
  }
  // ________________________________________________________________________________________________________________

  // アイテムをクリックした時

  itemsClick =( value )=>{
    this.setState({ value : value });           // 選択された値をstateで管理。色を変えるのと、メインのボタンに格納するために使っています
    this.props.onChange && this.props.onChange(this.props.name,value); // 親にnameとvalueを渡している
    this.select_ref.current.focus();                 // メインのボタンにフォーカスを戻しています。(IEとEdgeは、フォーカスが最初の位置に戻ってしまうので)
  }
  // ________________________________________________________________________________________________________________
  
  // アイテム対してキーを押した時

  itemsKeyDown = (value, e ) => {
    const { searchabel, onChange } = this.props;
    switch( e.keyCode ){
      case 9: // Tabキー
        e.preventDefault();
        this.select_ref.current.focus();                    // メインのボタンにフォーカスを戻しています。(IEとEdgeは、フォーカスが最初の位置に戻ってしまうので)
      break

      case 38 :　// 上
        e.preventDefault();
        if( e.target.previousElementSibling ) {            // previousElementSiblingで前の要素を参照して、要素があるなら
          e.target.previousElementSibling.focus();        // フォーカスを前の要素へ移動
        }
        else if( this.props.searchable ) {                   // (親から)searchableが設定されていたら
          this.search_ref.current.focus();                // 検索ボックスにフォーカス
        }
      break;

      case 40 :　// 下
        e.preventDefault();
        if( e.target.nextElementSibling ) {                // previousElementSiblingで次の要素を参照して、要素があるなら
          e.target.nextElementSibling.focus();            // フォーカスを次の要素へ移動
        }
      break;
      case 13 : // Enter キー
        e.preventDefault();                              // onClickとしても発火するのでここだけで制御するためにしています。（これをつけないと、IE,Edgeはボックスが閉じない）
        this.setState({ 
          value :value      // 選択された値をstateで管理。色を変えるのと、メインのボタンに格納するために使っています 
        },()=>{ //  コールバックで同期
          this.props.onChange && this.props.onChange(this.props.name,this.state.value ); // 親にnameとvalueを渡している        
        });
        this.select_ref.current.focus();                        // メインのボタンにフォーカスを戻しています。(IEとEdgeは、フォーカスが最初の位置に戻ってしまうので)
      break;
      default: 
    }
  }
  // ________________________________________________________________________________________________________________

  render(){
    
    // state と props の省略宣言
    const {
      label,        // ラベル
      must,         // boolean型 必須マーク「＊」を表示
      disabled,     // 編集不可にする
      searchable,   // boolean型 trueなら検索可能セレクトになる　
    } = this.props

    const { 
      setData,  //  ArrayObject型 [{ value : ... , label : ... }, { value : ... , label : ... }]で設定
      value,    // 選択されているアイテム
      focus,    // boolean型 スタイル変更
      hover,    // boolean型 スタイル変更
      showTop,  // boolean型 ボックスを表示する位置　trueなら上に表示
      showItems,   // boolean型 trueならボックスを表示
    } = this.state
    // ________________________________________________________________________________________________________________
    
    // 状態によって変更されるスタイルの記述
    Object.assign( this.select_style, {
      backgroundColor : //　優先順位順に背景色を設定
        disabled ? "transparent" : 　
        focus    ? "#eef" :
        value    ? "#fff" :
        must     ? "#fee" :
        "#fff", 
      boxShadow: 
        hover ? "0px 0px 3px 0px #00f" :
        null,
      pointerEvents: 
        disabled? "none" : 
        null,
    })
    Object.assign( this.hiddenbox_style, {
      top: 
        showTop ? null:
        "100%",
      bottom: 
        showTop ? "100%" : 
        null,
      display:
        showItems ? "inline-flex" :
        'none',
    })
    // _______________________________________________________________________________________________________

    // 変数の宣言
      let itemsArr  = [];  // 最終的に表示するリストアイテムの配列
      let selectLabel = null //表示する選択中の配列ラベル 
    // ________________________________________________________________________________________________________________

    // リスト項目を作成
    for(let i in setData ){  
      itemsArr.push(
        <ItemButton 
          selected     = { value === setData[i].value }  
          onClick      = { () => this.itemsClick( setData[i].value )}
          onKeyDown    = { (e)=> this.itemsKeyDown( setData[i].value, e ) }
          key          = { 'itemsArr_'+i }
          label        = { setData[i].label }
        />
      )
    }
    if( value ){ // valueがあれば
      let filteredData = this.props.setData.filter( setData => { // setDataにフィルターをかけて
        if (setData.value === value ) return true;  // valueがあるデータを参照 filteredData = [{ value :"", label : ""}]
      })

      if( filteredData.length > 0 ){ selectLabel = filteredData[0].label　}  // // filteredDataがあれば、labelを抽出して表示する
      // else if( setData.length > 0 ){ console.log("エラー：valueで設定されているデータがありません。value=",value,"setData=",setData) }　// エラーログ
    }
    // ________________________________________________________________________________________________________________
    // HTML
    return(
      <div style={this.wrap_style}>
        <Labeling label={label} must={must} />
        <div 
          style       = {this.selectWrap_style} 
          onMouseDown = { ()=> this.elementClickFlag = true } 
          onKeyDown   = { ()=> this.elementClickFlag = true } 
        >
          <button 
            ref          = { this.select_ref }
            style        = { Object.assign({}, this.select_style) }
            onFocus      = { this.selectFocus }
            onClick      = { this.selectClick }
            onKeyDown    = { this.selectKeyDown } 
            disabled     = { disabled }
            onBlur       = { () => this.setState({ focus : false }) }
            onMouseOver  = { () => this.setState({ hover : true  }) }
            onMouseLeave = { () => this.setState({ hover : false }) }
          >
            { selectLabel }     
          </button>
        
          <div style={Object.assign({},this.hiddenbox_style)} ref = { this.hiddenbox_ref }>
            {searchable && 
            <div style={this.searchWrap_style}>
              <input 
                style     = {this.search_style}
                value     = {this.state.searchItems}
                onChange  = {this.searchChange}
                onKeyDown = {this.searchKeyDown}
                ref       = {this.search_ref}
              />  
            </div> 
            }
            <div style={this.itemsWrap_style} ref={this.itemsWrap_ref}>
              { itemsArr } {/* リストアイテム */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class ItemButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
    this.button_style = { // <button> リストアイテム
      height:20,
      textAlign:"left",
      paddingLeft:5,
      border:"none",
      cursor:"pointer",
      borderRadius:5,
      width:"100%",
      marginBottom:"2px",
      boxSizing:"border-box",
      transition:"0.2s",
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state;
    const { 
      selected,
      value,
      onClick,
      onKeyDown,
      label,
    } = this.props;

    // 状態によって変更されるスタイル
    Object.assign( this.button_style, {
      backgroundColor:
        hover    ? "#ccc":
        selected ? "#2b406c":
        "transparent",
      color :
        hover    ? null:
        selected ? '#fff': 
        null,
    })
    //______________________________________________________________________

    return(
      <button 
        style        = {Object.assign({},this.button_style)} 
        value        = { value }
        onClick      = { onClick }
        onKeyDown    = { onKeyDown }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        {label}
      </button>
    )
  }  
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Labeling extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      paddingBottom : "4px"
    }
    this.label_style = {
      fontSize:14,
      marginRight : "1px",
      paddingLeft : "1px",
    }
    this.must_style = {
      fontSize:14,
      fontWeight : "bolder",
      color : "#d00", 
    }
  }
  //___________________________________________________________________
  
  render(){
    const { label, must } = this.props
    if( !label ){ return null }
    return(
      <div style={this.wrap_style}>
        <label style={this.label_style}>{label}</label>
        { must && <span style={this.must_style}>＊</span> }
      </div>
    )
  }  
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
const testData=[
  { value :" " , label :" " },
  { value :1 , label :"test1" },
  { value :2 , label :"test2" },
  { value :3 , label :"test3" },
  { value :4 , label :"test4" },
]

*/