import React, { Fragment } from 'react';
import BasicComponent from './BasicComponent';


export default class Text extends BasicComponent {
  constructor(props) {
    super(props);
    const { 
      value, 
      size,
      style,
      type,
      disabled,
    } = this.props;

    this.state = {
      value : value || '',
      focus : false,
      hover : false,
    };
    
    let boxWidth
    switch( size ){
      case "ss" : case "SS" : boxWidth = 70;   break
      case "s"  : case "S"  : boxWidth = 113;  break
      case "m"  : case "M"  : boxWidth = 156;  break
      case "l"  : case "L"  : boxWidth = 328;  break
      case "ll" : case "LL" : boxWidth = 672; break    
      default   : { boxWidth = size ? size : 156; break }
    }

    this.textWrap_style = {
      display: 
        style ? null:
        "inline-flex",
      flexDirection:"column",
    }
    this.text_style = {
      fontSize:16,
      borderRadius:5,
      height: 30,
      paddingLeft: 5,
      width: boxWidth,
      boxSizing:"border-box",
      transition:"0.3s",
      marginRight:16,
      marginBottom:16,
      border : "1px solid #aaa",
      paddingRight:
        type === "number" ? 5 :
        null,
      pointerEvents: 
        disabled ? "none" : 
        null,
      textAlign:
        type === "number" ? 'right' :
        null,
    }
  }
  // _____________________________________________________________________

  componentDidMount(){
    const { type } = this.props;
    switch( type ){
      case "password": {
        this.text_ref.type = "password";
        break;
      }
      case "number": case "decimal" : case "alphanum" :{
        this.text_ref.type = "tel";
        break
      }
      default: {
        this.text_ref.type = "text";
        break;
      }
    }       
  }
  // ______________________________________________________________________

  shouldComponentUpdate(nextProps){
    if(this.props.value !== nextProps.value){
      const fixedValue = nextProps.value ? nextProps.value : ""; // null や undifinedを''に修正
      this.setState({ value: fixedValue });
    }
    return true
  }
  // ______________________________________________________________________

  handleOnBlur = e =>{
    const { email, name, onChange } = this.props;
    if(email && e.target.value != ''){
      let regExp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/; 
      if(!regExp.test(e.target.value)){
        e.target.focus();
        alert(' please input correct email!');
      }
    }
    onChange && onChange(name, e.target.value);
    this.setState({ focus : false })
  }
  // ______________________________________________________________________________________________________

  handleOnChange = (e) => {
    const { type } = this.props;
    let replaceValue = null;
    // typeによって文字列変換をするためのswitch文です
    switch(type) {
      case "number" : {
        replaceValue = e.target.value.replace(/[^\d]/g,'');
        break;
      }
      case "decimal" : {
        replaceValue = e.target.value.replace(/[^\d||.]/g,'');
        break;
      }
      case "alphanum" : {
        replaceValue = e.target.value.replace(/[^a-zA-Z0-9!#$%&\()*+/:;=?@\[\]^_{}-]/g,'');
        break;
      }
      default : {
        replaceValue = e.target.value;
        break;          
      }
    }
    this.setState({ value: replaceValue })
  }
  // _______________________________________________________________________________________________________
  
  handleOnKeyDown = (e) => {
    const { onEnter } = this.props;
    if(e.keyCode === 13) { onEnter && onEnter(); };
  }
  render() {
    const { 
      disabled, 
      must, 
      label, 
      style, 
      readOnly,
      maxLength,
      autoFocus,
    } = this.props
    const { 
      value,    // 入力されている値
      focus,    // boolean型 スタイル変更
      hover,    // boolean型 スタイル変更
    } = this.state
    // _______________________________________________________________________________________________________

    // 状態によって変更されるスタイルの記述
    Object.assign( this.text_style, {
      backgroundColor : //　優先順位順に背景色を設定
        disabled ? "transparent" : 　
        focus    ? "#eef" :
        value    ? "#fff" :
        must     ? "#fee" :
        null, 
      boxShadow: 
        disabled ? null : 　
        hover ? "0px 0px 3px 0px #00f" :
        null,
    })
    // _______________________________________________________________________________________________________

    return (
      <div style={this.textWrap_style}>
        <Labeling label={label} must={must} />
        <input 
          // autoComplete = "off"
          autoFocus    = {autoFocus}
          style        = {Object.assign({}, this.text_style, style )}
          ref          = {(el)=>this.text_ref = el}
          maxLength    = {maxLength}
          value        = {value}
          onChange     = {this.handleOnChange}
          onBlur       = {this.handleOnBlur}
          onKeyDown    = {this.handleOnKeyDown}
          readOnly     = {readOnly}
          disabled     = {disabled}
          onFocus      = {()=>this.setState({ focus : true  })}
          onMouseOver  = {()=>this.setState({ hover : true  })}
          onMouseLeave = {()=>this.setState({ hover : false })}
        />
      </div>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Labeling extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      paddingBottom : "4px"
    }
    this.label_style = {
      fontSize:14,
      marginRight : "1px",
      paddingLeft : "1px",
    }
    this.must_style = {
      fontSize:14,
      fontWeight : "bolder",
      color : "#d00", 
    }
  }
  //___________________________________________________________________
  
  render(){
    const { label, must } = this.props
    if( !label ){ return null }
    return(
      <div style={this.wrap_style}>
        <label style={this.label_style}>{label}</label>
        { must && <span style={this.must_style}>＊</span> }
      </div>
    )
  }  
}