/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

export default class Link extends React.Component{
  constructor( props ){
    super( props )
    this.state = {
      hover : false,
    }
  }
    
  render(){
    const { style, className, onClick, children, label  } = this.props
    const { hover } = this.state
    const link_style = {
      backgroundColor:"transparent", 
      border:"none", 
      color:
        hover ? "#ccc":
        "#00a",
      cursor:"pointer",
      textDecoration:  
        hover ? "underline solid #ccc" :
        "underline solid #00a",   
    }
    
    return(
      <a
        tabIndex     = { 0 }
        style        = { !className ? Object.assign({}, link_style, style ) : null }
        className    = { className }
        onClick      = { onClick }
        onFocus      = {()=> this.setState({ hover:true  }) }
        onBlur       = {()=> this.setState({ hover:false }) }
        onMouseOver  = {()=> this.setState({ hover:true  }) }
        onMouseLeave = {()=> this.setState({ hover:false }) }
      >
      {label}{children}
      </a>
    )
  }
}