import React from 'react'
import * as WQC from "../WQCComponent/WQCComponent";
import * as SKY from "../../component/ComComponent";

export default class PR003 extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <WQC.Body>
        <WQC.Title noDetail noBtn>個人情報保護方針</WQC.Title>
        <WQC.PRParagram>
          <p>富士フイルム和光純薬株式会社は、個人情報の重要性を認識し、以下の方針に基づき、 個人情報の保護に努めます。</p>        
        </WQC.PRParagram>
        <WQC.PRParagram label="個人情報の取得について">
          <p>当社は、適法かつ公正な手段によって、個人情報を取得します。</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="個人データの利用について">
          <p>(1) 当社は、個人情報を、あらかじめ通知または公表した利用目的の範囲内で利用します。</p>
          <p>(2) 当社は、個人データの取扱いを委託する場合には、個人データの安全管理が図られるよう、委託先を厳正に調査・選定し、必要かつ適正な監督を行います。</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="個人データの第三者提供について">
          <p> 当社は、法令に定める場合を除き、あらかじめ本人の同意を得ることなく、個人データを、第三者に提供しません。</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="個人データの管理について">
          <p>(1) 当社は、利用目的の達成に必要な範囲内において個人データの正確性を保ち、これを安全に管理します。</p>
          <p>(2) 当社は、個人データの紛失、破壊、改ざんおよび漏えいなどを防止するため、不正アクセス、コンピュータウィルス等に対する合理的な情報セキュリティ対策を講じます。</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="保有個人データの開示等について">
          <p>当社は、本人より、自己の保有個人データについて、開示・訂正・利用停止等の求めがある場合には、法令に従い、遅滞なく対応します。</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="個人情報保護管理体制について">
          <p>(1) 当社は、個人情報を適正に保護し取扱うため個人情報保護管理体制を運用し、継続的な見直し及び改善を行ないます。</p>
          <p>(2) 当社は、従業員に対し、個人情報の保護および適正な管理方法についての教育・研修を実施し、日常業務における個人情報の適正な取扱いを徹底します。</p>
        </WQC.PRParagram>

      </WQC.Body>
    )
  }
}