import React, {Fragment} from 'react';

export default class ReferenceTable extends React.Component{
  constructor(props){
    super(props);
    this.table_style = {
      width : "100%",
      tableLayout: "fixed",
      borderCollapse: "separate",
      border: "solid 1px #c2c2c2",
      borderRadius: 5,
      backgroundColor: "#fff",
    }
  }
  // _______________________________________________________________________________________________________________

  render(){
    const { 
      children,
      color, 
      fontColor,
    } = this.props
    const tbodyArr = []

    for( let i in children ){
      const { header, name, colSpan } = children[i]
      tbodyArr.push(
        <tr key={'tr' + i}>
          <TH 
            key     = {"TH"+i}
            label   = { header }  
            color   = { fontColor } 
            bgColor = { color }
            indexS  = { i === '0' } 
            indexE  = { i == children.length - 1 }
            colSpan = { colSpan }
          />
          <TD 
            key    = {"TD"+i}
            label  = { name } 
            indexS = { i === '0' } 
            indexE = { i == children.length - 1 }
            colSpan = { colSpan }
          />
        </tr>
      )
    }
    // _______________________________________________________________________________________________________________

    return(
      <table style={this.table_style}>
        <tbody>
          { tbodyArr }
        </tbody>
      </table>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class TD extends React.Component{
  render(){
    const {
      label,
      indexS,
      indexE,
      colSpan,
    } = this.props

    if( !label ){ return null }

    const td_style = {
      backgroundColor : "#fff",
      padding:2,
      borderRadius :
        indexS ? "0px 5px 0px 0px" : 
        indexE ? "0px 0px 5px 0px" :
        null,
      borderBottom: "1px solid #ddd",
      borderTop: "1px solid #ddd",

    }
    return(
      <td style={ td_style } colSpan={colSpan}>
        { label }
      </td>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


class TH extends React.Component{
  render(){
    
    const {
      label,
      color,
      bgColor,
      indexS,
      indexE,
      colSpan,
    } = this.props

    if( !label ){ return null }

    const th_style = { 
      backgroundColor :bgColor,
      color : color,
      padding:2,
      borderRadius :
        indexS ? "5px 0px 0px 0px" : 
        indexE ? "0px 0px 0px 5px" :
        null,
      borderBottom: "1px solid #ddd",
      textAlign:"center",
      verticalAlign: 'middle',
    }

    return(
      <td style={ th_style } colSpan={colSpan}>
        { label }
      </td>
    )
  }
}