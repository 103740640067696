import React, {Fragment} from 'react';
import copyIcon from "../../image/copy_icon.png";

export default class QCTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      cellHover   : [null,null] ,   // オンマウス中のセルの場所を保持して、スタイル適用するため
      cellFocus   : [null,null],    // フォーカス中のセルの場所を保持して、スタイル適用するため
      fowardCol   : true,
      setData     : this.props.setData || []
    }
    // console.log( this.props.setData )
    this.firstRender = true;
  }
  // ______________________________________________________________________________________________________
  
  componentDidMount(){
    this.firstRender = false;
  }
  // ______________________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if( this.props.setData !== nextProps.setData ){             //　親から渡されるsetDataが変化したら
      this.setState({ setData : nextProps.setData }) // コピーした配列をセット
    }
    return true
  }
  // ______________________________________________________________________________________________________

  // フォーカスが外れた時
  
  getData =()=> {
    this.props.onChange && this.props.onChange( this.state.setData )  // 親に渡す
  }
  // _______________________________________________________________________________________________________

  setJsonData =(row,name,value)=>{
    if(this.props.disabledAll) { return; };
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    setData_copy[row][name] = value  // true or false を反転
    this.setState({ setData : setData_copy })        // 変更後のデータをセット
    this.props.onChange && this.props.onChange( setData_copy　) // 親に渡す
  }
  // ________________________________________________________________________________________________________________

  setJsonDetail = ( row, name, cell, value )=>{
    if(this.props.disabledAll) { return; };
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    setData_copy[row][name][cell] = value           // 入力した値をセット
    this.setState({ setData : setData_copy })        // 変更後のデータをセット
    this.props.onChange && this.props.onChange( setData_copy　) // 親に渡す
    // console.log(setData_copy)
  } 
  // ________________________________________________________________________________________________________________
  
  excludedOnChange =( row, cell,)=> {
    let value = ( this.state.setData[row].excluded[cell] == '1' ) ? '0' : '1'; // チェックボックスの値
    this.setJsonDetail(row,"excluded",cell,value )
  }
  // ________________________________________________________________________________________________________________

  dataTypeOnChange = (row) => {
    let value = (this.state.setData[row].dataType == '1') ? '0' : '1'; // チェックボックスの値
    this.setJsonData( row, "dataType", value)
  }
  // ________________________________________________________________________________________________________________

  inputOnKeyDown=( i, j, e)=>{
    const { setData, fowardCol } = this.state;

    if( e.keyCode === 9 || e.keyCode === 13){
      e.preventDefault()
      if( fowardCol ){
        if( j === 9 ){
          for(let k = 1; k <= setData.length; k++){
            if(setData[i + 1] && setData[i + 1].dataType == '1' ){
              i += 1;
            }else{
              break;
            }
          }
          this.setState({ tabControl : Number( i + 1 ) + "0"})
        }else{
          this.setState({ tabControl : i +""+ Number( j + 1 )})
        }
      }
      else{
        for(let k = 1; k <= setData.length; k++){
          if(setData[i + 1] && setData[i + 1].dataType == '1' ){
            i += 1;
          }else{
            break;
          }
        }

        if( i === setData.length - 1 ){
          this.setState({ tabControl : "0" + Number( j + 1 ) })
        }else{
          this.setState({ tabControl : Number( i + 1 ) + "" + j })
        }
      }
    }
  }
  // ________________________________________________________________________________________________________________
  // ロットまたはコントロール項目と装置／号機のみコピーして、一行追加します
  handleCopyRow = (index) => {
    const { onChange, disabledAll} = this.props;
    const { setData, } = this.state;
    if(disabledAll) { return null} // 確認画面時は処理不可
    const setData_copy = setData.slice();     // テーブル全体をコピー
    const copyRow = Object.assign({}, setData_copy[index]) // 選択した行をコピー
    // コピーから除外する項目のリセット
    copyRow['reagentNo'] = '';
    copyRow['dataType'] = '';
    copyRow['mean'] = '';
    copyRow['sd'] = '';
    copyRow['ndate'] = '';
    copyRow['excluded'] = Array.from({length: 10}, () => '');
    copyRow['rawData'] = Array.from({length: 10}, () => null);

    setData_copy.splice(index + 1, 0, copyRow)    // テーブルにコピーした配列を追加（行を追加）
    this.setState({
      setData: setData_copy,
    })
    onChange && onChange(setData_copy); // 親に渡す
  }
  // ________________________________________________________________________________________________________________
  // 「行削除」ボタンを押した時
  handleDelRow = (index) => {
    const { onChange, disabledAll } = this.props;
    const { setData, } = this.state;
    if(disabledAll) { return null} // 確認画面時は処理不可
    const setData_copy = setData.slice();     // 配列をコピー
    setData_copy.splice(index, 1);            // spliceメソッドで配列を削除（行を削除）
    this.setState({
      setData: setData_copy,
    })
    onChange && onChange(setData_copy); // 親に渡す
  }
  // _______________________________________________________________________________________________________
  
  render(){

    // スタイル定義
    const{ color1, fontColor, minWidth, disabledAll } = this.props;

    const tableWrap_style = {   // <div> テーブルのwapper
      width:"100%",
      minWidth: minWidth,
      marginBottom:"16px",
    }
    const table_style = {            // <table> テーブル
      backgroundColor:"rgb(255,255,255)",
      width : "100%",
    }
    const th_style = {                      // <td> テーブルヘッダー
      border:"1px solid #ccc",
      backgroundColor:color1,
      fontSize:14,
      height:25,
      verticalAlign:"middle",
      color:fontColor,
      fontWeight:"bold",
      textAlign:"center",
    }

    const thAggregate_style = {
      border:"1px solid #ccc",
      backgroundColor:"rgb(43, 64, 108)",
      fontSize:14,
      height:25,
      verticalAlign:"middle",
      color:"#fff",
      fontWeight:"bold",
      textAlign:"center",
      minWidth:60,
      maxWidth : 100,
    }
    const tbody_style = {
      // overflow:"auto",
      // maxHeight:"500px",
    }

    const tabWrap_style = {
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      height:40,
    }
    const delete_style = {            // <button> 削除ボタン
      width:"100%",
      backgroundColor:"transparent",
      border:"none",
      color:"#c55",
      fontSize:25,
      fontWeight:"bolder",
      cursor:"pointer",
    }

    // _____________________________________________________________________________________________________

    const { keyName, Aggregate } = this.props
    const { setData, tabControl,fowardCol  } = this.state    
    let   trArr       = [];
    let   tdArr       = [];
    let   cellsItem   = null;    
    // _____________________________________________________________________________________________________

    for(let i in setData ){
      let cantEdit = true;
      if( setData[i].dataType === '1'){ cantEdit = false; }
      tdArr=[];
      // 「複写」列の挿入
      tdArr.push(
        <TD key={"copyCell" + i } icon onClick={()=>this.handleCopyRow(i)}>
          <img src={copyIcon} title='行を下にコピー' alt="copy"/>
        </TD>
      )
      // 各列の設定
      for( let j = 1; j<= 7; j++ ){
        switch (j) {
          case 1:{
            switch ( keyName ) {
              case "lotNo":{
                cellsItem = <span style={{paddingLeft:5}} >{setData[i].itemCode} {setData[i].itemNameShort}</span>
                break;
              }
              case "itemCode":{
                cellsItem = <span style={{paddingLeft:5}} >{setData[i].lotNo}</span>
                break;
              }
              default:
                cellsItem = <span style={{paddingLeft:5}} >keyName</span>
                break;
            }
            break;
          }
          case 2:{
            cellsItem = <span style={{paddingLeft:5}}>{setData[i].analyzerCode} {setData[i].userAnalyzerName}</span>
            break;
          }
          case 3:{
            cellsItem =<TableInput text value={setData[i].reagentNo} onChange={(value)=>this.setJsonData(i, "reagentNo" ,value) } disabled={disabledAll} />
            // cellsItem = <span style={{paddingLeft:5}}>{setData[i].reagentNo}</span>
            break;
          }
          case 4:{
            cellsItem = <CheckBox checked={setData[i].dataType} onClick={()=>this.dataTypeOnChange(i)} disabled={disabledAll}/>
            break;
          }
          case 5:{
            cellsItem =<TableInput value={setData[i].mean} onChange={(value)=>this.setJsonData(i, "mean" ,value) } disabled={cantEdit || disabledAll} />
            break;
          }
          case 6:{
            cellsItem =<TableInput value={setData[i].sd} onChange={(value)=>this.setJsonData(i, "sd" ,value) }  disabled={cantEdit || disabledAll} maxLength={128}/>
            break;
          }
          case 7:{
            cellsItem =<TableInput value={setData[i].ndate} onChange={ (value)=>this.setJsonData(i, "ndate" ,value) }  disabled={cantEdit || disabledAll} maxLength={128}/>
            break;
          }
          default:{
            break;
          }
        }
        tdArr.push(
          <TD key={"cell" + i + j }>{cellsItem}</TD>
        )
      }

      for( let j = 0 ; j < 10 ; j++ ){
        tdArr.push(
          <RawDataTD
            autoFocus   = { this.firstRender ? (i === '0' && j === 0 ): true }  // 初回のレンダーの時に、一番最初のセルでオートフォーカスを止める
            key         = { ( tabControl === i+""+j ) ? "focus" : "RawDataTD"+ i + j }  // keyを変えることで再レンダーをかけている（オートフォーカス発火）
            value       = { setData[i].rawData[j]  } 
            onFocus     = { ()=>this.setState({ tabControl : null })} // tabControlが現在のセル位置に設定されているので、onFocus時にリセット
            onKeyDown   = { (e)=>this.inputOnKeyDown( Number(i), Number(j), e) }
            onChange    = { (value)=>this.setJsonDetail(i,"rawData", j,value )} 
            disabled    = { setData[i].dataType =='1' || disabledAll }
            excluded    = { setData[i].excluded[j] == '1'} // その行の「手入力」チェックが入っているかどうか
            checked     = {setData[i].excluded[j]} 
            onCheck     = {()=>this.excludedOnChange(i,j)}
          />
        )
      }
      // コントロール項目ごとの場合、集計データを表示
      tdArr.push(
        <Fragment key={"sumCell" + i}>
          <TD><TableInput value={setData[i].meanAll} disabled/></TD>
          <TD><TableInput value={setData[i].sdAll} disabled/></TD>
          <TD><TableInput value={setData[i].ndateAll} disabled/></TD>
        </Fragment>
      )
      // 「行削除」列の挿入
      tdArr.push(
        <TD key={"delCell" + i } icon onClick={()=>this.handleDelRow(i)}>
          <span style={delete_style}>×</span>        
        </TD>
      )
      let evenRowColor = (i%2 === 0) ? null : 'rgb(255, 254, 240)';
      trArr.push( <tr key={"row" + i} style={{backgroundColor:evenRowColor}}>{tdArr}</tr> )
    }

    // ___________________________________________________________________________________________________________
    return(
      <div style={tableWrap_style}>
        <div style={ tabWrap_style } >
          <div> </div>
          <div>
            <TabButton label="生データの集計を行う" Aggregate color="rgb(43, 64, 108)" fontColor="#fff"  onClick={()=> Aggregate(setData)} disabled={disabledAll} />
            <span>※チェック中のデータは集計から除外されます</span>
          </div>
          <div>
            <span>Tabキーの移動方向</span>
            <TabButton label="→" color={color1} fontColor={fontColor} selected={  fowardCol } onClick={()=>this.setState({ fowardCol : true }) } disabled={disabledAll}/>
            <TabButton label="↓" color={color1} fontColor={fontColor} selected={ !fowardCol }  onClick={()=>this.setState({ fowardCol : false }) } disabled={disabledAll}/>
          </div>
        </div>
        <table style={table_style}>
          <thead>
            <tr>
              <th colSpan="4" style={ th_style }></th>
              <th colSpan="4" style={ thAggregate_style }>試薬ロット集計</th>
              <th colSpan="10" style={ th_style } >
                <span>生データ</span>
              </th>
              <th colSpan="3" style={ thAggregate_style }>装置ロット集計</th>
              <th style={ th_style }></th>
            </tr>
            <tr>
              <th style={ Object.assign({}, th_style, {minWidth:"36px"} ) }>複製</th>
              <th style={ Object.assign({}, th_style, {minWidth:"150px"} ) }>
                { 
                  keyName === "lotNo"    ? "コントロール項目" :
                  keyName === "itemCode" ? "ロット番号" :
                  "keyName違います"
                }
              </th>
              <th style={ Object.assign({}, th_style, {minWidth:"200px"} ) }>装置／号機</th>
              <th style={ Object.assign({}, th_style, {minWidth:"100px"} ) }>試薬ロット番号</th>
              <th style={ thAggregate_style }>手入力</th>
              <th style={ thAggregate_style }>平均</th>
              <th style={ thAggregate_style }>標準偏差</th>
              <th style={ thAggregate_style }>回数</th>
              <th style={ th_style }>1回目</th>
              <th style={ th_style }>2回目</th>
              <th style={ th_style }>3回目</th>
              <th style={ th_style }>4回目</th>
              <th style={ th_style }>5回目</th>
              <th style={ th_style }>6回目</th>
              <th style={ th_style }>7回目</th>
              <th style={ th_style }>8回目</th>
              <th style={ th_style }>9回目</th>
              <th style={ th_style }>10回目</th>
              <th style={ thAggregate_style }>平均</th>
              <th style={ thAggregate_style }>標準偏差</th>
              <th style={ thAggregate_style }>回数</th>
              <th style={ Object.assign({}, th_style, {minWidth:"36px"} ) }>削除</th>
            </tr>
          </thead>
          <tbody 
            style = { tbody_style } 
            onBlur= { this.getData }
          >
            {trArr}
          </tbody>
        </table>
      </div>

    );

  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class CheckBox extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      display : "flex",
      alignItems:"center",
      height:"100%",
      justifyContent:"center"
    }
    this.boxWrap_style = {
      display:"inline-flex",
      padding:0,
      border:"none",
      backgroundColor:"transparent",
      alignItems:"center",
      boxSizing:"border-box",
      fontSize:16,
    }
    this.box_style = {
      height:20,
      width:20,
      boxSizing:"border-box",
      borderRadius:5,
      borderStyle:"solid",
      borderWidth:"2px",
      borderColor:"#777",
      position:"relative",
    }
    this.checked_style = {
      position:"absolute",
      bottom:2,
      left:3,
      boxSizing:"border-box",
      height:15,
      width:10,
      borderRight: "4px solid white",
      borderBottom: "4px solid white",
      transform: "rotate(45deg)",
      borderRadius:5,
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { checked } = this.props;
    if(checked !== nextProps.checked){
      return true;
    }
    return true;
  }
  // ________________________________________________________________________________

  render() {
    const {
      disabled,
      checked,
      onClick,
    } = this.props

    Object.assign(this.box_style, { // <td> 各セル
      backgroundColor: 
        checked == '1' ? "#777" : 
        "white",
    });
    Object.assign(this.boxWrap_style, { 
      cursor:
        disabled ? null : 
        "pointer",
    });
    // ________________________________________________________________________________

    return (
      <div style={this.wrap_style} onClick={onClick} tabIndex={0} onKeyDown={(e)=>{ e.keyCode === 13 && onClick();}}>
        <div style={Object.assign({}, this.boxWrap_style)} disabled={disabled}>
          <div style={Object.assign({}, this.box_style)}>
            { checked == '1' && <div style = {this.checked_style}></div> }
          </div>
        </div>
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// テーブル用インプット
class TableInput extends React.Component{
  constructor(props){
    super(props);
    const { value, text } = this.props;
    this.state = {
      value: value || "",
    }
    this.text_style = { // <input> テキストボックスのスタイル
      width:"100%",
      border:"none",
      height:25,
      outline:"none",
      boxSizing:"border-box",
      padding:"0px 5px",
      textAlign: 
        text ? "left" : 
        "right", 
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if(value !== nextProps.value) {
      let replaceValue = !nextProps.value ? "" : nextProps.value
      this.setState({ value : replaceValue });
    }
    return true;
  }
  // ________________________________________________________________________________

  handleOnChange = (e) => {
    const { text } = this.props;
    // 試薬ロット番号の時はテキスト入力、それ以外は数値のみ入力可
    if(text){
      this.setState({ value : e.target.value })
    }else{
      let replaceValue = e.target.value.replace(/[^0-9-.]/g,'');
      this.setState({ value : replaceValue })
    }
  }
  // ________________________________________________________________________________

  handleOnBlur = (e) => {
    const { onChange, value } = this.props;
    let replaceValue = !value ? "" : value
    if(e.target.value !== replaceValue) {
      onChange && onChange(e.target.value);
    }
  }
  // ________________________________________________________________________________

  render(){
    
    const { 
      disabled, 
      onChange, 
      onFocus, 
      onKeyDown, 
      excluded,
      maxLength,
      text,
    } = this.props;

    const { value } = this.state;
    // console.log(value)

    Object.assign(this.text_style, { 
      backgroundColor:
        disabled ? "#eee" :
        excluded ? "#eee" : 
        "transparent",
    });
    // ____________________________________________________________________________________

    return(
      <input 
        type      = {text ? "text": "tel"}
        value     = { value }
        style     = {Object.assign({}, this.text_style)} 
        disabled  = { disabled }
        onChange  = { this.handleOnChange } 
        onBlur    = { (e) => { onChange && onChange( e.target.value )} }
        onFocus   = { onFocus }
        onKeyDown = { onKeyDown }
        maxLength = { maxLength }
      />
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// タブボタン
class TabButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________


  render(){
    const { label, color, fontColor, onClick, selected, Aggregate, disabled } = this.props;
    const { hover } = this.state;
    const button_style = {                          // <input> テキストボックスのスタイル
      height:25,
      border:"none",
      backgroundColor:
        Aggregate ? color :
        hover     ? color :
        selected  ? color :
        null,
        // color,
      width:
        Aggregate ? 200 :
        50,
      borderRadius:5,
      color:fontColor,
      fontSize:14,
      cursor:
        disabled ? null :
        "pointer",
      boxShadow : 
        hover ? "2px 2px 2px #ccc" :
        null,
      marginLeft:16,
    }
    // __________________________________________________________________________________________________________________

    return(
      <button
        style        = { button_style } 
        onClick      = { onClick }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
        disabled     = { disabled }
      >
      { label }  
      </button>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class TD extends React.Component{
  constructor(props){
    super(props);
    const { icon } = this.props;
    this.state = {
      hover : false,
      focus : false,
    }

    this.td_style = {
      textAlign: icon? 'center': "left",
      cursor: icon ? 'pointer' : null,
      verticalAlign:"middle",
      border:"1px solid #ccc",
      boxSizing:"border-box",
      transition:"0.2s",
    }
  }
  // ________________________________________________________________________________________________________________

  render(){
    const { style, onClick, children } = this.props 
    const { hover, focus, } = this.state           // 省略宣言

    Object.assign(this.td_style, {            // <td> 各セル
      boxShadow : 
        focus ? "0px 0px 2px 0px #00f inset" :
        hover ? "0px 0px 5px 0px #00f inset" :
        null,
      backgroundColor :
        focus ? "#eef" :
        null,
    });

    return(
      <td
        style        = {Object.assign({},this.td_style, style )} 
        onClick      = {onClick}
        onMouseOver  = {()=> this.setState({ hover : true  })}
        onMouseLeave = {()=> this.setState({ hover : false })}
        onFocus      = {()=> this.setState({ focus : true  })}
        onBlur       = {()=> this.setState({ focus : false })}
      >
        { children }
      </td>
    )
  }  
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// テーブル用インプット
class RawDataTD extends React.Component{
  constructor(props){
    super(props);
    const { value } = this.props;
    this.state = {
      value: value || "",
    }
    this.text_style = { // <input> テキストボックスのスタイル
      width:"100%",
      border:"none",
      height:25,
      outline:"none",
      boxSizing:"border-box",
      padding:"0px 5px",
      textAlign:"right", 
    }
    this.td_style = {
      textAlign:"left",
      verticalAlign:"middle",
      border:"1px solid #ccc",
      boxSizing:"border-box",
      transition:"0.2s",
      minWidth:80,
      maxWidth:100,
    }
    this.itemWrap_style = {
      display : 'inline-flex',
      alignItems : 'center',
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps, nextState) {
    const { value, checked, disabled, } = this.props;
    const { focus, hover } = this.state;
    if(value !== nextProps.value) {
      let fixedValue = !nextProps.value ? "" : nextProps.value;
      this.setState({ value : fixedValue });
      return true;
    }
    if(checked !== nextProps.checked){ return true; };
    if(disabled !== nextProps.disabled){ return true; };
    if(this.state.value !== nextState.value){ return true; };
    if(focus !== nextState.focus) { return true; };
    if(hover !== nextState.hover) { return true; };
    return false;
  }
  // ________________________________________________________________________________

  handleOnChange = (e) => {
    let replaceValue = e.target.value.replace(/[^0-9-.]/g,'');
    this.setState({ value : replaceValue })
  }
  // ________________________________________________________________________________

  textOnBlur = (e) => {
    const { onChange, value } = this.props;
    this.setState({ focus : false });
    let replaceValue = !value ? "" : value
    if(e.target.value !== replaceValue) {
      onChange && onChange(e.target.value);
    }
  }
  // ________________________________________________________________________________

  textOnFocus = () => {
    const { onFocus } = this.props;
    this.setState({ focus : true });
    onFocus && onFocus()
  }
  // ________________________________________________________________________________

  render(){    
    const { 
      disabled,
      onKeyDown, 
      tabIndex, 
      autoFocus, 
      excluded,
      onCheck,
      checked,
    } = this.props;

    const { hover, focus, value } = this.state           // 省略宣言

    Object.assign(this.text_style, { 
      backgroundColor:
        excluded ? "#eee" : 
        "transparent",
    });
    Object.assign(this.td_style, {            // <td> 各セル
      boxShadow : 
        disabled ? null :
        focus ? "0px 0px 2px 0px #00f inset" :
        hover ? "0px 0px 5px 0px #00f inset" :
        null,
      backgroundColor :
        disabled ? "#eee" :
        checked == '1' ? "#eee" :
        focus ? "#eef" :
        null,
    });
    // ____________________________________________________________________________________

    return(
      <td
        style        = {Object.assign({}, this.td_style)} 
        onMouseOver  = {()=> this.setState({ hover : true  })}
        onMouseLeave = {()=> this.setState({ hover : false })}
      >
        <label style={this.itemWrap_style}>
          <CheckBox 
            onClick    = {()=> !disabled && onCheck()}
            checked    = {checked}
            onFocus    = {()=> this.setState({ focus : true  })}
            onBlur     = {()=> this.setState({ focus : false })}
          />
          <input 
            autoFocus = {autoFocus}
            tabIndex  = {tabIndex}
            type      = {"tel"}
            value     = {value}
            style     = {Object.assign({}, this.text_style)} 
            disabled  = {disabled}
            onChange  = {this.handleOnChange} 
            onBlur    = {this.textOnBlur}
            onFocus   = {this.textOnFocus}
            onKeyDown = {onKeyDown}
            maxLength = {20}
          />
        </label>
      </td>
    )
  }
}