import React, {Fragment} from 'react'
import { Link } from "react-router-dom";
// 画像import文
import home from '../WQCImage/home.png';
import Book from '../WQCImage/Book.png';
import Support from '../WQCImage/Support.png';
import Gear from '../WQCImage/Gear.png';
import Padlock from '../WQCImage/Padlock.png';
import Graph from '../WQCImage/Graph.png';
import ToolBox from '../WQCImage/ToolBox.png';

export default class MenuComponent extends React.Component{
  constructor(props){
    super(props);
    let agent = window.navigator.userAgent.toLowerCase();
    let ie11 = (agent.indexOf('trident/7') !== -1);

    this.topJson = [{
      icon: 'home', 
      categoryName: "TOP" ,
      link: "/",
      menuName: "HOME",
    }]

    this.wrap_style = {
      whiteSpace: 'nowrap',
      width: '100%',
      height : 50,
      padding:"0px 8px",
      position: "fixed",
      display:"flex",
      zIndex: "899",
      alignItems:"center",
      top: 50,
      background : 
        ie11 ? "-ms-linear-gradient(bottom,  #ccc,  #eee)":
        "linear-gradient( #eee, 50%, #ccc)",
    }
  }
  render() {
    const { setData, cntSdeskNew } = this.props;
    const menuListArr = [];
    // console.log(setData)
    for(let i in setData){
      menuListArr.push(
        <Menu setData={setData[i]} key={"Menu"+i} cntSdeskNew={cntSdeskNew}/>
      )
    } 
    return(
      <div style={this.wrap_style}>
        <Menu setData={this.topJson}/>
        {menuListArr}
      </div>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Menu extends React.Component{
  constructor(props){
    super(props);
    const { icon } = this.props.setData[0]
    this.state = {
      hover : false, 
    }
    this.wrap_style = {
      position:"relative",
      display:"inline-flex",
      flexDirection:"column",
      padding:"0px 16px",
    }
    this.menuWrap_style = {
      display:"inline-flex",
      position:"relative",
      alignItems:"center",
      backgroundColor:"transparent",
      border : "none",
      height : 50,
    }
    this.menuIcon_style = {
      width : 35,
      height :35,
    }

    this.menuLabel_style = {
      fontWeight:"bolder",
      fontSize:16,
      paddingLeft:8,
      color : 
        icon == 'home' ? '#FF0000' :
        icon == 'Support' ? '#0000FF' :
        null,
    }

    this.messeWrap_style = {                             // <div> 吹き出しのWrap
      position:"absolute",
      top:-35,
      display:"flex",
      flexDirection:"column",
      // alignItems:"center",
      whiteSpace:"nowrap",
      zIndex:2,
      height:0, 
    }
    this.messeParts_style = {                            // <div> 吹き出し上部の三角形
      borderRight: "25px solid transparent",
      borderTop: "10px solid #e33",
      borderLeft: "0px solid transparent",
      width:0,
      position:"relative",
      left:30,
    }
    this.messeLabel_style = {                             // <div> 吹き出しの言葉
      padding:"8px 16px",
      backgroundColor:"#e33",
      borderRadius:5,
      fontSize:12,
      color:"#fff",
    }

    this.images = {
      home : home,
      Book : Book,
      Support : Support,
      Gear : Gear,
      Padlock : Padlock,
      Graph : Graph,
      ToolBox : ToolBox,
    }
    //　サポートデスクの通知バッチ
    this.cntSdeskNew_style = {
      position: "absolute",
      bottom: 0,
      left: -8,
      backgroundColor: "#F00",
      color: "#FFF",
      padding: 4,
      minHeight: 24,
      minWidth: 24,
      fontSize: 16,
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
    }
  }
  // _______________________________________________________________________________________________________

  
  render() {
    const { setData, cntSdeskNew } = this.props;
    const { icon, categoryName } = setData[0]
    const { hover } = this.state;

    return(
      <div style={this.wrap_style} 
        onMouseOver  = {()=>this.setState({ hover : true  })}
        onMouseLeave = {()=>this.setState({ hover : false })}
      >
        <button style={this.menuWrap_style}>
          {icon == 'Support' && cntSdeskNew > 0 && 
            <div style={this.cntSdeskNew_style}>{cntSdeskNew}</div>
          }
          <img src={this.images[icon]} style={this.menuIcon_style} alt="" />
          <div style={this.menuLabel_style}>
            {categoryName}
          </div>
        </button>

        {/* {massege &&
        <div style={this.messeWrap_style}>
          <div style={this.messeLabel_style}>
            新規{massege}件のメッセージがあります
          </div>
          <div style={this.messeParts_style}></div>
        </div>
        } */}
        <Submenu setData={setData} rendering={hover}/>
      </div>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Submenu extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      zIndex : 5,
      position:"absolute",
      top:"100%",
      display:"flex",
      flexDirection:"column",
      minWidth:"100%",
    }
    this.submenuWrap_style = {
      borderRadius:5,
      border: '#000 solid 1px',
      boxShadow: '0px 2px 2px 0px #cccccc',
      backgroundColor: '#FFF',
      display:"flex",
      flexDirection:"column",
      minWidth:"100%",
    }
    this.submenu_style = {
      textDecoration : 'none',
      whiteSpace:'nowrap',
      padding: '8px 24px',
      maxWidth: '100%',
      cursor: 'pointer',
      fontSize:13,
    }
  }

  render() {
    const { setData, rendering } = this.props;
    if(!rendering){ return null; };
    // _______________________________________________________________________________________________________

    const submenuArr = [];
    for(let i in setData){
      const { menuName, link } = setData[i];
      submenuArr.push( <SubmenuItem label={menuName} to={link} key={"SubmenuItem"+i}/> )
    }
    return(
      <div style={this.wrap_style}>
        <div style={this.submenuWrap_style}>
          {submenuArr}
        </div>
      </div>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class SubmenuItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false,
    }
    this.submenuItem_style = {
      textDecoration : 'none',
      whiteSpace:'nowrap',
      padding: '16px 24px',
      maxWidth: '100%',
      cursor: 'pointer',
      fontSize:13,
    }
  }

  render() {
    const { label, to } = this.props;
    const { hover } = this.state;

    // 状態によって変更されるスタイルの記述
    Object.assign(this.submenuItem_style,{
      backgroundColor : //　優先順位順に背景色を設定
        hover ? "#3faf7f" : 　
        null, 
      color : 
        hover ? '#FFF' :
        '#000',
    })
    // _______________________________________________________________________________________________________
    return(
      <Link
        style        = {Object.assign({},this.submenuItem_style)}
        to           = {to}
        onBlur       = {()=>this.setState({ hover : false })}
        onFocus      = {()=>this.setState({ hover : true  })}
        onMouseOver  = {()=>this.setState({ hover : true  })}
        onMouseLeave = {()=>this.setState({ hover : false })}  
      >
        {label}
      </Link>
    );
  }
}