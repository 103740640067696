import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as SKY from '../../component/ComComponent';
import * as WQC from "../WQCComponent/WQCComponent";
import Footer from '../WQCComponent/Footer';
import {login, error, logOut, introduce} from '../../comRedux/Actions';
import { history } from '../../comRedux/createStore';//追加
import welcome from '../WQCImage/welcome.jpg';

import {axiosPost,server} from '../../comRedux/axiosPost';

class Login extends React.Component{
  constructor (props) {
    super(props);
    this.state = {
      userCode:'',
      password:'',
      errMsg : null,
    }
  }
  //__________________________________________________________________________________
  componentDidMount(){
    this.loginFromMypage() // マイページからのログイン
  }
  //__________________________________________________________________________________
  
  // マイページからのログイン
  loginFromMypage = () => {
    // URLから、Token情報を取得
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const token = urlParams.get('token');
    const userCode = urlParams.get('userCode');
    const loginWithSurvey = urlParams.get('loginWithSurvey');
    console.log(token);
    console.log(loginWithSurvey);

    // Tokenがあれば処理
    if(token){
      // JavaへPostするためのJsonを作成
      let returnJson = {
        token:token,
        userCode:userCode,
        loginWithSurvey:loginWithSurvey,
     };
      axiosPost.post(server+'/loginWQC/loginToken',returnJson)
      .then((res) => {
        // ログイン成功時
        console.log(res.data);
        if (res.data === true) {
          this.props.dispatch(login());
        } else {
          // ログイン失敗時
          this.setState({errMsg : 'ログインできません'});
          this.props.dispatch(error('LOGIN','ログインできません'));
        }
      })
      .catch(err => {
        alert(err);
        console.log('JSON読み込みエラー')
          return
      })
    }
  }
  //__________________________________________________________________________________

  click_login = () =>{
    this.setState({errMsg : null});

    let returnJson = {
      userCode:this.state.userCode,
      password:this.state.password,
      loginWithSurvey:this.state.loginWithSurvey,
   };
    // this.props.dispatch(login());
    axiosPost.post(server+'/loginWQC/login',returnJson)
    .then((res) => {
      if (res.data==true) {
        this.props.dispatch(login());
      } else {
        this.setState({errMsg : 'ログインできません'});
        this.props.dispatch(error('LOGIN','ログインできません'));
      }
      //return res.data;
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
        return
    })
    
  }
  //__________________________________________________________________________________
  click_introduce = () => {
    this.props.dispatch(introduce());
  }
  //__________________________________________________________________________________
  // テキストボックスの内容が変更された時にstateを更新
  handleOnChangeValue = (name,value) => {
    this.setState({[name]:value});
  }
  //__________________________________________________________________________________
  render(){
    return(
      <Fragment>
      <SKY.Centering className="wqc_login_outer" >
        <SKY.Col w >
          <SKY.Low center className="wqc_login_title">WQC-web</SKY.Low>
          <SKY.Low center><p className="wqc_login_sentens">※本サイトは、富士フイルム和光試薬株式会社ユーザー様の<br />　会員専用サービスページです。</p></SKY.Low>
          <SKY.Low center>
            <SKY.Col className="wqc_login_inner" padding={32}>
              <WQC.InputArea errMsg={this.state.errMsg} style={{ backgroundColor:null,margin:null, padding:null }}>
                <SKY.Text label="ユーザーID" name="userCode" must="true" value={this.props.userCode} onChange={this.handleOnChangeValue} style={{width:"100%",margin:"0px 0px 16px 0px",}} autoFocus/>
                <SKY.Text label="パスワード" name="password" type="password" must="true" value={this.props.password} onChange={this.handleOnChangeValue} onEnter={this.click_login} style={{width:"100%",margin:"0px 0px 16px 0px",}}/>
                {/*<SKY.CheckBox label="サーベイユーザーとしてログイン" name="loginWithSurvey" value={this.props.loginWithSurvey} onChange={this.handleOnChangeValue} />*/}
                <SKY.Btn  label="ログイン" className="wqc_login_btn" onClick={this.click_login} />
              </WQC.InputArea>
              {/* <SKY.Low end margin={"0px 0px 20px 0px"}>
                <SKY.CheckBox label="ログイン情報を保存する" color="#375" className="wqc_login_check" />
              </SKY.Low> */}
              <SKY.Low center marginBottom={16}>
                <SKY.Link label='"パスワードを忘れた"または"新規登録"' onClick={this.click_introduce}/>
                {/* <SKY.Link label="新規登録" style={{ marginLeft:16 }}/> */}
              </SKY.Low>
              <SKY.Low center>
              <img src={welcome} alt='welcome'/>
              </SKY.Low>
            </SKY.Col>
          </SKY.Low>
        </SKY.Col>
      </SKY.Centering>
      <Footer />
      </Fragment>
    );
  }
  
}
const mapStateToProps = (state,ownPropS) =>{
  return {
    ...state.COMMON.Login
  }
}

export default connect(mapStateToProps)(Login);