import React, {Fragment} from 'react';
import Select from '../../component/Select';
import Low from '../../component/Low';
import Col from '../../component/Col';
import RichTextDisplay from './RichTextDisplay';
import AssistWord from './AssistWord';


export default class QCTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      cellHover   : [null,null] ,   // オンマウス中のセルの場所を保持して、スタイル適用するため
      cellFocus   : [null,null],    // フォーカス中のセルの場所を保持して、スタイル適用するため
      fowardCol   : true,
      setData     : this.props.setData || []
    }
    this.firstRender = true;
    this.datadate = "";
    this.lotNo = "";
    this.analyzerCode = "";
  }
  // ______________________________________________________________________________________________________
  
  getOptions = (setData) => {
    let datadateArr = [];
    let lotNoArr = [];
    let analyzerArr = [];
    this.datadate = "";
    this.lotNo = "";
    this.analyzerCode = "";
    this.datadateOptions = [];
    this.lotNoOptions = [];
    this.analyzerOptions = [];
    this.datadateOptions[0] = { value: "", label: "",};
    this.lotNoOptions[0] = { value: "", label: "",};
    this.analyzerOptions[0] = { value: "", label: "",};
    // データの抽出
    for(let i in setData){
      const { datadate, lotNo, analyzerCode, userAnalyzerName } = setData[i];
      if(datadateArr.indexOf(datadate) === -1){
        datadateArr += datadate;
        this.datadateOptions.push({ value: datadate, label: datadate,});
      }
      if(lotNoArr.indexOf(lotNo) === -1){
        lotNoArr += lotNo;
        this.lotNoOptions.push({ value: lotNo, label: lotNo,});
      }
      if(analyzerArr.indexOf(analyzerCode) === -1){ 
        analyzerArr += analyzerCode;
        let analyzerLabel = userAnalyzerName ? analyzerCode + " " + userAnalyzerName : analyzerCode
        this.analyzerOptions.push({ value: analyzerCode, label: analyzerLabel});
      }
    }
  }
  // ______________________________________________________________________________________________________

  componentDidMount(){
    this.firstRender = false;
    this.getOptions(this.props.setData);
  }
  // ______________________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if( this.props.setData !== nextProps.setData ){             //　親から渡されるsetDataが変化したら
      this.getOptions(nextProps.setData);
      this.setState({ setData : nextProps.setData }) // コピーした配列をセット
    }
    return true
  }
  // ______________________________________________________________________________________________________

  // フォーカスが外れた時
  
  getData =()=> {
    // console.log(this.state.setData);
    this.props.onChange && this.props.onChange( this.state.setData )  // 親に渡す
  }
  // _______________________________________________________________________________________________________

  setJsonData =(row,name,value)=>{
    if(this.props.disabledAll) { return; };
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    setData_copy[row][name] = value  // true or false を反転
    this.setState({ setData : setData_copy })        // 変更後のデータをセット
    this.props.onChange && this.props.onChange( setData_copy　) // 親に渡す
  }
  // ________________________________________________________________________________________________________________

  dataTypeOnChange = (row) => {
    let value = (this.state.setData[row].chkUpdate == '1') ? '0' : '1'; // チェックボックスの値
    this.setJsonData( row, "chkUpdate", value)
  }
  // ________________________________________________________________________________________________________________

  render(){

    // スタイル定義
    const{ color1, fontColor, minWidth, disabledAll } = this.props;

    const tableWrap_style = {   // <div> テーブルのwapper
      width:"100%",
      minWidth: minWidth,
      marginBottom:"16px",
    }
    const table_style = {            // <table> テーブル
      backgroundColor:"rgb(255,255,255)",
      width : "100%",
    }
    const th_style = {                      // <td> テーブルヘッダー
      border:"1px solid #ccc",
      backgroundColor:color1,
      fontSize:14,
      height:25,
      verticalAlign:"middle",
      color:fontColor,
      fontWeight:"bold",
      textAlign:"center",
    }
    const tbody_style = {
      // overflow:"auto",
      // maxHeight:"500px",
    }
    // _____________________________________________________________________________________________________

    const { setData,} = this.state    
    let   trArr       = [];
    let   tdArr       = [];
    let   cellsItem   = null;    
    // _____________________________________________________________________________________________________

    for(let i in setData ){
      tdArr=[];
      for( let j = 1; j<= 5; j++ ){
        switch (j) {
          case 1:{
            cellsItem = <CheckBox checked  = { setData[i].chkUpdate } color={color1} onClick={()=>this.dataTypeOnChange(i)} disabled={disabledAll}/>
            break;
          }
          case 2:{
            cellsItem = setData[i].regUser
            break;
          }
          case 3:{
            cellsItem = setData[i].regUserName
            break;
          }
          case 4:{
            cellsItem = setData[i].fileName
            break;
          }
          case 5:{
            cellsItem = <RichTextDisplay value={setData[i].formErrorMsg} />
            break;
          }
          default:{ break; }
        }
        tdArr.push(
          <TD key={"cell" + i + j } type={j}>{cellsItem}</TD>
        )
      }
      trArr.push( <tr key={"row" + i} >{tdArr}</tr> )
    }
    // ___________________________________________________________________________________________________________
    return(
      <div style={tableWrap_style}>
        <Col>
          <Low>
            <AssistWord label="更新対象のチェックで取込データの絞り込みが可能です"/>
          </Low>
         </Col>
        <table style={table_style}>
          <thead>
            <tr>
              <th style={ Object.assign({}, th_style, {minWidth:"30px"} ) }>更新対象</th>
              <th style={ Object.assign({}, th_style, {minWidth:"90px"} ) }>施設</th>
              <th style={ Object.assign({}, th_style, {minWidth:"150px"} ) }>施設名</th>
              <th style={ Object.assign({}, th_style, {minWidth:"100px"} ) }>ファイル名</th>
              <th style={ Object.assign({}, th_style, {minWidth:"100px"} ) }>エラーメッセージ</th>
            </tr>
          </thead>
          <tbody 
            style = { tbody_style } 
            onBlur= { this.getData }
          >
            {trArr}
          </tbody>
        </table>
      </div>

    );

  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class CheckBox extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {
      display : "flex",
      alignItems:"center",
      height:"100%",
      justifyContent:"center"
    }
    this.boxWrap_style = {
      display:"inline-flex",
      padding:0,
      border:"none",
      backgroundColor:"transparent",
      alignItems:"center",
      boxSizing:"border-box",
      fontSize:16,
    }
    this.box_style = {
      height:20,
      width:20,
      boxSizing:"border-box",
      borderRadius:5,
      borderStyle:"solid",
      borderWidth:"2px",
      borderColor:"#777",
      position:"relative",
    }
    this.checked_style = {
      position:"absolute",
      bottom:2,
      left:3,
      boxSizing:"border-box",
      height:15,
      width:10,
      borderRight: "4px solid white",
      borderBottom: "4px solid white",
      transform: "rotate(45deg)",
      borderRadius:5,
    }

  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    const { checked } = this.props;
    if(checked !== nextProps.checked){
      return true;
    }
    return true;
  }
  // ________________________________________________________________________________

  render() {
    const {
      disabled,
      checked,
      onClick,
    } = this.props

    if(checked === null){return null}
    Object.assign(this.box_style, { // <td> 各セル
      backgroundColor: 
        checked == '1' ? "#777" : 
        "white",
    });
    Object.assign(this.boxWrap_style, { 
      cursor:
        disabled ? null : 
        "pointer",
    });
    // ________________________________________________________________________________

    return (
      <div style={this.wrap_style} onClick={onClick} tabIndex={0} onKeyDown={(e)=>{ e.keyCode === 13 && onClick();}}>
        <div style={Object.assign({}, this.boxWrap_style)} disabled={disabled}>
          <div style={Object.assign({}, this.box_style)}>
            { checked == '1' && <div style = {this.checked_style}></div> }
          </div>
        </div>
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// テーブル用インプット

class TD extends React.Component{
  constructor(props){
    super(props);
    const { type } = this.props 

    this.td_style = {
      padding : "0px 5px",
      verticalAlign:"middle",
      border:"1px solid #ccc",
      boxSizing:"border-box",
      transition:"0.2s",

    }
  }
  // ________________________________________________________________________________________________________________

  render(){
    const { style, children } = this.props 


    return(
      <td
        style = {Object.assign({},this.td_style, style )} 
      >
        { children }
      </td>
    )
  }  
}

