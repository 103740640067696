/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React,{Fragment} from 'react';

export default class Icon extends React.Component{
  constructor(props){
    super(props);
    const { w, h } = this.props
    this.state = {
      onmouse: false,
    }
    
    this.wrap_style = {               // <div> アイコンのwrap 
      position:"relative",
      display:"inline-flex",
      justifyContent:"center",
      flexDirection:"column",
      alignItems:"center",
    }
    
    this.icon_style = {               // <button> アイコン外枠のボタン
      backgroundColor:"transparent", 
      border:"none", 
      cursor:"pointer",
      width : 
        w ? w :
        25,
      height : 
        h ? h :
        25,
      // outline:"none",
    }

    this.balloonWrap_style = {        // <div> 吹き出しのWrap
      position:"absolute",
      top:"100%",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      whiteSpace:"nowrap",
      zIndex:2,
      height:0,
      width:0,
    }
    this.balloonParts_style = {        // <div> 吹き出し上部の三角形
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #000",
      borderLeft: "10px solid transparent",
    }
    this.balloonTitle_style = {        // <div> 吹き出しの言葉
      padding:8,
      backgroundColor:"black",
      borderRadius:5,
      fontSize:12,
      color:"#fff",
    }
  }
  // ____________________________________________________________________________________________________

  onMouseOver =()=>{
    const { onMouseOver } = this.props;
    this.setState({onmouse:true});
    onMouseOver && onMouseOver();
  }
  // ____________________________________________________________________________________________________


  onMouseLeave =()=>{
    const { onMouseLeave } = this.props;
    this.setState({onmouse:false});
    onMouseLeave && onMouseLeave();
  }
  // ____________________________________________________________________________________________________

  render(){
    const { 
      onBlur,
      onFocus,
      label,
      src,
      onClick,
      style,
    } = this.props;
    const { onmouse } = this.state;
    return(   
      <div style={this.wrap_style}>
        <a tabIndex="0" onFocus={onFocus} onBlur={onBlur}>
          <img
            src={src}
            style={Object.assign({}, this.icon_style, style)} 
            onClick={onClick}
            onMouseOver={this.onMouseOver}
            onMouseLeave={this.onMouseLeave}
          />
        </a>

        {/* ※１　親で label の引数が設定されていて、かつマウスがアイコン上にある時にレンダーします */}
        {label && onmouse && 
        <div style={this.balloonWrap_style}>
          <div style={this.balloonParts_style}></div>
          <div style={this.balloonTitle_style}>
            {label}
          </div>
        </div>
        }   
        {/* _______________________________________________________________________________________*/}
      </div>
    );
  }
}