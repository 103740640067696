import React, {Fragment} from 'react';
import Select from '../../component/Select';
import Low from '../../component/Low';
import Col from '../../component/Col';
import AssistWord from './AssistWord';


export default class QCTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      cellHover   : [null,null] ,   // オンマウス中のセルの場所を保持して、スタイル適用するため
      cellFocus   : [null,null],    // フォーカス中のセルの場所を保持して、スタイル適用するため
      fowardCol   : true,
      setData     : this.props.setData || []
    }
    this.datadate = "";
    this.lotNo = "";
    this.analyzerCode = "";
  }
  // ______________________________________________________________________________________________________
  
  getOptions = (setData) => {
    let datadateArr = [];
    let lotNoArr = [];
    let analyzerArr = [];
    this.datadate = "";
    this.lotNo = "";
    this.analyzerCode = "";
    this.datadateOptions = [];
    this.lotNoOptions = [];
    this.analyzerOptions = [];
    this.datadateOptions[0] = { value: "", label: "",};
    this.lotNoOptions[0] = { value: "", label: "",};
    this.analyzerOptions[0] = { value: "", label: "",};
    // データの抽出
    for(let i in setData){
      const { datadate, lotNo, analyzerCode, userAnalyzerName } = setData[i];
      if(datadateArr.indexOf(datadate) === -1){
        datadateArr += datadate;
        this.datadateOptions.push({ value: datadate, label: datadate,});
      }
      if(lotNoArr.indexOf(lotNo) === -1){
        lotNoArr += lotNo;
        this.lotNoOptions.push({ value: lotNo, label: lotNo,});
      }
      if(analyzerArr.indexOf(analyzerCode) === -1){ 
        analyzerArr += analyzerCode;
        let analyzerLabel = userAnalyzerName ? analyzerCode + " " + userAnalyzerName : analyzerCode
        this.analyzerOptions.push({ value: analyzerCode, label: analyzerLabel});
      }
    }
  }
  // ______________________________________________________________________________________________________

  componentDidMount(){
    this.getOptions(this.props.setData);
  }
  // ______________________________________________________________________________________________________

  shouldComponentUpdate(nextProps) {
    if( this.props.setData !== nextProps.setData ){             //　親から渡されるsetDataが変化したら
      this.getOptions(nextProps.setData);
      this.setState({ setData : nextProps.setData }) // コピーした配列をセット
    }
    return true
  }
  // ______________________________________________________________________________________________________

  // フォーカスが外れた時
  
  getData =()=> {
    this.props.onChange && this.props.onChange( this.state.setData )  // 親に渡す
  }
  // _______________________________________________________________________________________________________

  setJsonDetail = ( row, name, cell, value )=>{
    if(this.props.disabledAll) { return; };
    const setData_copy = this.state.setData.slice();            // 配列をコピー
    setData_copy[row][name][cell] = value           // 入力した値をセット
    this.setState({ setData : setData_copy })        // 変更後のデータをセット
    this.props.onChange && this.props.onChange( setData_copy　) // 親に渡す
    console.log(setData_copy)
  } 
  // ________________________________________________________________________________________________________________
    handleOnChangeSelect = (name,value) => {
    if(name === "datadate"){ this.datadate = value; }
    if(name === "lotNo"){ this.lotNo = value; }
    if(name === "analyzerCode"){ this.analyzerCode = value; }
    // const { setData } = this.props;
    let filterdData = this.props.setData;
    // データ日付フィルター
    filterdData = filterdData.filter( filterdData  => {
      return( filterdData['datadate'].search(this.datadate) !== -1);
    })
    // ロット番号フィルター 
    filterdData = filterdData.filter( filterdData  => {
      return( filterdData['lotNo'].search(this.lotNo) !== -1 );
    })
    // 装置フィルター
    filterdData = filterdData.filter( filterdData  => {
      return( filterdData['analyzerCode'].search(this.analyzerCode) !== -1);
    })
    this.setState({ setData : filterdData })          // 絞り込み後のデータをセット
  }
  // ________________________________________________________________________________________________________________
  handleOnTabKeyDown = (e) =>{
    const id = e.target.id
    if(!id && e.keyCode !== 9){return}; // tabキー以外なら処理をしない
    
    const {fowardCol, setData} = this.state;
    
    e.preventDefault(); // デフォルトのTab移動機能を停止
    
    const idName = id.split('-')[0] // "QCTable"を切出し
    const rowNo = id.split('-')[1]
    const colNo = id.split('-')[2]
    let nextColNo = colNo;
    let nextRowNo = rowNo;

    if(fowardCol){
      if(colNo === "9"){
        nextColNo = "0";
        nextRowNo = Number(rowNo) + 1;
      }else{
        nextColNo = Number(colNo) + 1;
      }
      console.log(nextColNo,nextRowNo)
    }else{
      if(rowNo == setData.length-1){
        nextRowNo = "0";
        nextColNo = Number(colNo) + 1;
      }else{
        nextRowNo = Number(rowNo) + 1;
      }
    }
    if(colNo === "9" && rowNo == setData.length-1){
      nextColNo = "0";
      nextRowNo = "0";
    }
    const nextTarget = document.getElementById(idName+"-"+nextRowNo+"-"+nextColNo);  
    nextTarget && nextTarget.focus();
  }
  render(){

    // スタイル定義
    const{ color1, fontColor, minWidth, disabledAll } = this.props;

    const tableWrap_style = {   // <div> テーブルのwapper
      width:"100%",
      minWidth: minWidth,
      marginBottom:"16px",
    }
    const table_style = {            // <table> テーブル
      backgroundColor:"rgb(255,255,255)",
      width : "100%",
    }
    const th_style = {                      // <td> テーブルヘッダー
      border:"1px solid #ccc",
      backgroundColor:color1,
      fontSize:14,
      height:25,
      verticalAlign:"middle",
      color:fontColor,
      fontWeight:"bold",
      textAlign:"center",
    }

    const thAggregate_style = {
      border:"1px solid #ccc",
      backgroundColor:"rgb(43, 64, 108)",
      fontSize:14,
      height:25,
      verticalAlign:"middle",
      color:"#fff",
      fontWeight:"bold",
      textAlign:"center",
    }
    const tbody_style = {
      // overflow:"auto",
      // maxHeight:"500px",
    }

    const tabWrap_style = {
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      height:40,
    }

    // _____________________________________________________________________________________________________

    const { Aggregate } = this.props
    const { setData, fowardCol  } = this.state    
    let   trArr       = [];
    // _____________________________________________________________________________________________________

    for(let i in setData ){
      trArr.push(
        <TR 
          key={"TR" + i} 
          setData={setData[i]}
          index={i}
          disabled={disabledAll}
          onChange = {this.setJsonDetail}
        />
      )
    }
    // ___________________________________________________________________________________________________________
    return(
      <div style={tableWrap_style}>
        <Col>
          <Low>
            <AssistWord label="下記条件で取込データの絞り込みが可能です"/>
          </Low>
          <Low>
            <Select label="データ日付" name="datadate" value={this.datadate} setData={this.datadateOptions} onChange={this.handleOnChangeSelect} disabled={disabledAll}/>
            <Select label="ロット番号" name="lotNo" value={this.lotNo} setData={this.lotNoOptions} onChange={this.handleOnChangeSelect} disabled={disabledAll}/>
            <Select label="装置／号機" size="LL" name="analyzerCode" value={this.analyzerCode} setData={this.analyzerOptions} onChange={this.handleOnChangeSelect} disabled={disabledAll}/>
          </Low>  
         </Col>
        <div style={ tabWrap_style } >
          <div> </div>
          <div>
            <TabButton label="生データの集計を行う"　Aggregate color="rgb(43, 64, 108)" fontColor="#fff"  onClick={Aggregate} disabled={disabledAll} />
            <span>※チェック中のデータは集計から除外されます</span>
          </div>
          <div>
            <span>Tabキーの移動方向</span>
            <TabButton label="→"　color={color1} fontColor={fontColor} selected={  fowardCol } onClick={()=>this.setState({ fowardCol : true }) } disabled={disabledAll}/>
            <TabButton label="↓"　color={color1} fontColor={fontColor} selected={ !fowardCol }  onClick={()=>this.setState({ fowardCol : false }) } disabled={disabledAll}/>
          </div>
        </div>
        <table style={table_style}>
          <thead>
            <tr>
              <th style={ Object.assign({}, th_style, {minWidth:"100px"} ) }>データ日付</th>
              <th style={ Object.assign({}, th_style, {minWidth:"100px"} ) }>装置/号機</th>
              <th style={ Object.assign({}, th_style, {minWidth:"80px"} ) }>ロット番号</th>
              <th style={ Object.assign({}, th_style, {minWidth:"100px"} ) }>コントロール項目</th>
              <th style={ Object.assign({}, th_style, {minWidth:"90px"} ) }>試薬ロット</th>
              <th style={ thAggregate_style }>平均</th>
              <th style={ thAggregate_style }>標準偏差</th>
              <th style={ thAggregate_style }>回数</th>
              <th style={ th_style }>1回目</th>
              <th style={ th_style }>2回目</th>
              <th style={ th_style }>3回目</th>
              <th style={ th_style }>4回目</th>
              <th style={ th_style }>5回目</th>
              <th style={ th_style }>6回目</th>
              <th style={ th_style }>7回目</th>
              <th style={ th_style }>8回目</th>
              <th style={ th_style }>9回目</th>
              <th style={ th_style }>10回目</th>
              <th style={ thAggregate_style }>平均</th>
              <th style={ thAggregate_style }>標準偏差</th>
              <th style={ thAggregate_style }>回数</th>
            </tr>
          </thead>
          <tbody 
            style = { tbody_style } 
            onBlur= { this.getData }
            onKeyDown = {this.handleOnTabKeyDown}
          >
            {trArr}
          </tbody>
        </table>
      </div>

    );

  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class TR extends React.Component{
  constructor(props){
    super(props);
    this.td_style = {
      textAlign:"left",
      padding : "0px 5px",
      verticalAlign:"middle",
      border:"1px solid #ccc",
    }
  }
  render(){
    const { index, onChange, disabled } = this.props;
    const {
      datadate,
      userAnalyzerName,
      analyzerCode,
      lotNo,
      reagentNo,
      itemName,
      itemCode,
      mean,
      sd,
      ndate,
      meanAll,
      sdAll,
      ndateAll,
      rawData = [9],
      excluded = [9],
    } = this.props.setData;
    const tr_style = {
      backgroundColor: index % 2 === 0 ? null : 'rgb(255, 254, 240)',
    }
    return(
      <tr style={tr_style}>
        <td style={this.td_style}>{datadate}</td>
        <td style={this.td_style}>{userAnalyzerName? analyzerCode + ' ' + userAnalyzerName: analyzerCode}</td>
        <td style={this.td_style}>{lotNo}</td>
        <td style={this.td_style}>{itemName ? itemCode + ' ' + itemName: itemCode}</td>
        <td style={this.td_style}>{reagentNo}</td>
        <td style={Object.assign({},this.td_style, {textAlign:'right'})}>{mean}</td>
        <td style={Object.assign({},this.td_style, {textAlign:'right'})}>{sd}</td>
        <td style={Object.assign({},this.td_style, {textAlign:'right'})}>{ndate}</td>
        <RawDataTD
          id = {"QCTable-" + index + "-0"}
          autoFocus={index == 0}
          disabled={disabled}
          value={rawData[0]} 
          checked={excluded[0]} 
          onCheck={(value)=>onChange(index,"excluded",0,value)}
          onChange={(value)=>onChange(index,"rawData",0,value)}
        />
        <RawDataTD 
          id = {"QCTable-" + index + "-1"}
          disabled={disabled}
          value={rawData[1]} 
          checked={excluded[1]} 
          onCheck={(value)=>onChange(index,"excluded",1,value)}
          onChange={(value)=>onChange(index,"rawData",1,value)}
        />
        <RawDataTD 
          id = {"QCTable-" + index + "-2"}
          disabled={disabled}
          value={rawData[2]}
          checked={excluded[2]} 
          onCheck={(value)=>onChange(index,"excluded",2,value)}
          onChange={(value)=>onChange(index,"rawData",2,value)}          
        />
        <RawDataTD
          id = {"QCTable-" + index + "-3"}
          disabled={disabled}
          value={rawData[3]}
          checked={excluded[3]} 
          onCheck={(value)=>onChange(index,"excluded",3,value)}
          onChange={(value)=>onChange(index,"rawData",3,value)}                    
        />
        <RawDataTD
          id = {"QCTable-" + index + "-4"}
          disabled={disabled}
          value={rawData[4]}
          checked={excluded[4]} 
          onCheck={(value)=>onChange(index,"excluded",4,value)}
          onChange={(value)=>onChange(index,"rawData",4,value)}                    
        />
        <RawDataTD
          id = {"QCTable-" + index + "-5"}
          disabled={disabled}
          value={rawData[5]}
          checked={excluded[5]} 
          onCheck={(value)=>onChange(index,"excluded",5,value)}
          onChange={(value)=>onChange(index,"rawData",5,value)}                    
        />
        <RawDataTD
          id = {"QCTable-" + index + "-6"}
          disabled={disabled}
          value={rawData[6]}
          checked={excluded[6]} 
          onCheck={(value)=>onChange(index,"excluded",6,value)}
          onChange={(value)=>onChange(index,"rawData",6,value)}                    
        />
        <RawDataTD
          id = {"QCTable-" + index + "-7"}
          disabled={disabled}
          value={rawData[7]}
          checked={excluded[7]} 
          onCheck={(value)=>onChange(index,"excluded",7,value)}
          onChange={(value)=>onChange(index,"rawData",7,value)}                    
        />
        <RawDataTD
          id = {"QCTable-" + index + "-8"}
          disabled={disabled}
          value={rawData[8]}
          checked={excluded[8]} 
          onCheck={(value)=>onChange(index,"excluded",8,value)}
          onChange={(value)=>onChange(index,"rawData",8,value)}                    
        />
        <RawDataTD
          id = {"QCTable-" + index + "-9"}
          disabled={disabled}
          value={rawData[9]}
          checked={excluded[9]} 
          onCheck={(value)=>onChange(index,"excluded",9,value)}
          onChange={(value)=>onChange(index,"rawData",9,value)}                    
        />
        <td style={Object.assign({},this.td_style, {textAlign:'right'})}>{meanAll}</td>
        <td style={Object.assign({},this.td_style, {textAlign:'right'})}>{sdAll}</td>
        <td style={Object.assign({},this.td_style, {textAlign:'right'})}>{ndateAll}</td>
      </tr>
    )
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class CheckBox extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      checked : this.props.checked,
    }
    this.wrap_style = {
      display : "flex",
      alignItems:"center",
      height:"100%",
      justifyContent:"center"
    }
    this.boxWrap_style = {
      display:"inline-flex",
      padding:0,
      border:"none",
      backgroundColor:"transparent",
      alignItems:"center",
      boxSizing:"border-box",
      fontSize:16,
    }
    this.box_style = {
      height:20,
      width:20,
      boxSizing:"border-box",
      borderRadius:5,
      borderStyle:"solid",
      borderWidth:"2px",
      borderColor:"#777",
      position:"relative",
    }
    this.checked_style = {
      position:"absolute",
      bottom:2,
      left:3,
      boxSizing:"border-box",
      height:15,
      width:10,
      borderRight: "4px solid white",
      borderBottom: "4px solid white",
      transform: "rotate(45deg)",
      borderRadius:5,
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps,nextState) {
    if(this.props.checked !== nextProps.checked){ 
      this.setState({checked: nextProps.checked})
      return true;
    }
    if(this.state.checked !== nextState.checked){ return true;}
    return false;
  }
  // ________________________________________________________________________________
  handleOnClick = () =>{
    const {checked} = this.state;
    const {onChange, disabled} = this.props;
    if(disabled){return};
    const nextChecked = checked == '1' ? '0' : '1';
    this.setState({checked: nextChecked})
    onChange && onChange(nextChecked)
  }
  // ________________________________________________________________________________
  render() {
    const { disabled} = this.props;
    const {checked} = this.state;

    Object.assign(this.box_style, { // <td> 各セル
      backgroundColor: 
        checked == '1' ? "#777" : 
        "white",
    });
    Object.assign(this.boxWrap_style, { 
      cursor:
        disabled ? null : 
        "pointer",
    });
    // ________________________________________________________________________________

    return (
      <div style={this.wrap_style} onClick={this.handleOnClick} tabIndex={0} onKeyDown={(e)=>{ e.keyCode === 13 && this.handleOnClick();}}>
        <div style={Object.assign({}, this.boxWrap_style)} disabled={disabled}>
          <div style={Object.assign({}, this.box_style)}>
            { checked == '1' && <div style = {this.checked_style}></div> }
          </div>
        </div>
      </div>
    );
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// タブボタン
class TabButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________


  render(){
    const { label, color, fontColor, onClick, selected, Aggregate, disabled } = this.props;
    const { hover } = this.state;
    const button_style = {                          // <input> テキストボックスのスタイル
      height:25,
      border:"none",
      backgroundColor:
        Aggregate ? color :
        hover     ? color :
        selected  ? color :
        null,
        // color,
      width:
        Aggregate ? 200 :
        50,
      borderRadius:5,
      color:fontColor,
      fontSize:14,
      cursor:
        disabled ? null :
        "pointer",
      boxShadow : 
        hover ? "2px 2px 2px #ccc" :
        null,
      marginLeft:16,
    }
    // __________________________________________________________________________________________________________________

    return(
      <button
        style        = { button_style } 
        onClick      = { onClick }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
        disabled     = { disabled }
      >
      { label }  
      </button>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// テーブル用インプット
class RawDataTD extends React.Component{
  constructor(props){
    super(props);
    const { value } = this.props;
    this.state = {
      value: value || "",
    }
    this.text_style = { // <input> テキストボックスのスタイル
      width:"100%",
      border:"none",
      height:25,
      outline:"none",
      boxSizing:"border-box",
      padding:"0px 5px",
      textAlign:"right", 
    }
    this.td_style = {
      textAlign:"left",
      verticalAlign:"middle",
      border:"1px solid #ccc",
      boxSizing:"border-box",
      transition:"0.2s",
      minWidth:80,
      maxWidth:100,
    }
    this.itemWrap_style = {
      display : 'inline-flex',
      alignItems : 'center',
    }
  }
  // ________________________________________________________________________________

  shouldComponentUpdate(nextProps, nextState) {
    const { value, checked, disabled, } = this.props;
    const { focus, hover } = this.state;
    if(value !== nextProps.value) {
      let fixedValue = !nextProps.value ? "" : nextProps.value;
      this.setState({ value : fixedValue });
      return true;
    }
    if(checked !== nextProps.checked){ return true; };
    if(disabled !== nextProps.disabled){ return true; };
    if(this.state.value !== nextState.value){ return true; };
    if(focus !== nextState.focus) { return true; };
    if(hover !== nextState.hover) { return true; };
    return false;
  }
  // ________________________________________________________________________________

  handleOnChange = (e) => {
    let replaceValue = e.target.value.replace(/[^0-9-.]/g,'');
    this.setState({ value : replaceValue })
  }
  // ________________________________________________________________________________

  textOnBlur = (e) => {
    const { onChange, value } = this.props;
    this.setState({ focus : false });
    let replaceValue = !value ? "" : value
    if(e.target.value !== replaceValue) {
      onChange && onChange(e.target.value);
    }
  }
  // ________________________________________________________________________________

  textOnFocus = () => {
    const { onFocus } = this.props;
    this.setState({ focus : true });
    onFocus && onFocus()
  }
  // ________________________________________________________________________________

  render(){    
    const { 
      disabled,
      autoFocus, 
      onCheck,
      checked,
      id,
    } = this.props;

    const { hover, focus, value } = this.state           // 省略宣言

    Object.assign(this.text_style, { 
      backgroundColor:
        checked == '1' ? "#eee" :
        "transparent",
    });
    Object.assign(this.td_style, {            // <td> 各セル
      boxShadow : 
        disabled ? null :
        focus ? "0px 0px 2px 0px #00f inset" :
        hover ? "0px 0px 5px 0px #00f inset" :
        null,
      backgroundColor :
        disabled ? "#eee" :
        checked == '1' ? "#eee" :
        focus ? "#eef" :
        null,
    });
    // ____________________________________________________________________________________

    return(
      <td
        style        = {Object.assign({}, this.td_style)} 
        onMouseOver  = {()=> this.setState({ hover : true  })}
        onMouseLeave = {()=> this.setState({ hover : false })}
      >
        <label style={this.itemWrap_style}>
          <CheckBox
            onChange   = {onCheck}
            checked    = {checked}
            disabled   = {disabled}
            onFocus    = {()=> this.setState({ focus : true  })}
            onBlur     = {()=> this.setState({ focus : false })}
          />
          <input
            id        = {id}
            autoFocus = {autoFocus}
            type      = {"tel"}
            value     = {value}
            style     = {Object.assign({}, this.text_style)} 
            disabled  = {disabled}
            onChange  = {this.handleOnChange} 
            onBlur    = {this.textOnBlur}
            onFocus   = {this.textOnFocus}
            maxLength = {20}
          />
        </label>
      </td>
    )
  }
}