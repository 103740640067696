import React from 'react';

export default class AssistWord extends React.Component{
  render(){
    const { label, hidden } = this.props
    if(!label || hidden){ return null};
    const word_style = {
      color : "#01906D",
      fontWeight : "bold",
      paddingBottom : 8,
      marginRight:8,
    }

    return(
      <p style={word_style}>{label}</p>
    )
  }
}