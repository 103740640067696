import React from 'react'
import * as WQC from "../WQCComponent/WQCComponent";
import * as SKY from "../../component/ComComponent";

export default class PR001 extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <WQC.Body>
        <WQC.Title noDetail noBtn>本システムについて</WQC.Title>
        <WQC.PRParagram>
          <p>WQC-webをご利用いただきましてありがとうございます。</p>
          <p>現在は最小限の機能しか備えていませんが、今後いっそうの充実をはかっていく予定です。</p>
          <p>本システムについてご意見、ご要望などがございましたら、遠慮なくご連絡ください。</p>
        </WQC.PRParagram>
        {/* <h1><WQC.AssistWord label='本システムでご提供する機能は、以下の通りです。'/></h1> */}

        <WQC.PRParagram label="１．精度管理システム">
          <p>以下の精度管理システムがご利用できます。</p>
          <p>１）内部精度管理システム</p>
          <p>２）外部精度管理システム</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="２．サポートデスク">
          <p>双方向に連絡が可能なシステムです。迅速な問題解決にご利用いただけます。</p>
        </WQC.PRParagram>

        <WQC.PRParagram label="お問い合わせ先">
          <p>富士フイルム和光純薬株式会社</p>
          <p>臨床検査薬　カスタマーサポートセンター</p>
          <p>東京都中央区日本橋本町2-4-1</p>
          <p>TEL　03-3270-9134</p>
        </WQC.PRParagram>
      </WQC.Body>
    )
  }
}