import React, { Fragment } from 'react';

export default class Tilde extends React.Component{
  render(){
    const {paddingBottom} = this.props
    const tilde_style = {
      marginRight:16,
      marginButtom:16,
      fontSize:16,
      fontWeight:"bold",
      paddingBottom:paddingBottom
    }

    return(
      <Fragment>
        <div style={ tilde_style }>
          ～
        </div>
      </Fragment>
    )
  }
}