import React, {Fragment} from 'react'

export default class Logo extends React.Component{
  render(){ 
    const { label } = this.props
    const logo = {
      fontSize: "35px",
      color:"#fff",
      padding:"4px",
    }
    return(
      <h1 style={logo}>{ label }</h1>
    )
  }
}