import React, { Fragment } from 'react';

export default class PopupContents extends React.Component{

  render(){
    const { children, onClick } = this.props

    const wrap ={
      margin:'16px 0px 16px 0px',
      display: "flex",
      flexDirection:'column',
      width:"100%",  
    }

    const titleArea = {
      display: "inline-flex",
      justifyContent:"space-between",
      alignItems: "center",
      paddingLeft:8,
      marginBottom:16,
      height: 30,
      borderLeft:"10px solid #3faf7f",
    }

    const title_style = {
      fontWeight: "bolder",
      fontSize: 16,
      color:"#333",
    } 

    const inputArea = {
      backgroundColor: "#eee",
      padding:"16px 16px 0px 16px",
      borderRadius:5,
      width:"100%",
      display:"flex",
      alignItems: "flex-end",
    }

    // ______________________________________________________________________________
    
    return(
      <div style={wrap}>
        <div style={ titleArea }>
          <span style={ title_style }>{this.props.label}（参照ページ）</span>
          <BackBtn onClick={onClick}/>
        </div>
        <div style={ inputArea }>
        { children }
        </div>
      </div>
      
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class BackBtn extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover : false,
    }
  }
  render(){
    const { onClick } = this.props;
    const { hover } = this.state;

    const btn_style = {
      height: 35,
      padding:"0px 25px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 20,
      boxShadow:" 2px 2px 2px #aaa",
      cursor: "pointer",
      border:
        hover ? "2px solid #000" :
        "2px solid #555",
      color: 
        hover ? null :  
        "#fff",
      backgroundColor: 
        hover ? "#FFF" :
        "#555",
      transition:"0.3s",
    }

    return(
      <button 
        style        = {btn_style}
        onClick      = {onClick} 
        onMouseOver  = {()=>this.setState({hover:true})}
        onMouseLeave = {()=>this.setState({hover:false})}
        onFocus      = {()=>this.setState({hover:true})}
        onBlur       = {()=>this.setState({hover:false})}
      >
      閉じる
    </button>
    );
  }
}