import React from 'react';

export default class InputAreaLine extends React.Component{
  render(){

    const line_style = {
      borderTop:"5px solid white",
      width:"100%",
      paddingTop:"16px",
    }

    return(
      <div style={line_style}/>
    )
  }
}