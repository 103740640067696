import React from 'react';

export default class Centering extends React.Component{
  render(){

    const outer = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height:"100vh",
    }
    return(
      <div style  = {Object.assign({},outer,this.props.style)}
        className = {this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}