import Body from './Body';

import Card from './Card';
import InputArea from './InputArea';
import InputFile from "./InputFile";
import RichTextEditor from "./RichTextEditor";

import Logo from './Logo';
import Title from './Title';
import Footer from './Footer';
import Header from './Header';
import TagChangeMenu from "./TagChangeMenu";
import CheckList from "./CheckList";
import Menu from "./Menu";
import PopupContents from "./PopupContents";
import PopupBody from './PopupBody';
import ReferenceTable from './ReferenceTable';// 参照テーブル
import AssistWord from './AssistWord';
import InputAreaHeading from './InputAreaHeading';
import InputAreaLine from './InputAreaLine';
import Wrapper from './Wrapper';

// ボタン類
import SearchBtn from './SearchBtn';
import PageBtn   from './PageBtn';
import SubmitBtn from "./SubmitBtn";
import DeleteBtn from "./DeleteBtn";
import LabelBtn  from "./LabelBtn";

// 特化機能
import WelcomeComponent from './WelcomeComponent';
import InfoComponent from './InfoComponent';
import SupportComponent from './SupportComponent';
import SupportTitle from './SupportTitle';
import JurisdictionArea from "./JurisdictionArea";
import QCTable from "./QCTable";
import QCTable2 from "./QCTable2";
import QCTable3 from "./QCTable3";
import QCSearchList from "./QCSearchList";
import PRParagram from './PRParagram';
import InputMatrix from "./InputMatrix";

// 標準const
const SearchTableColor1 = "#2296F3"
const SearchTableColor2 = "#f9f9ff"
const SearchTableColorF = "#ffffff"

const InputTableColor1 = "#ffcc80"
const InputTableColor2 = "#fffef0"
const InputTableColorF = "#000000"


export {
  Body,
  Card,
  CheckList,
  Footer,
  Header,
  InputArea,
  InputFile,
  Logo,
  Menu,
  PopupContents,
  ReferenceTable,
  RichTextEditor,
  TagChangeMenu,
  Title,
  PopupBody,
  AssistWord,
  InputAreaHeading,
  InputAreaLine,
  Wrapper,

  // ボタン類
  PageBtn,
  SearchBtn,
  SubmitBtn,
  DeleteBtn,
  LabelBtn,

  // 特化機能
  WelcomeComponent,
  InfoComponent,
  SupportTitle,
  SupportComponent,
  JurisdictionArea,
  QCTable,
  QCSearchList,
  QCTable2,
  QCTable3,
  PRParagram,
  InputMatrix,

  // 標準const
  SearchTableColor1,
  SearchTableColor2,
  SearchTableColorF,

  InputTableColor1,
  InputTableColor2,
  InputTableColorF,
}

